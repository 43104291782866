import { Link } from 'react-router-dom'

import styles from './SocialMedia.module.sass'

function SocialMedia() {
    const footerLogo = '/assets/logos/logo.svg'

    return (
        <>
            <div className={styles['social-media--container']}>
                <div className={styles['footer--logo']}>
                    <Link to='/'><img src={footerLogo} alt='Destiny Tour Logo' /></Link>
                </div>
                <div className={styles['social-icons']}>
                    <a href='#' target='_blank'><i className={`fa-brands fa-facebook-f ${styles['icon']}`}></i></a>
                    <a href='#' target='_blank'><i className={`fa-brands fa-instagram ${styles['icon']}`}></i></a>
                    <a href='#' target='_blank'><i className={`fa-brands fa-x-twitter ${styles['icon']}`}></i></a>
                    <a href='#' target='_blank'><i className={`fa-brands fa-youtube ${styles['icon']}`}></i></a>
                    <a href='#' target='_blank'><i className={`fa-brands fa-blogger-b ${styles['icon']}`}></i></a>
                </div>
            </div>
        </>
    )
}

export default SocialMedia