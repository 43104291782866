import { useState, useEffect } from 'react'
import useAuth from '../../../../authManager/useAuth'
import useAccountById from '../../../../hooks/account_methods/useAccountById'
import usePersonInvoicingById from '../../../../hooks/order_methods/usePersonInvoicingById'
import useBusinessInvoicingById from '../../../../hooks/order_methods/useBusinessInvoicingById'

import AccountPanel from './Panels/AccountPanel'
import PersonPanel from './Panels/PersonPanel'
import BusinessPanel from './Panels/BusinessPanel'

import styles from './AccountInfo.module.sass'

function AccountInfo() {
    const [activeTab, setActiveTab] = useState(1)
    const [contentTab, setContentTab] = useState(<AccountPanel data={null} />)

    const { auth } = useAuth()
    const account = useAccountById(auth.id)
    const person_invoicing = usePersonInvoicingById(auth.id)
    const business_invoicing = useBusinessInvoicingById(auth.id)

    const handleTabClick = (index) => {
        setActiveTab(index)
    }

    useEffect(() => {
        if (activeTab === 1) {
            setContentTab(<AccountPanel data={account} />)
        } else if (activeTab === 2) {
            setContentTab(<PersonPanel data={person_invoicing} />)
        } else {
            setContentTab(<BusinessPanel data={business_invoicing} />)
        }
    }, [account, person_invoicing, business_invoicing, activeTab])

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={styles['account--information']}>
                <div className={styles['account--tabs']}>
                    <div className={`${styles['tab--button']} ${activeTab === 1 ? styles['active'] : ''}`} onClick={() => handleTabClick(1)}>
                        <span className={styles['text']}>Account Information</span>
                    </div>
                    <div className={`${styles['tab--button']} ${activeTab === 2 ? styles['active'] : ''}`} onClick={() => handleTabClick(2)}>
                        <span className={styles['text']}>Person Invoicing</span>
                    </div>
                    <div className={`${styles['tab--button']} ${activeTab === 3 ? styles['active'] : ''}`} onClick={() => handleTabClick(3)}>
                        <span className={styles['text']}>Business Invoicing</span>
                    </div>
                </div>

                <div className={styles['account--panels']}>
                    {contentTab}
                </div>
            </div>
        </>
    )
}

export default AccountInfo