import useAxiosPrivate from '../../api/useAxiosPrivate'

const useUpdateTwoFA = () => {
    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController()

    const updateTwoFA = async (id, code, twoFA_type = 'disable') => {
        try {
            const response = await axiosPrivate.put(`/api/v1/accounts/twofa-authentication/${id}`, { code, twoFA_type }, { signal: controller.signal })
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            controller.abort()
        }
    }

    return { updateTwoFA }
}

export default useUpdateTwoFA