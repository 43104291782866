import styles from './ProfileBanner.module.sass'
import clientStyles from '../../../../Client.module.sass'

function ProfileBanner() {
    const banner = '/assets/banners/profile/profile_banner.svg'

    return (
        <>
            <div className={clientStyles['page--size']}>
                <div className={styles['banner--container']}>
                    <img src={banner} alt='Banner Profile Image' />
                </div>
            </div>
        </>
    )
}

export default ProfileBanner