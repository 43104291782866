import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'

import styles from '../Admin.module.sass'

function NotFound() {
    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Page Not Found'} />
                    <div className={`${styles['container']} ${styles['page-not-found']}`}>
                        <span className={styles['error-code']}>404</span>
                        <span className={styles['error-text']}>The page have you searched for does not exist.</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound