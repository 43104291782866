import React from 'react'

import styles from './BoxCards.module.sass'

function BoxCards({ data, columns }) {
    return (
        <>
            <div className={`${styles['box-container']} ${styles[`box-grid-${columns}`]} ${styles['margin-bottom-s']}`}>
                { data.map((boxItem, i) => 
                        <div key={i} className={styles['box']}>
                            <div className={styles['box--content']}>
                                <div className={styles['counter']}>{boxItem.counter}</div>
                                <div className={styles['text']}>{boxItem.text}</div>
                            </div>
                            <div className={styles['box--icon']}>
                                <i className={`${boxItem.icon} ${styles['icon']}`}></i>
                            </div>
                        </div>
                    ) }
            </div>
        </>
    )
}

export default BoxCards