import { useEffect } from 'react'
import { BASE_URL } from '../../../../api/axios'

import WishlistButton from '../../General/WishlistButton/WishlistButton'

import styles from './Wishlist.module.sass'
import clientStyles from '../../../Client.module.sass'

function Wishlist({ tours }) {
    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={clientStyles['profile--page']}>
            <h2 className={clientStyles['page--title']}>My Wishlist</h2>
            <div className={clientStyles['page--panel']}>
                <div className={styles['wishlist']}>
                    <div className={styles['label-headings']}>
                        <span className={styles['label']}>Tour Image</span>
                        <span className={styles['label']}>Tour Title</span>
                        <span className={styles['label']}>Tour Days</span>
                        <span className={styles['label']}>Tour Price</span>
                        <span className={styles['label']}>Actions</span>
                    </div>
                    <div className={styles['tour-items']}>
                        {tours.length ? (
                            Object.values(tours).map((tour) => (
                                <div key={tour.id} className={styles['item']}>
                                    <span className={styles['tour-image']}>
                                        <img src={BASE_URL + tour.representative_image_url} alt={tour.tour_title} />
                                    </span>
                                    <span className={styles['tour-title']}>{tour.tour_title}</span>
                                    <span className={styles['tour_days']}>{tour.tour_days} {tour.tour_days === 1 ? 'day' : `days / ${tour.tour_days - 1} night${tour.tour_days - 1 === 1 ? '' : 's'}`}</span>
                                    <span className={styles['total-price']}>from {tour.child_price ? tour.child_price : tour.adult_price}€</span>
                                    <span className={styles['actions']}>
                                        <WishlistButton tourId={tour.id} isWishlistPage={true} />
                                    </span>
                                </div>
                            ))
                        ) : (
                            <p>There are no tours yet.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wishlist