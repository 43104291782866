import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { BASE_URL } from '../../api/axios'
import useGetTourById from '../../hooks/useGetTourByLink'
import useTourGalleryById from '../../hooks/useTourGalleryById'
import useGetAllDestinations from '../../hooks/useGetAllDestinations'
import { useFormattedDate } from '../../functions/useDateTimeFormat'

import useAuth from '../../authManager/useAuth'
import useAxiosPrivate from '../../api/useAxiosPrivate'

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import WishlistButton from '../Components/General/WishlistButton/WishlistButton'
import NotFoundError from '../Components/General/NotFoundError/NotFoundError'

import clientStyles from '../Client.module.sass'

function TourPage() {
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [wishlist, setWishlist] = useState({})
    const galleryPlaceholder = '/assets/icons/icon_gallery.svg'

    const { link } = useParams()
    const navigate = useNavigate()
    const tour = useGetTourById(link)
    const imgGallery = useTourGalleryById(tour.id)
    const destinations = useGetAllDestinations()

    const [checkout, setCheckout] = useState({ num_of_adults: 1, num_of_childs: 0 })

    const handleInput = (e) => {
        const { name, value } = e.target
        const parsedValue = name === 'departure_date' ? value : parseInt(value)
        setCheckout({ ...checkout, [name]: parsedValue })
    }

    const goToCheckoutPage = (e) => {
        e.preventDefault()

        navigate('/payments/checkout/', { state: checkout })
    }

    useEffect(() => {
        axiosPrivate.get(`/api/v1/tours/wishlist/${auth?.id}`)
            .then((response) => {
                setWishlist(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [wishlist])

    // initializing with tour data
    useEffect(() => {
        setCheckout({
            ...checkout,
            id: tour.id,
            tour_title: tour.tour_title,
            tour_days: tour.tour_days,
            representative_image_url: tour.representative_image_url,
            adult_price: tour.adult_price,
            child_price: tour.child_price,
            destination_id: tour.destination_id
        })

        if (!checkout.departure_date && tour.tour_valability_dates) {
            setCheckout({
                ...checkout,
                departure_date: JSON.parse(tour.tour_valability_dates)[0]
            })
        }
    }, [tour])

    // do calculation for tour price
    useEffect(() => {
        if (tour.adult_price && tour.child_price) {
            const totalPrice = checkout.num_of_adults * tour.adult_price + checkout.num_of_childs * tour.child_price
            setCheckout({
                ...checkout,
                total_price: totalPrice
            })
        }
    }, [checkout.num_of_adults, checkout.num_of_childs])

    // by default, setting total_price with the adult_price
    useEffect(() => {
        if (tour.adult_price) {
            setCheckout({
                ...checkout,
                total_price: tour.adult_price
            })
        }
    }, [tour.adult_price])

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>{tour.tour_title}</title>
                <meta name='keywords' content={tour.tour_tags}></meta>
            </Helmet>

            <Header />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['page--gap__XL']}>
                    <div className={clientStyles['page--inner__no-banner']}>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }}
                        >
                            {Object.values(tour).length ? (
                                <div className={clientStyles['tour--container']}>
                                    <div className={clientStyles['tour--header']}>
                                        <div className={clientStyles['tour--details']}>
                                            <h1 className={clientStyles['title']}>{tour.tour_title}</h1>
                                            {destinations && destinations[tour.destination_id - 1] && (
                                                <div className={clientStyles['destination']}>
                                                    <i className={`fa-solid fa-map-pin ${clientStyles['icon']}`}></i>
                                                    {destinations[tour.destination_id - 1].destination_name}
                                                </div>
                                            )}
                                            <div className={clientStyles['days']}>
                                                <i className={`fa-solid fa-hourglass-half ${clientStyles['icon']}`}></i>
                                                {tour.tour_days} {tour.tour_days === 1 ? 'day' : `days / ${tour.tour_days - 1} night${tour.tour_days - 1 === 1 ? '' : 's'}`}
                                            </div>
                                        </div>
                                        <div className={clientStyles['tour--utils']}>
                                            <div className={clientStyles['price']}>
                                                {(checkout.total_price && checkout.total_price !== tour.adult_price) ? (
                                                    checkout.total_price + '€'
                                                ) : (
                                                    (tour.child_price || tour.adult_price) ? (
                                                        <>
                                                            <span className={clientStyles['from']}>from</span>
                                                            {tour.child_price ? tour.child_price : tour.adult_price}€
                                                        </>
                                                    ) : (
                                                        <p className={clientStyles['no-price']}>No price yet.</p>
                                                    )
                                                )}
                                            </div>
                                            <div className={clientStyles['buttons']}>
                                                <WishlistButton tourId={tour.id} wishlist={wishlist} />
                                                <a href='#booking_form' className={clientStyles['button']}>Book Now</a>
                                            </div>
                                        </div>
                                    </div>

                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 2 }}
                                    >
                                        {imgGallery.length > 0 && (
                                            <div className={clientStyles['tour--gallery']}>
                                                {imgGallery.map((image, i) => (
                                                    <div key={i} className={clientStyles['image']}>
                                                        <img src={BASE_URL + image.image_url} alt={tour.tour_title} />
                                                    </div>
                                                ))}

                                                {imgGallery.length < 5 && (
                                                    <>
                                                        {[...Array(5 - imgGallery.length).keys()].map((i) => (
                                                            <div key={i} className={clientStyles['image']}>
                                                                <img src={galleryPlaceholder} alt={tour.tour_title} />
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </motion.div>

                                    <div className={clientStyles['divider']}></div>

                                    <div className={clientStyles['tour--content']}>
                                        <div dangerouslySetInnerHTML={{ __html: tour.tour_content }}></div>
                                    </div>

                                    {tour.map && (
                                        <>
                                            <div className={clientStyles['divider']}></div>

                                            <div className={clientStyles['tour--map']}>
                                                <h2 className={clientStyles['module--title']}>Map Location</h2>
                                                <div dangerouslySetInnerHTML={{ __html: tour.map }}></div>
                                            </div>
                                        </>
                                    )}

                                    <div className={clientStyles['divider']} id='booking_form'></div>

                                    <div className={clientStyles['tour--booking-form']}>
                                        <h2 className={clientStyles['module--title']}>Reserve Now</h2>
                                        <form className={clientStyles['booking--form']} onSubmit={(e) => goToCheckoutPage(e)}>
                                            <div className={clientStyles['input--field']}>
                                                <label className={clientStyles['label-outside']}>Departure Dates</label>

                                                <select className={clientStyles['input']} name='departure_date' onChange={(e) => handleInput(e)}>
                                                    {tour.tour_valability_dates && JSON.parse(tour.tour_valability_dates).map((date, i) => (
                                                        <option key={i} value={date}>{useFormattedDate(date)}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={clientStyles['input--field']}>
                                                <label className={clientStyles['label-outside']}>Num. of Adults</label>

                                                <select className={clientStyles['input']} name='num_of_adults' onChange={(e) => handleInput(e)}>
                                                    {Array.from({ length: tour.num_of_adults }, (_, i) => (
                                                        <option key={i} value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={clientStyles['input--field']}>
                                                <label className={clientStyles['label-outside']}>Num. of Childrens</label>

                                                <select className={clientStyles['input']} name='num_of_childs' onChange={(e) => handleInput(e)}>
                                                    {Array.from({ length: tour.num_of_childs + 1 }, (_, i) => (
                                                        <option key={i} value={i}>{i}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className={clientStyles['input--field']}>
                                                <button className={clientStyles['button']}>Reserve Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <NotFoundError description={'The tour you requested does not exist.'} />
                            )}
                        </motion.div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default TourPage