import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'

import useAxiosPrivate from '../../../api/useAxiosPrivate'
import { useFormattedDate } from '../../../functions/useDateTimeFormat'
import useGetCouponById from '../../../hooks/useGetCouponById'

import Header from '../../Components/Header/Header'
import ProfileHeader from '../../Components/Profile/ProfileHeader/ProfileHeader'
import Footer from '../../Components/Footer/Footer'

import styles from '../../Client.module.sass'
import GenerateContract from '../../../Documents/GenerateContract'
import GenerateInvoice from '../../../Documents/GenerateInvoice'

function MyBookings() {
    const { id } = useParams()
    const contractRef = useRef()
    const invoiceRef = useRef()

    const [data, setData] = useState({})
    const [orderExist, setOrderExist] = useState('')

    const axiosPrivate = useAxiosPrivate()
    const coupon = useGetCouponById(data.coupon_id)

    const adultsArray = data?.traveller_details?.travellers?.adult ? Object.values(data.traveller_details.travellers.adult) : []
    const childsArray = data?.traveller_details?.travellers?.child ? Object.values(data.traveller_details.travellers.child) : []
    const travellersArray = [
        ...adultsArray.map(traveller => ({ ...traveller, type: 'adult' })),
        ...childsArray.map(traveller => ({ ...traveller, type: 'child' }))
    ]

    // generate the name to build document name
    const generateName = data?.invoicing_details?.invoicing.physical_name
        ? data?.invoicing_details?.invoicing?.physical_name?.toLocaleLowerCase().replace(/\s/g, '_')
        : data?.invoicing_details?.invoicing?.legal_name?.toLocaleLowerCase().replace(/\s/g, '_')

    // setting up the pdf settings
    const pdfSettings = {
        margin: 10,
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            dpi: 192,
            scale: 4,
            scrollX: 0,
            scrollY: 0,
            letterRendering: true,
            useCORS: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
            hotfixes: ['px_scaling']
        },
        pagebreak: {
            mode: ['css', 'legacy']
        }
    }

    const downloadContract = () => {
        // get reference to your HTML from the component
        const element = contractRef.current

        // concatening the settings with the one above and the name
        const opt = {
            ...pdfSettings,
            filename: `contract_${generateName}.pdf`
        }

        // getting the documents ready
        html2pdf().from(element).set(opt).save()
    }

    const downloadInvoice = () => {
        // get reference to your HTML from the component
        const element = invoiceRef.current

        // concatening the settings with the one above and the name
        const opt = {
            ...pdfSettings,
            filename: `invoice_${generateName}.pdf`
        }

        // getting the documents ready
        html2pdf().from(element).set(opt).save()
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/orders/${id}`)
                setData(response.data)
            } catch (error) {
                console.log(error)
                setOrderExist(error.response?.data?.message || 'Error fetching order data.')
            }
        }

        fetchData()
    }, [id, axiosPrivate])

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <ProfileHeader />

            <div className={styles['page--size']}>
                <div className={styles['order--page']}>
                    <div className={styles['profile--page']}>
                        <div className={styles['page--panel']}>
                            {data && (
                                <div className={styles['order--container']}>
                                    {!orderExist ? (
                                        <>
                                            <div className={styles['details']}>
                                                <div className={styles['details--invoice']}>
                                                    {/* order summary box */}
                                                    <div className={styles['order--details-box']}>
                                                        <h2 className={styles['box-title']}>Order Summary</h2>
                                                        <div className={styles['box--content']}>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Order Number:</div>
                                                                <div className={styles['value']}>#{data?.id}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Order Date:</div>
                                                                <div className={styles['value']}>{useFormattedDate(data?.order_date)}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Tour:</div>
                                                                <div className={styles['value']}>{data?.tour_title}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Tour Date:</div>
                                                                <div className={styles['value']}>{useFormattedDate(data?.departure_date)}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Tour Days:</div>
                                                                <div className={styles['value']}>{data?.tour_days} {data?.tour_days === 1 ? 'day' : `days / ${data?.tour_days - 1} night${data?.tour_days - 1 === 1 ? '' : 's'}`}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Payment Method:</div>
                                                                <div className={styles['value']}>{data?.payment_method === 1 ? 'At the Agency' : 'Banking Transfer'}</div>
                                                            </div>
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Status:</div>
                                                                <div className={styles[data?.status]}>{data?.status}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* order contact details box */}
                                                    <div className={styles['order--details-box']}>
                                                        <h2 className={styles['box-title']}>Contact Details</h2>
                                                        {data?.contact_details?.details ? (
                                                            <div className={styles['box--content']}>
                                                                <div className={styles['row']}>
                                                                    <div className={styles['label']}>First & Last Name:</div>
                                                                    <div className={styles['value']}>{data?.contact_details?.details?.first_name} {data?.contact_details?.details?.last_name}</div>
                                                                </div>
                                                                <div className={styles['row']}>
                                                                    <div className={styles['label']}>Email Address:</div>
                                                                    <div className={styles['value']}>{data?.contact_details?.details?.email_address}</div>
                                                                </div>
                                                                <div className={styles['row']}>
                                                                    <div className={styles['label']}>Phone Number:</div>
                                                                    <div className={styles['value']}>{data?.contact_details?.details?.phone}</div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p className={styles['no-data']}>There are no contact details yet.</p>
                                                        )}
                                                    </div>

                                                    {/* order invoicing details box */}
                                                    <div className={styles['order--details-box']}>
                                                        <h2 className={styles['box-title']}>Invoicing Details</h2>
                                                        {data?.invoicing_details?.invoicing ? (
                                                            <div className={styles['box--content']}>
                                                                {(data?.invoicing_details?.invoicing.physical_name || data?.invoicing_details?.invoicing.legal_name) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>{data?.invoicing_details?.invoicing.physical_name ? 'First & Last Name' : 'Company Name'}:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_name ? data?.invoicing_details?.invoicing.physical_name : data?.invoicing_details?.invoicing.legal_name}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_identification_number || data?.invoicing_details?.invoicing.legal_identification_number) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Identification Number:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_identification_number ? data?.invoicing_details?.invoicing.physical_identification_number : data?.invoicing_details?.invoicing.legal_identification_number}</div>
                                                                    </div>
                                                                )}
                                                                {data?.invoicing_details?.invoicing.legal_register && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Legal Register:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.legal_register}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_email || data?.invoicing_details?.invoicing.legal_email) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Email Address:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_email ? data?.invoicing_details?.invoicing.physical_email : data?.invoicing_details?.invoicing.legal_email}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_phone || data?.invoicing_details?.invoicing.legal_phone) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Phone:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_phone ? data?.invoicing_details?.invoicing.physical_phone : data?.invoicing_details?.invoicing.legal_phone}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_bank_name || data?.invoicing_details?.invoicing.legal_bank_name) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Bank Name:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_bank_name ? data?.invoicing_details?.invoicing.physical_bank_name : data?.invoicing_details?.invoicing.legal_bank_name}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_bank_iban || data?.invoicing_details?.invoicing.legal_bank_iban) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Bank IBAN:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_bank_iban ? data?.invoicing_details?.invoicing.physical_bank_iban : data?.invoicing_details?.invoicing.legal_bank_iban}</div>
                                                                    </div>
                                                                )}
                                                                {(data?.invoicing_details?.invoicing.physical_billing_address || data?.invoicing_details?.invoicing.legal_billing_address) && (
                                                                    <div className={styles['row']}>
                                                                        <div className={styles['label']}>Billing Address:</div>
                                                                        <div className={styles['value']}>{data?.invoicing_details?.invoicing.physical_billing_address ? data?.invoicing_details?.invoicing.physical_billing_address : data?.invoicing_details?.invoicing.legal_billing_address}</div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <p className={styles['no-data']}>There are no invoicing details yet.</p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* order traveller details box */}
                                                <div className={`${styles['order--details-box']} ${styles['travellers']}`}>
                                                    <h2 className={styles['box-title']}>Travellers</h2>
                                                    {travellersArray.length ? (
                                                        <div className={styles['box--content']}>
                                                            {travellersArray.map((traveller, index) => (
                                                                <div key={index} className={styles['row']}>
                                                                    <div className={styles['label']}>Traveller {index + 1} ({traveller.type}):</div>
                                                                    <div className={styles['value']}>
                                                                        {traveller.name} - <strong>Identification Number:</strong> {traveller.identification_number}
                                                                        {traveller.type === 'child' && (
                                                                            <>
                                                                                {' - '}<strong>Age:</strong> {traveller.age} year{traveller.age == 1 ? '' : 's'}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <p className={styles['no-data']}>There are no travellers yet.</p>
                                                    )}
                                                </div>

                                                {/* order price breakdown box */}
                                                <div className={`${styles['order--details-box']} ${styles['price-breakdown']}`}>
                                                    <h2 className={styles['box-title']}>Price Breakdown</h2>
                                                    <div className={styles['box--content']}>
                                                        <div className={styles['row']}>
                                                            <div className={styles['label']}>Adult Price:</div>
                                                            <div className={styles['value']}>{data.adult_price * data.num_of_adults}€ ({data.num_of_adults} x {data.adult_price}€)</div>
                                                        </div>
                                                        {data?.num_of_childs ? (
                                                            <div className={styles['row']}>
                                                                <div className={styles['label']}>Child Price:</div>
                                                                <div className={styles['value']}>{data.child_price * data.num_of_childs}€ ({data.num_of_childs} x {data.child_price}€)</div>
                                                            </div>
                                                        ) : null}
                                                        {data?.coupon_id && (
                                                            <>
                                                                <div className={styles['divider']} />
                                                                <div className={styles['row']}>
                                                                    <div className={styles['label']}>Coupon:</div>
                                                                    <div className={styles['value']}>
                                                                        <i>{coupon.name}</i> - has a <strong>discount of {coupon.price_type === 'fixed' ? `${coupon.price_value}€` : `${coupon.price_value}%`}</strong> from main price
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        {data?.additional_services?.length ? (
                                                            <>
                                                                <div className={styles['divider']} />
                                                                {data.additional_services.map((service, i) => (
                                                                    <div key={i} className={styles['row']}>
                                                                        <div className={styles['label']}>Additional Service ({i + 1}):</div>
                                                                        <div className={styles['value']}>
                                                                            <i>{service.name}</i> - has a <strong>price of {service.price_value}€</strong>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                        <div className={styles['divider']} />
                                                        <div className={`${styles['row']} ${styles['row-total']}`}>
                                                            <div className={styles['label']}>Total Price:</div>
                                                            <div className={styles['value']}>{data.total_price}€</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles['invoice--buttons']}>
                                                    <button className={styles['button']} onClick={() => downloadContract()}>Download Contract</button>
                                                    <button className={styles['button']} onClick={() => downloadInvoice()}>Download Invoice</button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <p>{orderExist}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <GenerateContract
                data={data}
                contact={data?.contact_details?.details}
                invoicing={data?.invoicing_details?.invoicing}
                ref={contractRef}
            />
            <GenerateInvoice
                data={data}
                travellers={data?.traveller_details?.travellers}
                invoicing={data?.invoicing_details?.invoicing}
                ref={invoiceRef}
            />
        </>
    )
}

export default MyBookings