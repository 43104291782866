import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useGetAllUsers = () => {
    const axiosPrivate = useAxiosPrivate()
    const [users, setUsers] = useState({})
    
    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getAllUsers = async () => {
            try {
                const response = await await axiosPrivate.get(`/api/v1/accounts/users/`, { signal: controller.signal })
                isMounted && setUsers(response.data)
            } catch (error) {
                return
            }
        }

        getAllUsers()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [users])

    return users
}

export default useGetAllUsers