import axios from 'axios'
export const BASE_URL = 'https://api.destinytour.claudiudesign.ro'
export const CLIENT_BASE_URL = 'https://destiny.claudiudesign.ro'

// export const BASE_URL = 'http://localhost:4000'
// export const CLIENT_BASE_URL = 'http://localhost:3000'

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
})