import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../api/useAxiosPrivate'

const usePersonInvoicingById = (id) => {
    const [personInvoicing, setPersonInvoicing] = useState({})
    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        if (!id) return

        let isMounted = true
        const controller = new AbortController()

        const getDataForPersonInvoicing = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/accounts/person-invoicing/${id}`, { signal: controller.signal })
                isMounted && setPersonInvoicing(response.data)
            } catch (error) {
                return
            }
        }

        getDataForPersonInvoicing()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [id, personInvoicing])

    return personInvoicing
}

export default usePersonInvoicingById