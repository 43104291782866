import { useState, createContext } from 'react'

export const MenuContext = createContext()

export function MenuProvider({ children }) {
    const [toggle, setToggle] = useState(false)

    const toggleMenu = () => {
        setToggle(!toggle)
    }

    return (
        <MenuContext.Provider value={{ toggle, toggleMenu }}>
            {children}
        </MenuContext.Provider>
    )
}