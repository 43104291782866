import useAuth from '../../../../authManager/useAuth'
import useAxiosPrivate from '../../../../api/useAxiosPrivate'

import styles from './WishlistButton.module.sass'

function WishlistButton({ ...props }) {
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const { tourId, wishlist, isWishlistPage } = props

    const wishlistFilter = wishlist && wishlist.length > 0 && wishlist.some((item) => item.tour_id === tourId)

    const addToWishlist = async () => {
        const dataToServer = {
            account_id: auth.id,
            tour_id: tourId
        }

        try {
            if (!wishlistFilter && !isWishlistPage) {
                await axiosPrivate.post('/api/v1/tours/wishlist/', dataToServer)
            } else {
                await axiosPrivate.delete(`/api/v1/tours/wishlist/${tourId}`)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {auth.id && !isWishlistPage && (
                <button
                    className={`${styles['wishlist']} ${wishlistFilter ? styles['active'] : ''}`}
                    onClick={addToWishlist}
                >
                    <i className='fa-regular fa-heart'></i>
                </button>
            )}

            {auth.id && isWishlistPage && (
                <button
                    className={`${styles['wishlist--page']} ${wishlistFilter ? styles['active'] : ''}`}
                    onClick={addToWishlist}
                >
                    Remove
                </button>
            )}
        </>
    )
}

export default WishlistButton