import { useState } from 'react'
import useFieldValidation from '../../../../../functions/useFieldValidation'
import useCustomBankIBAN from '../../../../../functions/useCustomBankIBAN'
import usePutBusinessInvoicingById from '../../../../../hooks/order_methods/usePutBusinessInvoicingById'
import useAuth from '../../../../../authManager/useAuth'

import PanelContentRow from './PanelContentRow/PanelContentRow'
import { FormModal, FormModalField, FormModalValidation } from '../../../General/FormModal/FormModal'

import styles from './Panel.module.sass'
import modalStyles from '../../../General/FormModal/FormModal.module.sass'

function BusinessPanel({ data }) {
    const [formData, setFormData] = useState({ id: '', name: '', identification_number: '', business_register: '', phone: '', email: '', address: '', bank_name: '', bank_iban: '' })
    const [message, setMessage] = useState({ color: '', text: '' })
    const [openModal, setOpenModal] = useState(false)

    const { auth } = useAuth()
    const { updateBusinessInvoicingDetails } = usePutBusinessInvoicingById()
    const { fieldValidations, setFieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    const toggleModal = () => {
        // toggle the modal state
        setOpenModal(!openModal)

        // we set the form state with the props data that are fetch from database
        setFormData({
            id: auth.id,
            name: data.name,
            identification_number: data.identification_number,
            business_register: data.business_register,
            phone: data.phone,
            email: data.email,
            address: data.address,
            bank_name: data.bank_name,
            bank_iban: data.bank_iban
        })

        // we check to not validate by default empty inputs on
        // modal open but additional validation on submit form
        if (data.name) checkFieldValidation('name', data.name)
        if (data.identification_number) checkFieldValidation('identification_number', data.identification_number)
        if (data.business_register) checkFieldValidation('business_register', data.business_register)
        if (data.phone) checkFieldValidation('phone', data.phone)
        if (data.email) checkFieldValidation('email', data.email)
        if (data.address) checkFieldValidation('address', data.address)
        if (data.bank_name) checkFieldValidation('bank_name', data.bank_name)
        if (data.bank_iban) checkFieldValidation('bank_iban', data.bank_iban)

        // we clear all the validations in the case we have some
        setFieldValidations({})

        // we clear the general message in the case we have some
        setMessage({ color: '', text: '' })
    }

    const updateFormInput = (e) => {
        const customIBAN = useCustomBankIBAN(e.target.name, e.target.value)

        setFormData({ ...formData, [e.target.name]: customIBAN })
        checkFieldValidation(e.target.name, e.target.value)
    }

    const updateData = async (e) => {
        e.preventDefault()

        // precheck if all mandatory fields are filled
        if (!formData.name || !formData.identification_number || !formData.business_register
            || !formData.phone || !formData.email || !formData.address)
                return setMessage({ color: 'color-red', text: 'All fields marked with * must be filled.' })
    
        // checking if inputs meets the criteria
        if (isAnyFieldInvalid || Object.keys(fieldValidations).length === 0) return setMessage({ color: 'color-red', text: 'One or more fields are not filled properly.' })

        const response = await updateBusinessInvoicingDetails(formData)
        if (response?.data) {
            if (response.data.status === 200) {
                setMessage({ color: 'color-green', text: response.data.message })
                setTimeout(() => {
                    setMessage({ color: '', text: '' })
                    setOpenModal(!openModal)
                }, 3000)
            }
        }
    }
    
    return (
        <>
            <div className={styles['panel--container']}>
                <h2 className={styles['panel--title']}>
                    Business Information

                    <div className={styles['save--button']}>
                        <button className={styles['button']} onClick={() => toggleModal()}>
                            <i className='fa-solid fa-pencil'></i>
                            Edit
                        </button>
                    </div>
                </h2>
                <div className={styles['panel--data']}>
                    <PanelContentRow label={'Company Name'} value={data?.name} />
                    <PanelContentRow label={'Identification Number'} value={data?.identification_number} />
                    <PanelContentRow label={'Business Register Number'} value={data?.business_register} />
                    <PanelContentRow label={'Phone Number'} value={data?.phone} />
                    <PanelContentRow label={'Email Address'} value={data?.email} />
                    <PanelContentRow label={'Billing Address'} value={data?.address} />
                    <PanelContentRow label={'Bank Name'} value={data?.bank_name} />
                    <PanelContentRow label={'Bank IBAN'} value={data?.bank_iban} />
                </div>

                {openModal && (
                    <FormModal title={'Business Information'} closeFunction={toggleModal}>
                        <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Company Name' inputName='name' inputValue={formData.name} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.name?.errorMessage} />
                                </div>
                            </div>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Identification Number' inputName='identification_number' inputValue={formData.identification_number} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.identification_number?.errorMessage} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Business Register' inputName='business_register' inputValue={formData.business_register} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.business_register?.errorMessage} />
                                </div>
                            </div>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Phone Number' inputName='phone' inputValue={formData.phone} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.phone?.errorMessage} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Email Address' inputName='email' inputValue={formData.email} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.email?.errorMessage} />
                                </div>
                            </div>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Billing Address' inputName='address' inputValue={formData.address} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.address?.errorMessage} />
                                </div>
                            </div>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank Name' inputName='bank_name' inputValue={formData.bank_name} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.bank_name?.errorMessage} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank IBAN' inputName='bank_iban' inputValue={formData.bank_iban} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.bank_iban?.errorMessage} />
                                </div>
                            </div>

                            {message.text &&
                                <div className={`${styles['message']} ${styles[message.color]}`}>
                                    <p className={styles['message--text']}>{message.text}</p>
                                </div>
                            }

                            <button className={modalStyles['modal--save']}>
                                <i className='fa-solid fa-pen-to-square'></i>
                                Update
                            </button>
                        </form>
                    </FormModal>
                )}
            </div>
        </>
    )
}

export default BusinessPanel