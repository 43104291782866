import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from '../../api/axios'

import useFieldValidation from '../../functions/useFieldValidation'

import styles from './Accounts.module.sass'
import globalStyles from '../../styles/Globals.module.sass'

function Recovery() {
    const [passwordView, setPasswordView] = useState(false)
    const [accountView, setAccountView] = useState(false)

    const [data, setData] = useState({ email: '' })
    const [validation, setValidation] = useState({ message: '', isValid: false })

    const { fieldValidations, setFieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    const mainViewHandler = () => {
        if (passwordView) setPasswordView(!passwordView)
        if (accountView) setAccountView(!accountView)

        setValidation({ message: '', isValid: false })
    }

    const passwordViewHandler = () => {
        setPasswordView(!passwordView)
    }

    // const accountViewHandler = () => {
    //     setAccountView(!accountView)
    // }

    const handleInputs = (e) => {
        const { name, value } = e.target

        setData({ ...data, [name]: value })
        checkFieldValidation(name, value)
    }

    const requestNewPassword = async (e) => {
        e.preventDefault()

        try {
            // adding additional form check for inputs in the case we send with the empty fields
            checkFieldValidation('email', data.email)

            // checking if inputs meets the criteria
            if (isAnyFieldInvalid || Object.keys(fieldValidations).length === 0) {
                return setMessage({ color: 'color-red', text: 'One or more fields are not filled properly.' })
            }

            const dataToServer = { email: data.email }
            await axios.post('/api/v1/recovery/password/', dataToServer, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
                .then((response) => {
                    setData({ ...data, email: '' })
                    setFieldValidations({})
                    setValidation({ message: response.data.message, isValid: true })
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } catch (error) {
            setValidation({ message: 'An unexpected error occured. Try again later...', isValid: false })
        }
    }

    return (
        <>
            <Helmet>
                <title>Destiny Tour - Recovery</title>
            </Helmet>

            <div className={styles['form-page']}>
                <div className={styles['form-image']}>
                    <img src='/assets/accounts/recovery.svg' alt='Login Form' />
                </div>
                <div className={styles['recovery-menu']}>
                    <h1 className={styles['form-title']}>Recovery</h1>
                    <p className={styles['form-title--description']}>
                        {passwordView || accountView
                            ? passwordView
                                ? 'Here you can reset your password to get access back to your account.'
                                : 'Here you can recover your account due to suspicious activity.'
                            : 'Happens to all of us to have issues with accounts. Here you can fix these.'
                        }
                    </p>

                    {(passwordView || accountView) ? (
                        <div>
                            <p className={styles['back-to-main']} onClick={() => mainViewHandler()}>Back to Recovery Options</p>

                            {passwordView ? (
                                <form className={styles['recovery-form']} onSubmit={(e) => requestNewPassword(e)}>
                                    <h2 className={styles['recovery-instructions']}>Below you should provide the email address for the account that you've lost the password.</h2>
                                    <div className={styles['form-field']}>
                                        <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                                        <input className={styles['form-input']} type='email' value={data.email} name='email' onChange={(e) => handleInputs(e)} placeholder='Email Address' />
                                    </div>
                                    {fieldValidations?.email?.errorMessage &&
                                        <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                            <p className={styles['field-error']}>{fieldValidations.email.errorMessage}</p>
                                        </div>
                                    }

                                    <div className={styles['form-field']}>
                                        <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Recover Your Password Now' disabled={fieldValidations?.email?.isValid ? '' : true} />
                                    </div>
                                </form>
                            ) : (
                                <form className={styles['recovery-form']}>
                                    <h2 className={styles['recovery-instructions']}>Below you should provide the email address associated with the account on the moment of the creation of this.</h2>
                                    <div className={styles['form-field']}>
                                        <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                                        <input className={styles['form-input']} type='email' name='email' onChange={(e) => handleInputs(e)} placeholder='Email Address' />
                                    </div>
                                    {fieldValidations?.email?.errorMessage &&
                                        <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                            <p className={styles['field-error']}>{fieldValidations.email.errorMessage}</p>
                                        </div>
                                    }

                                    <div className={styles['form-field']}>
                                        <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Recover Your Account Now' disabled={fieldValidations?.email?.isValid ? '' : true} />
                                    </div>
                                </form>
                            )}
                        </div>
                    ) : (
                        <div className={styles['options-available']}>
                            <div className={styles['option']} onClick={() => passwordViewHandler()}>
                                <span className={styles['button-title']}>Password</span>
                                <p className={styles['button-description']}>If you forgot your password or something else happened but you have access to your email, you can reset it now.</p>
                            </div>
                            {/* <div className={styles['option']} onClick={() => accountViewHandler()}>
                                <span className={styles['button-title']}>Account</span>
                                <p className={styles['button-description']}>If something suspicious happened to your account like lost, stolen or hacked, here you can recover it if you know your registration email.</p>
                            </div> */}
                        </div>
                    )}

                    {validation.message &&
                        <div className={`${globalStyles['validation--message']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                            {validation.message}
                        </div>
                    }

                    <div className={styles['account-util']}>
                        <p>You don't have an account? <Link to='/register/'>Create one</Link></p>
                        <p>You already have an account? <Link to='/login/'>Authenticate now</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recovery