import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useGetAdditionalServiceById = (id) => {
    const axiosPrivate = useAxiosPrivate()
    const [service, setService] = useState({})

    useEffect(() => {
        if (!id) return
        
        let isMounted = true
        const controller = new AbortController()

        const getDataForAdditionalService = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/tours/additional-services/${id}`, { signal: controller.signal })
                isMounted && setService(response.data)
            } catch (error) {
                console.error('Error fetching additional service data: ', error)
            }
        }

        getDataForAdditionalService()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [id])

    return service
}

export default useGetAdditionalServiceById