import { useState, useEffect } from 'react'

import useAuth from '../../../authManager/useAuth'
import useAxiosPrivate from '../../../api/useAxiosPrivate'

import Header from '../../Components/Header/Header'
import ProfileHeader from '../../Components/Profile/ProfileHeader/ProfileHeader'
import ProfileNavMenu from '../../Components/Profile/ProfileNavMenu/ProfileNavMenu'
import Wishlist from '../../Components/Profile/Wishlist/Wishlist'
import Footer from '../../Components/Footer/Footer'

import clientStyles from '../../Client.module.sass'

function MyWishlist() {
    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [tours, setTours] = useState({})

    useEffect(() => {
        axiosPrivate.get(`/api/v1/tours/wishlist/tours/${auth?.id}`)
            .then((response) => {
                setTours(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [tours])

    return (
        <>
            <Header />
            <ProfileHeader />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['profile--flex']}>
                    <ProfileNavMenu />
                    <Wishlist tours={tours} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MyWishlist