import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from '../../api/axios'

import useFieldValidation from '../../functions/useFieldValidation'

import styles from './Accounts.module.sass'
import globalStyles from '../../styles/Globals.module.sass'

function PasswordRecovery() {
    const navigate = useNavigate()
    const { token } = useParams()

    const [data, setData] = useState({ password: '', secondPassword: '' })
    const [validation, setValidation] = useState({ message: '', isValid: false })

    const { fieldValidations, setFieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    const handleInputs = (e) => {
        const { name, value } = e.target

        setData({ ...data, [name]: value })
        checkFieldValidation(name, value)
    }

    const requestNewPassword = async (e) => {
        e.preventDefault()

        try {
            // adding additional form check for inputs in the case we send with the empty fields
            checkFieldValidation('password', data.password)
            checkFieldValidation('secondPassword', data.secondPassword)

            // checking if inputs meets the criteria
            if (isAnyFieldInvalid || Object.keys(fieldValidations).length === 0) {
                return setValidation({ message: 'One or more fields are not filled properly.', isValid: false })
            }

            // checking if both password are equal
            if (data.password !== data.secondPassword) {
                return setValidation({ message: 'The password and confirmation password do not match.', isValid: false })
            }

            const dataToServer = { password: data.password, recovery_token: token }
            await axios.post('/api/v1/recovery/password/', dataToServer, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
                .then((response) => {
                    setData({ password: '', secondPassword: '' })
                    setFieldValidations({})
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        navigate('/login/')
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } catch (error) {
            setValidation({ message: 'An unexpected error occured. Try again later...', isValid: false })
        }
    }

    return (
        <>
            <Helmet>
                <title>Destiny Tour - Recovery</title>
            </Helmet>

            <div className={styles['form-page']}>
                <div className={styles['form-image']}>
                    <img src='/assets/accounts/recovery.svg' alt='Login Form' />
                </div>
                <div className={styles['recovery-menu']}>
                    <h1 className={styles['form-title']}>Password Recovery</h1>
                    <p className={styles['form-title--description']}>Here you can change your password according to your request.</p>

                    <form className={styles['recovery-form']} onSubmit={(e) => requestNewPassword(e)}>
                        <div className={styles['form-field']}>
                            <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                            <input className={styles['form-input']} type='password' value={data.password} name='password' onChange={(e) => handleInputs(e)} placeholder='New Password' autoComplete='password' />
                        </div>
                        {fieldValidations?.password?.errorMessage &&
                            <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                <p className={styles['field-error']}>{fieldValidations.password.errorMessage}</p>
                            </div>
                        }

                        <div className={styles['form-field']}>
                            <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                            <input className={styles['form-input']} type='password' value={data.secondPassword} name='secondPassword' onChange={(e) => handleInputs(e)} placeholder='Confirm New Password' autoComplete='password' />
                        </div>
                        {fieldValidations?.secondPassword?.errorMessage &&
                            <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                <p className={styles['field-error']}>{fieldValidations.secondPassword.errorMessage}</p>
                            </div>
                        }

                        <div className={styles['form-field']}>
                            <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Update'
                                disabled={(fieldValidations?.password?.isValid && fieldValidations?.secondPassword?.isValid) ? '' : true}
                            />
                        </div>
                    </form>

                    {validation.message &&
                        <div className={`${globalStyles['validation--message']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                            {validation.message}
                        </div>
                    }

                    <div className={styles['account-util']}>
                        <p>You don't have an account? <Link to='/register/'>Create one</Link></p>
                        <p>You already have an account? <Link to='/login/'>Authenticate now</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordRecovery