import { useState } from 'react'

const useFieldValidation = () => {
    const [fieldValidations, setFieldValidations] = useState({})

    const validationRules = {
        name: {
            regex: /^[a-zA-Z-ăâîșțĂÂÎȘȚ\s]+$/,
            errorMessages: {
                empty: 'The name should be filled.',
                invalidFormat: 'The name should contain only lowercase and uppercase letters, spaces, and the symbol minus (-).'
            }
        },
        identification_number: {
            regex: /^[a-zA-Z0-9]+$/,
            errorMessages: {
                empty: 'The identification number should be filled.',
                invalidFormat: 'The identification number should contain only lowercase and uppercase letters and digit characters.'
            }
        },
        business_register: {
            regex: /^[a-zA-Z0-9 /]+$/,
            errorMessages: {
                empty: 'The business register number should be filled.',
                invalidFormat: 'The business register number should contain only lowercase and uppercase letters, spaces, digits and symbol ( / ).'
            }
        },
        email: {
            regex: /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            errorMessages: {
                empty: 'The email address should be filled.',
                invalidFormat: 'The email address has an wrong format. It should be like noreply@domain.com'
            }
        },
        phone: {
            regex: /^\+[0-9]{8,15}$/,
            errorMessages: {
                empty: 'The phone number should be filled.',
                invalidFormat: 'The phone number has an wrong format. Should have country\'s prefix (+) and should be between 8 - 15 digits.'
            }
        },
        current_password: {
            regex: /^[a-zA-Z-ăâîșțĂÂÎȘȚ0-9?!@#$%^&*\s]*$/,
            errorMessages: {
                empty: 'The password should be filled.',
                length: 'The password should be between 6-32 characters.',
                invalidFormat: 'The password contains special characters.'
            }
        },
        password: {
            regex: /^[a-zA-Z-ăâîșțĂÂÎȘȚ0-9?!@#$%^&*\s]*$/,
            errorMessages: {
                empty: 'The password should be filled.',
                length: 'The password should be between 6-32 characters.',
                invalidFormat: 'The password contains special characters.'
            }
        },
        secondPassword: {
            regex: /^[a-zA-Z-ăâîșțĂÂÎȘȚ0-9?!@#$%^&*\s]*$/,
            errorMessages: {
                empty: 'The password should be filled.',
                length: 'The password should be between 6-32 characters.',
                invalidFormat: 'The password contains special characters.'
            }
        },
        address: {
            regex: /^[a-zA-Z0-9.,()\s]*$/,
            errorMessages: {
                empty: 'The billing address should be filled.',
                invalidFormat: 'The billing address should contain only lowercase and uppercase letters, spaces, numbers and the following symbols: . , - ( ).'
            }
        },
        bank_name: {
            regex: /^[a-zA-ZăâîșțĂÂÎȘȚ\s]+$/,
            errorMessages: {
                invalidFormat: 'The bank name should contain only lowercase and uppercase letters and spaces.'
            }
        },
        bank_iban: {
            regex: /^[a-zA-Z0-9 ]{29}$/, // 29 chars - 24 for IBAN format and 5 for white spaces generated automatically
            errorMessages: {
                invalidFormat: 'The bank iban should be exactly 24 characters and contain only lowercase and uppercase letters and numbers.'
            }
        },
        otp_code: {
            regex: /^[0-9]{6}$/, // should be exactly 5 digits
            errorMessages: {
                invalidFormat: 'Your OTP code is not correct. This should be exactly 6 digit code.'
            }
        }
    }

    const checkFieldValidation = (name, value) => {
        const rules = validationRules[name]
        if (rules) {
            const { regex, errorMessages } = rules
            const trimmedValue = value.trim()
            let errorMessage = ''

            if (trimmedValue === '') errorMessage = errorMessages.empty
            else if (!regex.test(trimmedValue)) errorMessage = errorMessages.invalidFormat
            else if (
                (name === 'password' || name === 'secondPassword' || name === 'current_password') &&
                (trimmedValue.length < 6 || trimmedValue.length > 32)
            ) errorMessage = errorMessages.length

            let isValid = errorMessage?.length === 0

            if (trimmedValue === '' && (name === 'bank_name' || name === 'bank_iban'))
                isValid = true

            setFieldValidations((prevValidations) => (
                { ...prevValidations, [name]: { errorMessage, isValid } })
            )
        }
    }

    return { fieldValidations, setFieldValidations, checkFieldValidation }
}

export default useFieldValidation