import SocialMedia from '../SocialMedia/SocialMedia'

import styles from './FooterLinks.module.sass'

function FooterLinks() {
    return (
        <>
            <SocialMedia />
            <div className={styles['footer--links__grid']}>
                <div className={styles['column']}>
                    <h3 className={styles['title']}>Our Destinations</h3>
                    <div className={styles['links--stack']}>
                        <a href='#' className={styles['link']}>Canada</a>
                        <a href='#' className={styles['link']}>Alaska</a>
                        <a href='#' className={styles['link']}>France</a>
                        <a href='#' className={styles['link']}>Iceland</a>
                    </div>
                </div>
                <div className={styles['column']}>
                    <h3 className={styles['title']}>Our Activities</h3>
                    <div className={styles['links--stack']}>
                        <a href='#' className={styles['link']}>Northen Lights</a>
                        <a href='#' className={styles['link']}>Cruising & Sailing</a>
                        <a href='#' className={styles['link']}>Multi-Activities</a>
                        <a href='#' className={styles['link']}>Kayaing</a>
                    </div>
                </div>
                <div className={styles['column']}>
                    <h3 className={styles['title']}>Travel Blogs</h3>
                    <div className={styles['links--stack']}>
                        <a href='#' className={styles['link']}>Bali Travel Guide</a>
                        <a href='#' className={styles['link']}>Sri Lanks Travel Guide</a>
                        <a href='#' className={styles['link']}>Peru Travel Guide</a>
                    </div>
                </div>
                <div className={styles['column']}>
                    <h3 className={styles['title']}>About Us</h3>
                    <div className={styles['links--stack']}>
                        <a href='#' className={styles['link']}>Our Story</a>
                        <a href='#' className={styles['link']}>Work With Us</a>
                    </div>
                </div>
                <div className={styles['column']}>
                    <h3 className={styles['title']}>Contact Us</h3>
                    <div className={styles['links--stack']}>
                        <a href='#' className={styles['link']}>Our Story</a>
                        <a href='#' className={styles['link']}>Work With Us</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterLinks