import ProfileBanner from './ProfileBanner/ProfileBanner'
import ProfileAvatar from './ProfileAvatar/ProfileAvatar'

function ProfileHeader() {
    return (
        <>
            <ProfileBanner />
            <ProfileAvatar />
        </>
    )
}

export default ProfileHeader