import useGetAllTours from '../../hooks/useGetAllTours'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'
import ToursTable from '../Components/Tables/ToursTable'

import styles from '../Admin.module.sass'

function Tours() {
    const tours = useGetAllTours()

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Tours'} />
                    <div className={styles['container']}>
                        <ToursTable tours={tours} itemsPerPage={10} hasAddButton={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tours