import { useState, useEffect } from 'react'
import { BASE_URL } from '../../../api/axios'

import useAxiosPrivate from '../../../api/useAxiosPrivate'
import useDestinationById from '../../../hooks/useDestinationById'

import Pagination from './Pagination/Pagination'
import { FormModal, FormModalField } from '../FormModal/FormModal'

import styles from './Tables.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function DestinationsTable({ destinations, itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const currentItems = destinations.length && destinations.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [data, setData] = useState({
        id: '',
        destination_name: '',
        representative_image_url: ''
    })
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [isNew, setIsNew] = useState(false)

    const axiosPrivate = useAxiosPrivate()
    const destination = useDestinationById(data.id)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const addNewDestination = () => {
        setOpenModal(!openModal)
        setData({ id: '', destination_name: '', representative_image_url: '' })
        setValidation({ message: '', isValid: false })
        setIsNew(!isNew)
    }

    const handleModal = (id) => {
        if (openModal && isNew) {
            setIsNew(!isNew)
        }

        setOpenModal(!openModal)
        setData({ ...data, id })
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target;
        if (e.target.files && e.target.files[0]) {
            setData({ ...data, [name]: e.target.files[0] })
        } else {
            setData({ ...data, [name]: value })
        }
    }

    const deleteRepresentativeImage = () => {
        setData({ ...data, representative_image_url: '' })
    }

    const updateData = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key])
        })

        if (isNew) {
            await axiosPrivate.post('/api/v1/tours/destinations/add/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setData({ id: '', destination_name: '', representative_image_url: '' })
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })

        } else {
            await axiosPrivate.put(`/api/v1/tours/destinations/update/${data.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        }
    }

    useEffect(() => {
        if (data.id) {
            setData({
                ...data,
                destination_name: destination.destination_name,
                representative_image_url: destination.representative_image_url
            })
        }
    }, [destination])

    return (
        <>
            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Destinations</h2>

                        {hasAddButton && (
                            <button className={styles['button']} onClick={() => addNewDestination()}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['destinations--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Representative Image</div>
                                <div className={styles['heading']}>Destination Name</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['destinations--table']}`}>
                            {currentItems ? (
                                <>
                                    {currentItems.map((destination, i) => (
                                        <div key={i} className={styles['row']}>
                                            <div className={styles['text']}>{i + 1}</div>
                                            <div className={styles['text']}>
                                                <img src={BASE_URL + destination.representative_image_url} alt={destination?.destination_name} />
                                            </div>
                                            <div className={styles['text']}>{destination.destination_name ? destination.destination_name : 'No destination name.'}</div>
                                            <div className={styles['text']}>
                                                <div className={styles['tool--button']} onClick={() => handleModal(destination.id)}>
                                                    <i className="fa-solid fa-highlighter"></i>
                                                    Edit
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className={globalStyles['no-data--message']}>There is not data yet...</p>
                            )}

                            <Pagination totalItems={destinations.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title={isNew ? 'Add Destination' : 'Edit Destination'} closeFunction={handleModal}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Destination Name' inputName='destination_name' inputValue={data.destination_name} inputUpdate={updateFormInput} />
                            </div>
                        </div>

                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Representative Image</label>

                                    <div className={globalStyles['image--gallery']}>
                                        <div className={globalStyles['item']}>
                                            {!data.representative_image_url ? (
                                                <>
                                                    <label htmlFor='representative_image' className={globalStyles['upload-button']}>
                                                        <i className={`fa-solid fa-arrow-up-from-bracket`}></i>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        id='representative_image'
                                                        name='representative_image_url'
                                                        className={globalStyles['upload-input']}
                                                        accept='image/png, image/jpg, image/jpeg, image/svg'
                                                        onChange={(e) => updateFormInput(e)}
                                                    />
                                                </>
                                            ) : (
                                                <div className={globalStyles['image-uploaded']}>
                                                    <img src={data.representative_image_url?.name
                                                        ? URL.createObjectURL(data.representative_image_url)
                                                        : BASE_URL + data.representative_image_url} alt={data.destination_name} />
                                                    <div className={globalStyles['delete-image']} onClick={() => deleteRepresentativeImage()}>X</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            {isNew ? 'Add': 'Update'}
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default DestinationsTable