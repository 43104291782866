const useRoleList = () => {
    const roleList = {
        "admin": 9999,
        "editor": 2000,
        "user": 1000
    }

    return roleList
}

export default useRoleList