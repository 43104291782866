import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'
import NewsletterTable from '../Components/Tables/NewsletterTable'

import styles from '../Admin.module.sass'

function Newsletter() {
    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Newsletter'} />
                    <div className={styles['container']}>
                        <NewsletterTable itemsPerPage={10} hasAddButton={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newsletter