import styles from '../Panel.module.sass'

function PanelContentRow({ label, value }) {
    return (
        <>
            <div className={styles['row']}>
                <div className={styles['info']}>
                    <label className={styles['label']}>{label}</label>
                    <span className={styles['text']}>
                        {value === ''
                        || value === 'null'
                        || value === null
                        || value === undefined
                            ? 'No data here yet.'
                            : value
                        }
                    </span>
                </div>
            </div>
        </>
    )
}

export default PanelContentRow