import { useState } from 'react'
import { useFormattedDate, useFormattedTime } from '../../../../../functions/useDateTimeFormat'
import useFieldValidation from '../../../../../functions/useFieldValidation'
import usePutAccountById from '../../../../../hooks/account_methods/usePutAccountById'
import useAuth from '../../../../../authManager/useAuth'

import PanelContentRow from './PanelContentRow/PanelContentRow'
import { FormModal, FormModalField, FormModalValidation } from '../../../General/FormModal/FormModal'

import styles from './Panel.module.sass'
import modalStyles from '../../../General/FormModal/FormModal.module.sass'

function AccountPanel({ data }) {
    const [formData, setFormData] = useState({ id: '', name: '', email: '', phone: '' })
    const [message, setMessage] = useState({ color: '', text: '' })
    const [openModal, setOpenModal] = useState(false)

    const { auth } = useAuth()
    const { updateAccountDetails } = usePutAccountById()
    const { fieldValidations, setFieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    const registrationDate = useFormattedDate(data?.registration_date)
    const registrationTime = useFormattedTime(data?.registration_date)

    const toggleModal = () => {
        // toggle the modal state
        setOpenModal(!openModal)

        // we set the form state with the props data that are fetch from database
        setFormData({
            id: auth.id,
            name: data.name,
            email: data.email,
            phone: data.phone
        })

        // we clear all the validations in the case we have some
        setFieldValidations({})

        // we check to not validate by default empty inputs on
        // modal open but additional validation on submit form
        if (data.name) checkFieldValidation('name', data.name)
        if (data.email) checkFieldValidation('email', data.email)
        if (data.phone) checkFieldValidation('phone', data.phone)

        // we clear the general message in the case we have some
        setMessage({ color: '', text: '' })
    }

    const updateFormInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        checkFieldValidation(e.target.name, e.target.value)
    }

    const updateData = async (e) => {
        e.preventDefault()

        // precheck if all mandatory fields are filled
        if (!formData.name || !formData.email || !formData.phone)
            return setMessage({ color: 'color-red', text: 'All fields marked with * must be filled.' })

        // checking if inputs meets the criteria
        if (isAnyFieldInvalid || Object.keys(fieldValidations).length === 0) return setMessage({ color: 'color-red', text: 'One or more fields are not filled properly.' })

        const response = await updateAccountDetails(formData)
        if (response?.data) {
            if (response.data.status === 200) {
                setMessage({ color: 'color-green', text: response.data.message })
                setTimeout(() => {
                    setMessage({ color: '', text: '' })
                    setOpenModal(!openModal)
                }, 3000)
            }
        }
    }

    return (
        <>
            <div className={styles['panel--container']}>
                <h2 className={styles['panel--title']}>
                    Account Information

                    <div className={styles['save--button']}>
                        <button className={styles['button']} onClick={() => toggleModal()}>
                            <i className='fa-solid fa-pencil'></i>
                            Edit
                        </button>
                    </div>
                </h2>
                <div className={styles['panel--data']} onSubmit={(e) => updateData(e)}>
                    <PanelContentRow label={'Name'} value={data?.name} />
                    <PanelContentRow label={'Email Address'} value={data?.email} />
                    <PanelContentRow label={'Phone Number'} value={data?.phone} />
                    <PanelContentRow label={'Created At'} value={`${registrationDate} (at a time ${registrationTime})`} />
                    <PanelContentRow label={'2FA Authentication'} value={data?.twofa_enabled ? 'Enabled' : 'Disabled'} />
                </div>
                
                {openModal && (
                    <FormModal title={'Account Information'} closeFunction={toggleModal}>
                        <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Name' inputName='name' inputValue={formData.name} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.name?.errorMessage} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Email Address' inputName='email' inputValue={formData.email} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.email?.errorMessage} />
                                </div>
                            </div>
                            <div className={modalStyles['row']}>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='* Phone Number' inputName='phone' inputValue={formData.phone} inputUpdate={updateFormInput} />
                                    <FormModalValidation inputFieldCheck={fieldValidations?.phone?.errorMessage} />
                                </div>
                            </div>

                            {message.text &&
                                <div className={`${styles['message']} ${styles[message.color]}`}>
                                    <p className={styles['message--text']}>{message.text}</p>
                                </div>
                            }

                            <button className={modalStyles['modal--save']}>
                                <i className='fa-solid fa-pen-to-square'></i>
                                Update
                            </button>
                        </form>
                    </FormModal>
                )}
            </div>
        </>
    )
}

export default AccountPanel