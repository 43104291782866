import styles from './FormModal.module.sass'

export function FormModal({ title, children, closeFunction }) {
    return (
        <>
            <div className={styles['modal--container']}>
                <div className={styles['modal--container__inner']}>
                    <div className={styles['modal--header']}>
                        {title && <h1 className={styles['title']}>{title}</h1>}
                        <div className={styles['close--button']} onClick={() => closeFunction()}>
                            <i className='fa-solid fa-xmark'></i>
                        </div>
                    </div>

                    {children}
                </div>
            </div>
        </>
    )
}

export function FormModalField({ inputLabel, inputName, inputValue, inputUpdate }) {
    return (
        <>
            <div className={styles['input--field']}>
                <input
                    type='text'
                    className={styles['input']}
                    id={inputName}
                    name={inputName}
                    value={inputValue !== null && inputValue !== undefined ? inputValue : ''}
                    onChange={(e) => inputUpdate(e)}
                />
                <label
                    htmlFor={inputName}
                    className={`${styles['label']} ${inputValue !== '' && inputValue !== null && inputValue !== undefined ? styles['filled'] : ''}`}
                >{inputLabel}</label>
            </div>
        </>
    )
}

export function FormModalValidation({ inputFieldCheck }) {
    return (
        <>
            {inputFieldCheck &&
                <div className={`${styles['form-field']} ${styles['input-error']}`}>
                    <p className={styles['field-error']}>{inputFieldCheck}</p>
                </div>
            }
        </>
    )
}