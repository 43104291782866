import { useEffect } from 'react'
import { axiosPrivate } from './axios'
import useRefreshToken from '../authManager/useRefreshToken'
import useAuth from '../authManager/useAuth'

const useAxiosPrivate = () => {
    const refresh = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers['Authorization'])
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`

                return config
            }, (error) => Promise.reject(error)
        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            async (response) => {
                const errorData = response?.data
                if (errorData?.status === 403) {
                    const newAccessToken = await refresh()
                    const newRequest = {
                        ...response.config,
                        headers: {
                            ...response.config.headers,
                            'Authorization': `Bearer ${newAccessToken}`
                        }
                    }

                    return axiosPrivate(newRequest)
                }
                return response
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    }, [auth, refresh])

    return axiosPrivate
}

export default useAxiosPrivate