import Header from '../../Components/Header/Header'
import ProfileHeader from '../../Components/Profile/ProfileHeader/ProfileHeader'
import ProfileNavMenu from '../../Components/Profile/ProfileNavMenu/ProfileNavMenu'
import AccountInfo from '../../Components/Profile/AccountInfo/AccountInfo'
import Footer from '../../Components/Footer/Footer'

import clientStyles from '../../Client.module.sass'

function Profile() {
    return (
        <>
            <Header />
            <ProfileHeader />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['profile--flex']}>
                    <ProfileNavMenu />
                    <AccountInfo />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Profile