import { useState } from 'react'
import useAxiosPrivate from '../../../../api/useAxiosPrivate'

import styles from './Newsletter.module.sass'
import clientStyles from '../../../Client.module.sass'

function Newsletter() {
    const mailBox = '/assets/footer/mailbox.svg'
    const [email, setEmail] = useState({ email: '', validationMessage: '', isValid: false })

    const axiosPrivate = useAxiosPrivate()

    const updateEmail = (e) => {
        const { name, value } = e.target
        setEmail({
            ...email,
            [name]: value
        })
    }

    const submitMessage = async (e) => {
        e.preventDefault()
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (!email.email.length) {
            return setEmail({ ...email, validationMessage: 'Email address field should not be empty.', isValid: false })
        }

        if (!emailPattern.test(email.email)) {
            return setEmail({ ...email, validationMessage: 'Please enter a valid email address.', isValid: false })
        }

        const dataToServer = {
            email: email.email
        }

        await axiosPrivate.post('/api/v1/newsletters/', dataToServer)
            .then((response) => {
                setEmail({ email: '', validationMessage: response.data.message, isValid: true })
                setTimeout(() => {
                    setEmail({ email: '', validationMessage: '', isValid: false})
                }, 3000)
            })
            .catch((error) => {
                setEmail({ ...email, validationMessage: error.response.data.message, isValid: false })
            }) 
    }

    return (
        <>
            <div className={clientStyles['page--size']}>
                <div className={styles['newsletter--container']}>
                    <div className={styles['newsletter--content']}>
                        <h2 className={styles['title']}>Subscribe Newsletter</h2>

                        <div className={styles['newsletter--bottom']}>
                            <p className={styles['caption']}>Get inspired! Receive travel discounts, tips and behind the scenes stories.</p>
                            <form className={styles['form']} onSubmit={(e) => submitMessage(e)}>
                                <input
                                    type='text'
                                    name='email'
                                    placeholder='Your email address'
                                    className={styles['input']}
                                    value={email.email}
                                    onChange={(e) => updateEmail(e)}
                                />
                                <input
                                    type='submit'
                                    value='Subscribe'
                                    className={styles['submit']}
                                />
                            </form>
                            {email.validationMessage && (
                                <p className={`${styles['newsletter--message']} ${email.isValid ? styles['valid'] : styles['invalid']}`}>
                                    {email.validationMessage}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles['mailbox-icon']}>
                        <img src={mailBox} alt='Destiny Tour Newsletter' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newsletter