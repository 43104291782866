import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import useGetAllDestinations from '../../hooks/useGetAllDestinations'

import Header from '../Components/Header/Header'
import Banner from '../Components/Header/Banner/Banner'
import BookingForm from '../Components/General/BookingForm/BookingForm'
import Footer from '../Components/Footer/Footer'

import DestinationCards from '../Components/General/DestinationCards/DestinationCards'
import CardImage from '../Components/General/ToursGrid/ToursGrid'
import LatestTour from '../Components/General/LatestTour/LatestTour'

import clientStyles from '../Client.module.sass'

function Landing() {
    const navigate = useNavigate()
    const destinations = useGetAllDestinations()

    const [destinationFilter, setDestinationFilter] = useState(1)
    const [dateFilter, setDateFilter] = useState({ from: '', to: '' })

    const showTours = () => {
        navigate(`/destinations/${destinations[destinationFilter - 1].destination_name.toLowerCase()}`, {
            state: { destinationFilter, dateFilter }
        })
    }

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Destiny Tour - We will make your travel a happiness</title>
            </Helmet>

            <Header />
            <Banner
                img={'/assets/banners/landing_banner.jpg'}
                title={'Make your travel wishlist, we’ll do the rest'}
                subtitle={'You can find the most beautiful places to travel.'}
            />
            
            <div className={`${clientStyles['page--size']} ${clientStyles['booking--negative_margin']}`}>
                <BookingForm
                    hasTitle={true}
                    hasButton={true}
                    destinations={destinations}
                    setDestinationFilter={setDestinationFilter}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    showTours={showTours}
                />
            </div>

            <DestinationCards />
            <CardImage />
            <LatestTour />
            <Footer />
        </>
    )
}

export default Landing