import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useGetAllOrders = () => {
    const axiosPrivate = useAxiosPrivate()
    const [orders, setOrders] = useState({})
    
    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getAllOrders = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/orders/`, { signal: controller.signal })
                isMounted && setOrders(response.data)
            } catch (error) {
                return
            }
        }

        getAllOrders()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [orders])

    return orders
}

export default useGetAllOrders