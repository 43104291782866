import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import useAxiosPrivate from '../../../../api/useAxiosPrivate'
import useAuth from '../../../../authManager/useAuth'
import { useFormattedDate } from '../../../../functions/useDateTimeFormat'

import styles from './Bookings.module.sass'
import clientStyles from '../../../Client.module.sass'

function Bookings() {
    const [orders, setOrders] = useState({})

    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        axiosPrivate.get(`/api/v1/orders/account/${auth.id}`)
            .then((response) => {
                setOrders(response.data)
            })
            .catch ((error) => {
                console.log(error)
            })
    }, [orders])

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={clientStyles['profile--page']}>
            <h2 className={clientStyles['page--title']}>My Bookings</h2>
            <div className={clientStyles['page--panel']}>
                <div className={styles['bookings']}>
                    <div className={styles['label-headings']}>
                        <span className={styles['label']}>Tour Name</span>
                        <span className={styles['label']}>Departure Date</span>
                        <span className={styles['label']}>Total Price</span>
                        <span className={styles['label']}>Status</span>
                    </div>
                    <div className={styles['order-items']}>
                        {orders.length ? (
                            Object.values(orders).map((order) => (
                                <Link key={order.id} className={styles['item']} to={`/account/my-bookings/order/${order.id}`}>
                                    <span className={styles['tour-title']}>{order.tour_title}</span>
                                    <span className={styles['departure-date']}>{useFormattedDate(order.departure_date)}</span>
                                    <span className={styles['total-price']}>{order.total_price}€</span>
                                    <span className={clientStyles[order.status]}>{order.status}</span>
                                </Link>
                            ))
                        ) : (
                            <p>There are no orders yet.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bookings