import styles from './OrderData.module.sass'
import adminStyles from '../../Admin.module.sass'

function OrderTravellers({ data }) {
    const adultsArray = data?.travellers?.adult ? Object.values(data.travellers.adult) : []
    const childsArray = data?.travellers?.child ? Object.values(data.travellers.child) : []
    const travellersArray = [
        ...adultsArray.map(traveller => ({ ...traveller, type: 'adult' })),
        ...childsArray.map(traveller => ({ ...traveller, type: 'child' }))
    ]

    return (
        <div className={`${styles['order--details-box']} ${styles['travellers']}`}>
            <h2 className={adminStyles['box-title']}>Travellers</h2>
            {travellersArray.length ? (
                <div className={styles['box--content']}>
                    {travellersArray.map((traveller, index) => (
                        <div key={index} className={styles['row']}>
                            <div className={styles['label']}>Traveller {index + 1} ({traveller.type}):</div>
                            <div className={styles['value']}>
                                {traveller.name} - <strong>Identification Number:</strong> {traveller.identification_number}
                                {traveller.type === 'child' && (
                                    <>
                                        {' - '}<strong>Age:</strong> {traveller.age} year{traveller.age == 1 ? '' : 's'}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className={styles['no-data']}>There are no travellers yet.</p>
            )}
        </div>
    )
}

export default OrderTravellers