import NavBar from './NavBar/NavBar'
import NavMenu from './NavMenu/NavMenu'

function Header() {
    const fixedMenu = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10000
    }

    return (
        <div style={fixedMenu}>
            <NavBar />
            <NavMenu />
        </div>
    )
}

export default Header