import useAxiosPrivate from '../../api/useAxiosPrivate'

const usePutAccountPassword = () => {
    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController()

    const updateAccountPassword = async (data) => {
        try {
            const response = await axiosPrivate.put(`/api/v1/accounts/change-password/${data.id}`, data, { signal: controller.signal })
            return response
        } catch (error) {
            console.log(error)
        } finally {
            controller.abort()
        }
    }

    return { updateAccountPassword }
}

export default usePutAccountPassword