import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useGetAllAdditionalServicesByTourId = (id) => {
    const axiosPrivate = useAxiosPrivate()
    const [servicesByTourId, setServicesByTourId] = useState({})
    
    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getAllAdditionalServicesByTourId = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/tours/item/${id}/additional-services/`, { signal: controller.signal })
                isMounted && setServicesByTourId(response.data)
            } catch (error) {
                return
            }
        }

        getAllAdditionalServicesByTourId()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [servicesByTourId])

    return servicesByTourId
}

export default useGetAllAdditionalServicesByTourId