import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAuth from './useAuth'

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth, rememberDevice } = useAuth()

    useEffect(() => {
        let isMounted = true

        const verifyRefreshToken = async () => {
            try {
                await refresh()
            }
            catch (err) {
                console.error(err)
            }
            finally {
                isMounted && setIsLoading(false)
            }
        }

        !auth?.accessToken && rememberDevice ? verifyRefreshToken() : setIsLoading(false)

        return () => isMounted = false
    }, [])

    return (
        <>
            {!rememberDevice
                ? <Outlet />
                : isLoading
                    ? null
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin