import styles from './BookingForm.module.sass'
import clientStyles from '../../../Client.module.sass'

function BookingForm({ hasTitle = false, hasButton = false, ...props }) {
    const { destinations, destinationFilter, setDestinationFilter, dateFilter, setDateFilter, showTours } = props

    const changeDestination = (e) => {
        const { value } = e.target
        setDestinationFilter(value)
    }

    const changeDate = (e) => {
        const { name, value } = e.target
        setDateFilter({ ...dateFilter, [name]: value })
    }

    return (
        <div className={styles['booking--form']}>
            {hasTitle && <p className={styles['title']}>Where do you want to go?</p>}

            <div className={styles['inputs']}>
                <div className={clientStyles['input--field']}>
                    <label className={clientStyles['label-outside']}>Pick a Destination</label>
                    <select className={clientStyles['input']} value={destinationFilter} onChange={(e) => changeDestination(e)}>
                        {destinations.length ? (
                            <>
                                {!hasButton && <option value=''>All Destinations</option>}
                                {Object.values(destinations).map((destination) => (
                                    <option key={destination.id} value={destination.id}>{destination.destination_name}</option>
                                ))}
                            </>
                        ) : (
                            <option value=''>Loading...</option>
                        )}
                    </select>
                </div>
                <div className={clientStyles['input--field']}>
                    <label className={clientStyles['label-outside']}>From Date</label>
                    <input type='date' className={clientStyles['input']} value={dateFilter.from} name='from' onChange={(e) => changeDate(e)} />
                </div>
                <div className={clientStyles['input--field']}>
                    <label className={clientStyles['label-outside']}>To Date</label>
                    <input type='date' className={clientStyles['input']} value={dateFilter.to} name='to' onChange={(e) => changeDate(e)} />
                </div>
            </div>

            {hasButton && (
                <div className={styles['booking--button']}>
                    <button className={styles['button']} onClick={() => showTours()}>
                        <i className='fa-solid fa-person-hiking'></i>
                        Show Tours
                    </button>
                </div>
            )}
        </div>
    )
}

export default BookingForm