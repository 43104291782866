import { useContext } from 'react'
import { MenuContext } from '../../Contexts/useContextMenu'

import styles from './UtilityBar.module.sass'

function UtilityBar({ title }) {
    const { toggleMenu } = useContext(MenuContext)

    return (
        <>
            <div className={styles['utility-bar']}>
                <div className={styles['utility-bar--utils']}>
                    <div className={styles['utility-bar--responsive-menu']} onClick={() => toggleMenu()}>
                        <div className={styles['utility-bar--hamburger']}></div>
                    </div>
                    <h1 className={styles['title']}>{title}</h1>
                </div>
            </div>
        </>
    )
}

export default UtilityBar