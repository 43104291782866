import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { MenuContext } from '../../Contexts/useContextMenu'
import useAuth from '../../../authManager/useAuth'
import useAccountRole from '../../../hooks/useAccountRole'

import styles from './NavMenu.module.sass'

function NavMenu() {
    const { toggle } = useContext(MenuContext)
    const location = useLocation()

    const { auth } = useAuth()
    const userRoles = useAccountRole(auth.roles)
    const { isAdmin } = userRoles

    const isActive = (path) => {
        const currentPath = location.pathname
        if (path === '/admin' || path === '/admin/') {
            return currentPath === path
        } else {
            return currentPath.startsWith(path)
        }
    }

    return (
        <>
            <div className={`${styles['admin--menu']} ${toggle ? styles['menu--small'] : ''}`}>
                <div className={styles['nav-menu--items']}>
                    <div className={styles['items--section']}>
                        <span className={styles['section--title']}>General</span>
                        <div className={styles['section--items']}>
                            <Link to='/admin/' className={`${styles['link']} ${isActive('/admin') || isActive('/admin/') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-grip ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Dashboard</span>
                            </Link>
                        </div>
                    </div>
                    {isAdmin && (
                        <div className={styles['items--section']}>
                            <span className={styles['section--title']}>Accounts</span>
                            <div className={styles['section--items']}>
                                <Link to='/admin/users/' className={`${styles['link']} ${isActive('/admin/users') ? styles['active'] : ''}`}>
                                    <i className={`fa-solid fa-users ${styles['icon']}`}></i>
                                    <span className={styles['link--text']}>Users</span>
                                </Link>
                                {/* <Link to='/admin/invoicing/' className={`${styles['link']} ${isActive('/admin/invoicing') ? styles['active'] : ''}`}>
                                    <i className={`fa-solid fa-file ${styles['icon']}`}></i>
                                    <span className={styles['link--text']}>Invocing Data</span>
                                </Link> */}
                            </div>
                        </div>
                    )}
                    <div className={styles['items--section']}>
                        <span className={styles['section--title']}>Booking</span>
                        <div className={styles['section--items']}>
                            <Link to='/admin/tours/' className={`${styles['link']} ${isActive('/admin/tours') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-route ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Tours</span>
                            </Link>
                            <Link to='/admin/destinations/' className={`${styles['link']} ${isActive('/admin/destinations') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-map ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Destinations</span>
                            </Link>
                            <Link to='/admin/coupons/' className={`${styles['link']} ${isActive('/admin/coupons') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-ticket ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Coupons</span>
                            </Link>
                            <Link to='/admin/additional-services/' className={`${styles['link']} ${isActive('/admin/additional-services') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-suitcase ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Additional Services</span>
                            </Link>
                            <Link to='/admin/orders/' className={`${styles['link']} ${isActive('/admin/orders') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-folder-tree ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Orders</span>
                            </Link>
                        </div>
                    </div>
                    <div className={styles['items--section']}>
                        <span className={styles['section--title']}>Social</span>
                        <div className={styles['section--items']}>
                            <Link to='/admin/newsletter/' className={`${styles['link']} ${isActive('/admin/newsletter/') || isActive('/admin/newsletter/') ? styles['active'] : ''}`}>
                                <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                                <span className={styles['link--text']}>Newsletter</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavMenu