import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../api/useAxiosPrivate'

const useBusinessInvoicingById = (id) => {
    const [businessInvoicing, setBusinessInvoicing] = useState({})
    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        if (!id) return

        let isMounted = true
        const controller = new AbortController()

        const getDataForBusinessInvoicing = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/accounts/business-invoicing/${id}`, { signal: controller.signal })
                isMounted && setBusinessInvoicing(response.data)
            } catch (error) {
                return
            }
        }

        getDataForBusinessInvoicing()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [id, businessInvoicing])

    return businessInvoicing
}

export default useBusinessInvoicingById