import { useState, useEffect } from 'react'

import useAuth from '../../authManager/useAuth'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import useAccountRole from '../../hooks/useAccountRole'

import useGetAllUsers from '../../hooks/useGetAllUsers'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'

import BoxCards from '../Components/BoxCards/BoxCards'
import UsersTable from '../Components/Tables/UsersTable'

import styles from '../Admin.module.sass'
import globalStyles from '../../styles/Globals.module.sass'

function Dashboard() {
    const [totals, setTotals] = useState({})
    const users = useGetAllUsers()

    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const userRoles = useAccountRole(auth.roles)
    const { isAdmin } = userRoles

    useEffect(() => {
        try {
            const getTotals = async () => {
                const response = await axiosPrivate.get('/api/v1/admin/')
                setTotals(response.data)
            }

            getTotals()
        }
        catch (error) {
            console.error(error)
        }
    }, [totals])

    const boxInfo = [
        { "counter": totals?.users, "text": "Total of Users", "icon": "fa-solid fa-users" },
        { "counter": totals?.tours, "text": "Total of Tours", "icon": "fa-solid fa-map-pin" },
        { "counter": totals?.destinations, "text": "Total of Destinations", "icon": "fa-solid fa-location-dot" },
        { "counter": totals?.orders, "text": "Total of Orders", "icon": "fa-solid fa-folder-tree" },
        { "counter": totals?.coupons, "text": "Total of Coupons", "icon": "fa-solid fa-ticket"},
        { "counter": totals?.additional_services, "text": "Total of Additional Services", "icon": "fa-solid fa-taxi"},
        { "counter": totals?.newsletter, "text": "Total of Emails", "icon": "fa-solid fa-envelope"},
        { "counter": totals?.user_details + totals?.company_details, "text": "Total of Invoicing Data", "icon": "fa-solid fa-file"},
    ]

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Dashboard'} />
                    <div className={styles['container']}>
                        <BoxCards data={boxInfo} columns={4} />

                        {isAdmin && (
                            <div className={globalStyles['mb--20']}>
                                {users.length ?
                                    <UsersTable users={users} itemsPerPage={5} />
                                    : <p className={styles['loading']}><i className='fa-solid fa-spinner'></i> Loading data...</p>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard