import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from '../../api/axios'

import useFieldValidation from '../../functions/useFieldValidation'

import styles from './Accounts.module.sass'

function Register() {
    const invalidTokenImg = '/assets/invalid.svg'
    const validTokenImg = '/assets/success.svg'

    const navigate = useNavigate()
    const { token } = useParams()

    const [data, setData] = useState({ name: '', email: '', password: '', secondPassword: '' })
    const [message, setMessage] = useState({ color: '', text: '' })

    const [tokenCheck, setTokenCheck] = useState(undefined)
    const [inputChecked, setInputChecked] = useState(false)

    const { fieldValidations, setFieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    if (token) {
        const checkToken = async (token) => {
            const dataToServer = { token: token }
            try {
                const res = await axios.post('/api/v1/signup', dataToServer, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
    
                if (res.data.status === 200) {
                    setTokenCheck(true)
                    setTimeout(() => { navigate('/login/') }, 5000)
                } else setTokenCheck(false)
            } catch (err) {
                setMessage({ color: 'color-red', text: 'An unexpected error occurred. Try again later...' })
            }
        }

        useEffect(() => {
            checkToken(token)
        }, [token])
    }

    const checkRegister = () => setInputChecked(!inputChecked)

    const handleInputs = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        checkFieldValidation(e.target.name, e.target.value)
    }

    const registerAccount = async (e) => {
        e.preventDefault()

        // adding additional form check for inputs in the case we send with the empty fields
        checkFieldValidation('name', data.name)
        checkFieldValidation('email', data.email)
        checkFieldValidation('password', data.password)
        checkFieldValidation('secondPassword', data.secondPassword)

        // sending a message if the both passwords are not a match
        if (data.password !== data.secondPassword) return setMessage({ color: 'color-red', text: 'The password and confirmation password do not match.' })
        // checking on send if inputs are empty or the button is enabled hardly from devtool to exploit the form
        if (Object.keys(fieldValidations).length === 0 || isAnyFieldInvalid) return setMessage({ color: 'color-red', text: 'One or more fields are not filled properly.' })
        // checking if terms and policies are accepted
        if (inputChecked === false) return setMessage({ color: 'color-red', text: 'You should be agree with Terms and Conditions and Privacy Policy.' })

        try {
            const dataToServer = { name: data.name, email: data.email, password: data.password }
            const res = await axios.post('/api/v1/signup', dataToServer, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })

            if (res.data.status === 400) setMessage({ color: 'color-red', text: res.data.message })
            else {
                setInputChecked(!inputChecked)
                setData({ name: '', email: '', password: '', secondPassword: '' })
                setFieldValidations({})
                setMessage({ color: 'color-green', text: res.data.message })
            }
        } catch (err) {
            setMessage({ color: 'color-red', text: 'An unexpected error occurred. Try again later...' })
        }
    }

    return (
        <>
            <Helmet>
                <title>Destiny Tour - Sign Up</title>
            </Helmet>

            <div className={styles['form-page']}>
                <div className={styles['form-image']}>
                    <img src='/assets/accounts/register.svg' alt='Register Form' />
                </div>
                <form className={styles['form']} onSubmit={(e) => registerAccount(e)}>
                    {!token ? (
                        <>
                            <h1 className={styles['form-title']}>Sign Up</h1>
                            <p className={styles['form-title--description']}>Let's get basics set up for you so you can access your account.</p>

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-user ${styles['icon']}`}></i>
                                <input className={fieldValidations?.name?.isValid === false ? `${styles['form-input']} ${styles['red']}` : styles['form-input']} value={data.name} type='text' name='name' onChange={(e) => handleInputs(e)} placeholder='First and Last Name' />
                            </div>
                            {fieldValidations?.name?.errorMessage &&
                                <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                    <p className={styles['field-error']}>{fieldValidations.name.errorMessage}</p>
                                </div>
                            }

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                                <input className={fieldValidations?.email?.isValid === false ? `${styles['form-input']} ${styles['red']}` : styles['form-input']} value={data.email} type='email' name='email' onChange={(e) => handleInputs(e)} placeholder='Email Address' />
                            </div>
                            {fieldValidations?.email?.errorMessage &&
                                <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                    <p className={styles['field-error']}>{fieldValidations.email.errorMessage}</p>
                                </div>
                            }

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-key ${styles['icon']}`}></i>
                                <input className={fieldValidations?.password?.isValid === false ? `${styles['form-input']} ${styles['red']}` : styles['form-input']} value={data.password} type='password' name='password' onChange={(e) => handleInputs(e)} placeholder='Password' />
                            </div>
                            {fieldValidations?.password?.errorMessage &&
                                <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                    <p className={styles['field-error']}>{fieldValidations.password.errorMessage}</p>
                                </div>
                            }

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-key ${styles['icon']}`}></i>
                                <input className={fieldValidations?.secondPassword?.isValid === false ? `${styles['form-input']} ${styles['red']}` : styles['form-input']} value={data.secondPassword} type='password' name='secondPassword' onChange={(e) => handleInputs(e)} placeholder='Confirmation Password' />
                            </div>
                            {fieldValidations?.secondPassword?.errorMessage &&
                                <div className={`${styles['form-field']} ${styles['input-error']}`}>
                                    <p className={styles['field-error']}>{fieldValidations.secondPassword.errorMessage}</p>
                                </div>
                            }

                            <div className={styles['form-field']}>
                                <input id='form-checkbox' type='checkbox' name='checkbox' onChange={() => checkRegister()} checked={inputChecked ? true : false} />
                                <label htmlFor='form-checkbox'>By checking this, you are agree with <Link to={'/'}>Terms and Conditions</Link> and <Link to={'/'}>Privacy Policy</Link>.</label>
                            </div>

                            <div className={styles['form-field']}>
                                <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Sign up' disabled={isAnyFieldInvalid ? true : false} />
                            </div>

                            {message.text &&
                                <div className={`${styles['message']} ${styles[message.color]}`}>
                                    <p className={styles['message--text']}>{message.text}</p>
                                </div>
                            }

                            <div className={styles['account-util']}>
                                <p>You already have an account? <Link to='/login/'>Authenticate now</Link></p>
                            </div>
                        </>
                    ) : (
                        <div className={styles['registration-confirmation']}>
                            {tokenCheck !== undefined ? (
                                tokenCheck ? (
                                    <>
                                        <img  className={styles['image']} src={validTokenImg} alt='Registration Account Valid Token' />
                                        <h1 className={styles['title']}>Account successfully activated!</h1>
                                        <p className={styles['info']}>You have successfully activated your account!</p>
                                        <p className={styles['info']}>You will be redirected to the Log In page in few seconds.</p>
                                    </>
                                ) : (
                                    <>
                                        <img  className={styles['image']} src={invalidTokenImg} alt='Registration Account Invalid Token' />
                                        <h1 className={styles['title']}>Invalid activation link</h1>
                                        <p className={styles['info']}>This activation link is not longer available.</p>
                                    </>
                                )
                            ) : ''}
                        </div>
                    )}
                </form>
            </div>
        </>
    )
}

export default Register