import { useState } from 'react'
import styles from './Pagination.module.sass'

function Pagination({ totalItems, itemsPerPage, onPageChange }) {
    const [currentPage, setCurrentPage] = useState(1)

    const totalPages = Math.ceil(totalItems / itemsPerPage)

    const handleFirstPage = () => {
        setCurrentPage(1)
        onPageChange(1)
    }

    const handlePreviousPage = () => {
        const newPage = currentPage > 1 ? currentPage - 1 : 1
        setCurrentPage(newPage)
        onPageChange(newPage)
    }

    const handleNextPage = () => {
        const newPage = currentPage < totalPages ? currentPage + 1 : totalPages;
        setCurrentPage(newPage)
        onPageChange(newPage)
    }

    const handleLastPage = () => {
        setCurrentPage(totalPages)
        onPageChange(totalPages)
    }

    const handleInputChange = (e) => {
        const { value } = e.target

        let num = parseInt(value)
        if (isNaN(num)) {
            num = 1
        } else if (num < 1) {
            num = 1
        } else if (num > totalPages) {
            num = totalPages
        }

        setCurrentPage(num)
        onPageChange(num)
    }

    return (
        <>
            <div className={styles['pagination']}>
                <button className={`${styles['pagination-btn']} ${styles['btn-first']} ${currentPage === 1 ? styles['disabled'] : ''}`} onClick={() => handleFirstPage()}>
                    <i className='fa-solid fa-angles-left icon'></i>
                </button>
                <button className={`${styles['pagination-btn']} ${styles['btn-prev']} ${currentPage === 1 ? styles['disabled'] : ''}`} onClick={() => handlePreviousPage()}>
                    <i className='fa-solid fa-chevron-left icon'></i>
                </button>

                <div className={styles['pagination-controller']}>
                    <input
                        className={styles['pagination-search']}
                        type='text'
                        value={currentPage}
                        onChange={(e) => handleInputChange(e)}
                    />
                    <span className={styles['pagination-total']}>of {totalPages}</span>
                </div>

                <button className={`${styles['pagination-btn']} ${styles['btn-next']} ${currentPage === totalPages ? styles['disabled'] : ''}`} onClick={() => handleNextPage()}>
                    <i className={`fa-solid fa-chevron-right ${styles['icon']}`}></i>
                </button>
                <button className={`${styles['pagination-btn']} ${styles['btn-last']} ${currentPage === totalPages ? styles['disabled'] : ''}`} onClick={() => handleLastPage()}>
                    <i className='fa-solid fa-angles-right icon'></i>
                </button>
            </div>
        </>
    )
}

export default Pagination