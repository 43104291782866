import App from './App'
import ReactDOM from 'react-dom/client'

import { AuthProvider } from './authManager/AuthProvider'
import { MenuProvider } from './admin/Contexts/useContextMenu'

// global styling
import GlobalStyles from './styles/Globals.module.sass'
import VariableStyles from './styles/Variables.module.sass'

// react base document format
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <AuthProvider>
        <MenuProvider>
            <App />
        </MenuProvider>
    </AuthProvider>
)