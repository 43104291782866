import { BASE_URL } from '../../../../api/axios'
import { Link } from 'react-router-dom'
import useGetAllTours from '../../../../hooks/useGetAllTours'

import styles from './LatestTour.module.sass'
import clientStyles from '../../../../styles/Globals.module.sass'
import useTourGalleryById from '../../../../hooks/useTourGalleryById'

function LatestTour() {
    const tours = useGetAllTours()
    const latestTour = tours[tours?.length - 1]
    const imgGallery = useTourGalleryById(latestTour?.id)

    return (
        <div className={clientStyles['page--size']}>
            <div className={clientStyles['page--gap__XL']}>
                <h2 className={clientStyles['module--title']}>Latest Tour You Can See</h2>
                <p className={clientStyles['module--description']}>Here is our latest tour available for you with a good price, good services and a lot of things to do. Go and reserve now, don't miss the opportunity.</p>
                {latestTour
                    ? (
                        <div className={styles['latest--grid']}>
                            <div className={styles['background']}>
                                <div className={styles['header']}>
                                    <h3 className={styles['title']}>{latestTour.tour_title}</h3>
                                    <div className={styles['price']}>
                                        {(latestTour.total_price && latestTour.total_price !== tour.adult_price) ? (
                                            latestTour.total_price + '€'
                                        ) : (
                                            (latestTour.child_price || latestTour.adult_price) ? (
                                                <>
                                                    <span className={styles['from']}>From</span>
                                                    {latestTour.child_price ? latestTour.child_price : latestTour.adult_price}€
                                                </>
                                            ) : (
                                                <p className={styles['no-price']}>No price yet.</p>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className={styles['body']}>
                                    <div dangerouslySetInnerHTML={{ __html: `${latestTour.tour_content.split(' ').slice(0, 75).join(' ')}...` }} />
                                </div>
                                <div className={styles['footer']}>
                                    <Link to={'/tours/' + latestTour.tour_link} className={styles['button']}>Book Now</Link>
                                </div>
                            </div>
                            <div className={styles['gallery']}>
                                <div className={styles['image']}>
                                    <img src={BASE_URL + imgGallery[0]?.image_url} alt={latestTour.tour_title} />
                                </div>
                                <div className={styles['image']}>
                                    <img src={BASE_URL + imgGallery[1]?.image_url} alt={latestTour.tour_title} />
                                </div>
                                <div className={styles['image']}>
                                    <img src={BASE_URL + imgGallery[2]?.image_url} alt={latestTour.tour_title} />
                                </div>
                                <div className={styles['image']}>
                                    <img src={BASE_URL + imgGallery[3]?.image_url} alt={latestTour.tour_title} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className={clientStyles['module--loading']}>Loading ...</p>
                    )}
            </div>
        </div>
    )
}

export default LatestTour