import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../authManager/useAuth'
import useAccountRole from '../../hooks/useAccountRole'

import useGetAllUsers from '../../hooks/useGetAllUsers'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'

import UsersTable from '../Components/Tables/UsersTable'

import styles from '../Admin.module.sass'

function Users() {
    const users = useGetAllUsers()

    const { auth } = useAuth()
    const navigate = useNavigate()
    const userRoles = useAccountRole(auth.roles)
    const { isAdmin } = userRoles

    useEffect(() => {
        if (!isAdmin) {
            navigate('/admin/')
        }
    }, [])

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Users'} />
                    <div className={styles['container']}>
                        {users.length ?
                            <UsersTable users={users} itemsPerPage={10} hasAddButton={true} />
                            : <p className={styles['loading']}><i className='fa-solid fa-spinner'></i> Loading data...</p>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users