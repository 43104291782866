import Newsletter from './Newsletter/Newsletter'
import FooterLinks from './FooterLinks/FooterLinks'

import styles from './Footer.module.sass'
import clientStyles from '../../Client.module.sass'

import config from '../../../config.json'

function Footer() {
    const getCurrentYear = new Date().getFullYear()

    return (
        <>
            <Newsletter />
            <div className={styles['footer--container']}>
                <div className={clientStyles['page--size']}>
                    <FooterLinks />

                    <div className={styles['footer--container__second']}>
                        <div className={styles['copyright']}>Copyright {config.COMPANY_NAME} @ {getCurrentYear} - All rights reserved.</div>
                        <div className={styles['policies']}>
                            <a href='#' className={styles['link']}>Privacy Policy</a>
                            <a href='#' className={styles['link']}>Terms of Use</a>
                            <a href='#' className={styles['link']}>Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer