import useAxiosPrivate from '../../api/useAxiosPrivate'

const usePutAccountById = () => {
    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController()

    const updateAccountDetails = async (data) => {
        try {
            const response = await axiosPrivate.put(`/api/v1/accounts/${data.id}`, data, { signal: controller.signal })
            return response
        } catch (error) {
            console.log(error)
        } finally {
            controller.abort()
        }
    }

    return { updateAccountDetails }
}

export default usePutAccountById