import React from 'react'
import { Link } from 'react-router-dom'

import styles from './TopBar.module.sass'

function TopBar() {
    return (
        <>
            <div className={styles['top-bar']}>
                <div className={styles['top-bar--links']}>
                    {/* <Link to='#' className={styles['top-bar--link']}>
                        <i className={`fa-solid fa-comment ${styles['icon']}`}></i>
                        <div className={styles['notification-number']}>8</div>
                    </Link>
                    <Link to='#' className={styles['top-bar--link']}>
                        <i className={`fa-solid fa-bell ${styles['icon']}`}></i>
                        <div className={styles['notification-number']}>99</div>
                    </Link> */}
                    <Link to='/' className={styles['top-bar--link']}>
                        <i className={`fa-solid fa-globe ${styles['icon']}`}></i>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default TopBar