import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useGetAllDestinations = () => {
    const axiosPrivate = useAxiosPrivate()
    const [destinations, setDestinations] = useState({})
    
    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getAllDestinations = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/tours/destinations/`, { signal: controller.signal })
                isMounted && setDestinations(response.data)
            } catch (error) {
                return
            }
        }

        getAllDestinations()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [destinations])

    return destinations
}

export default useGetAllDestinations