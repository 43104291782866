import { forwardRef } from 'react'
import { useFormattedDate } from '../functions/useDateTimeFormat'
import useGetCouponById from '../hooks/useGetCouponById'

import styles from './Documents.module.sass'

const GenerateInvoice = forwardRef(({ data, travellers, invoicing }, ref) => {
    const logo = '/assets/logos/logo_black.svg'
    const now = useFormattedDate(new Date())
    const orderDate = data?.order_date ? useFormattedDate(data.order_date) : now
    const coupon = useGetCouponById(data.coupon_id)

    return (
        <div style={{ position: 'fixed', top: '-9999999px', left: '-9999999px' }}>
            <div ref={ref}>
                <div className={styles['document--header']}>
                    <div className={styles['company-logo']}>
                        <img src={logo} alt='Destiny Tour Logo' width={250} height={140.6} />

                        <div className={styles['client']}>
                            <h3 className={styles['title']}>Buyer</h3>
                            <p className={styles['text']}>{invoicing?.physical_name ? invoicing?.physical_name : invoicing?.legal_name}</p>
                            {invoicing?.physical_identification_number
                                ? (
                                    <p className={styles['text']}><b>CNP:</b> {invoicing?.physical_identification_number}</p>
                                ) : (
                                    <p className={styles['text']}><b>CNP:</b> {invoicing?.legal_identification_number}</p>
                                )
                            }
                            {invoicing?.legal_register && (
                                <p className={styles['text']}><b>Trade Register:</b> {invoicing?.legal_register}</p>
                            )}
                            <p className={styles['text']}><b>Phone:</b> {invoicing?.physical_phone ? invoicing?.physical_phone : invoicing?.legal_phone}</p>
                            <p className={styles['text']}><b>Address:</b> {invoicing?.physical_billing_address ? invoicing?.physical_billing_address : invoicing?.legal_billing_address}</p>
                        </div>
                    </div>
                    <div className={styles['company-details']} width={250}>
                        <h3 className={styles['title']}>Destiny Tour Agency</h3>
                        <p className={styles['text']}>Tourism License 2222 / 4444</p>
                        <p className={styles['text']}>J20/1234/4567, RO12345678</p>
                        <p className={styles['text']}>Social Capital: 25.200 RON</p>
                        <p className={styles['text']}>Transilvanian Bank</p>
                        <p className={styles['text']} style={{ fontSize: '1.2rem' }}>IBAN (RON): RO35 BTRL RONC RT55 1234 1234</p>
                        <p className={styles['text']} style={{ fontSize: '1.2rem' }}>IBAN (EUR): RO82 BTRL EURC RT06 1234 1234</p>
                        <p className={styles['text']}>Swift: BTRLRO99</p>
                        <p className={styles['text']}>Bucharest, Romania</p>
                        <p className={styles['text']}>Phone: +40712345678</p>
                        <p className={styles['text']}>Email: office@destinytour.ro</p>
                        <p className={styles['text']}>www.destinytour.ro</p>
                    </div>
                </div>

                <div className={styles['document--content']}>
                    <p className={styles['invoice-from']}>Invoice {data?.order_date ? `#${data?.id}` : null} from {orderDate}</p>
                    <div className={styles['invoice--heading']}>
                        <span className={styles['label']}>Product or services name</span>
                        <span className={styles['label']}>Total Price</span>
                    </div>
                    <div className={styles['invoice--body']}>
                        <div className={styles['section']}>
                            <p className={`${styles['paragraph']} ${styles['tour-title']}`}>{data?.tour_title}</p>
                            <p className={`${styles['paragraph']} ${styles['trip-date']}`}><b>Departure Date:</b> {useFormattedDate(data?.departure_date)}</p>
                            <p className={`${styles['paragraph']} ${styles['adults']}`}>
                                {travellers?.adult && Object.values(travellers.adult).map((adult, i) => (
                                    <div key={i} className={styles['body-price']}>
                                        <p className={styles['paragraph']}><b>Adult ({i + 1}):</b> {adult.name} - <b>CNP:</b> {adult.identification_number}</p>
                                        <p className={styles['paragraph']}>{data?.adult_price ?? 0}€</p>
                                    </div>
                                ))}
                            </p>
                            {travellers?.child && (
                                <p className={`${styles['paragraph']} ${styles['childs']}`}>
                                    {Object.values(travellers?.child).map((child, i) => (
                                        <div key={i} className={styles['body-price']}>
                                            <p className={styles['paragraph']}><b>Child ({i + 1}):</b> {child.name} - <b>CNP:</b> {child.identification_number} - <b>Age:</b> {child.age}</p>
                                            <p className={styles['paragraph']}>{data?.child_price}€</p>
                                        </div>
                                    ))}
                                </p>
                            )
                            }
                            {(data?.coupon_id || data?.coupon) && (
                                <>
                                    <div className={styles['divider']} />
                                    <div className={styles['body-price']}>
                                        <p className={styles['paragraph']}><b>Coupon:</b> {coupon.name ? coupon.name : data?.coupon?.name}</p>
                                        {coupon.price_type ? (
                                            <p className={styles['paragraph']}><b>- {coupon.price_type === 'fixed' ? `${coupon.price_value}€` : `${coupon.price_value}%`}</b></p>
                                        ) : (
                                            <p className={styles['paragraph']}><b>- {data?.coupon?.price_type === 'fixed' ? `${data?.coupon?.price_value}€` : `${data?.coupon?.price_value}%`}</b></p>
                                        )}
                                    </div>
                                </>
                            )}
                            {data?.additional_services?.length ? (
                                <>
                                    <div className={styles['divider']} />
                                    {data.additional_services.map((service, i) => (
                                        <div key={i} className={styles['body-price']}>
                                            <div className={styles['label']}><b>Additional Service ({i + 1}):</b> {service.name}</div>
                                            <div className={styles['value']}>+ {(data?.num_of_adults + data?.num_of_childs) * service.price_value}€ ({data?.num_of_adults + data?.num_of_childs} x {service.price_value}€)</div>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                            <div className={styles['divider']} />
                            <div className={styles['body-price']}>
                                <p className={styles['paragraph']}>Payment Method</p>
                                <p className={styles['paragraph']}>{data?.payment_method === 1 ? 'At the Agency' : 'Banking Transfer'}</p>
                            </div>
                            <div className={styles['divider']} />
                            <div className={`${styles['body-price']} ${styles['total-price']}`}>
                                <p className={styles['paragraph']}>Total</p>
                                <p className={styles['paragraph']}>{data?.total_price}€</p>
                            </div>
                            <div className={styles['divider']} />
                            <div className={styles['invoice--footer']}>
                                <p className={`${styles['paragraph']} ${styles['law']}`}>This invoice is valid without signature and stamp according to article V, paragraph (2) of Ordinance No. 17/2015 and article 319 paragraph (29) of Law No. 227/2015 regarding the Fiscal Code.</p>
                                <p className={styles['paragraph']}>Thank you!<br />Destiny Tour Team<br />---------------------------</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default GenerateInvoice