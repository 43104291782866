import { useState, useEffect } from 'react'
import { FormModal, FormModalField } from '../FormModal/FormModal'
import useAxiosPrivate from '../../../api/useAxiosPrivate'

import styles from './OrderData.module.sass'
import adminStyles from '../../Admin.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'

function OrderInvoicing({ data }) {
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [invoicingDetails, setInvoicingDetails] = useState({})

    const axiosPrivate = useAxiosPrivate()

    const openInvoicingModal = () => {
        setOpenModal(!openModal)
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setInvoicingDetails({ ...invoicingDetails, [name]: value })
    }

    const updateInvoicing = async (e) => {
        e.preventDefault()

        await axiosPrivate.put(`/api/v1/orders/update/invoicing/${data?.id}`, invoicingDetails)
            .then((response) => {
                setValidation({ message: response.data.message, isValid: true })

                setTimeout(() => {
                    setValidation({ message: '', isValid: false })
                    window.location.reload()
                }, 3000)
            })
            .catch((error) => {
                setValidation({ message: error.response.data.message, isValid: false })
            })
    }

    useEffect(() => {
        setInvoicingDetails(data?.invoicing)
    }, [data])

    return (
        <>
            <div className={styles['order--details-box']}>
                <h2 className={adminStyles['box-title']}>
                    Invoicing Details

                    <button className={styles['order--edit-btn']} onClick={() => openInvoicingModal()}>
                        Edit
                        <i className='fa-solid fa-pen-to-square'></i>
                    </button>
                </h2>
                {data?.invoicing ? (
                <div className={styles['box--content']}>
                    {(data?.invoicing?.physical_name || data?.invoicing?.legal_name) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>{data?.invoicing.physical_name ? 'First & Last Name' : 'Company Name'}:</div>
                            <div className={styles['value']}>{data?.invoicing.physical_name ? data?.invoicing.physical_name : data?.invoicing.legal_name}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_identification_number || data?.invoicing?.legal_identification_number) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Identification Number:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_identification_number ? data?.invoicing?.physical_identification_number : data?.invoicing?.legal_identification_number}</div>
                        </div>
                    )}
                    {data?.invoicing?.legal_register && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Legal Register:</div>
                            <div className={styles['value']}>{data?.invoicing?.legal_register}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_email || data?.invoicing?.legal_email) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Email Address:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_email ? data?.invoicing?.physical_email : data?.invoicing?.legal_email}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_phone || data?.invoicing?.legal_phone) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Phone:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_phone ? data?.invoicing?.physical_phone : data?.invoicing?.legal_phone}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_bank_name || data?.invoicing?.legal_bank_name) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Bank Name:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_bank_name ? data?.invoicing?.physical_bank_name : data?.invoicing?.legal_bank_name}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_bank_iban || data?.invoicing?.legal_bank_iban) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Bank IBAN:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_bank_iban ? data?.invoicing?.physical_bank_iban : data?.invoicing?.legal_bank_iban}</div>
                        </div>
                    )}
                    {(data?.invoicing?.physical_billing_address || data?.invoicing?.legal_billing_address) && (
                        <div className={styles['row']}>
                            <div className={styles['label']}>Billing Address:</div>
                            <div className={styles['value']}>{data?.invoicing?.physical_billing_address ? data?.invoicing?.physical_billing_address : data?.invoicing?.legal_billing_address}</div>
                        </div>
                    )}
                </div>
                ) : (
                    <p className={styles['no-data']}>There are no invoicing details yet.</p>
                )}
            </div>

            {openModal && (
                <FormModal title={'Edit Invoicing Details'} closeFunction={() => openInvoicingModal()}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateInvoicing(e)}>
                        {invoicingDetails?.physical_name ? (
                            <>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='First & Last Name' inputName='physical_name' inputValue={invoicingDetails?.physical_name} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Identification Number' inputName='physical_identification_number' inputValue={invoicingDetails?.physical_identification_number} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Email Address' inputName='physical_email' inputValue={invoicingDetails?.physical_email} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Phone' inputName='physical_phone' inputValue={invoicingDetails?.physical_phone} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank Name' inputName='physical_bank_name' inputValue={invoicingDetails?.physical_bank_name} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank IBAN' inputName='physical_bank_iban' inputValue={invoicingDetails?.physical_bank_iban} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Billing Address' inputName='physical_billing_address' inputValue={invoicingDetails?.physical_billing_address} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Company Name' inputName='legal_name' inputValue={invoicingDetails?.legal_name} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Identification Number' inputName='legal_identification_number' inputValue={invoicingDetails?.legal_identification_number} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Legal Register' inputName='legal_register' inputValue={invoicingDetails?.legal_register} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Email Address' inputName='legal_email' inputValue={invoicingDetails?.legal_email} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Phone' inputName='legal_phone' inputValue={invoicingDetails?.legal_phone} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank Name' inputName='legal_bank_name' inputValue={invoicingDetails?.legal_bank_name} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Bank IBAN' inputName='legal_bank_iban' inputValue={invoicingDetails?.legal_bank_iban} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                                <div className={modalStyles['column']}>
                                    <FormModalField inputLabel='Billing Address' inputName='legal_billing_address' inputValue={invoicingDetails?.legal_billing_address} inputUpdate={(e) => updateFormInput(e)} />
                                </div>
                            </>
                        )}

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            Update
                        </button>
                    </form>
                </FormModal>
            )}

        </>
    )
}

export default OrderInvoicing