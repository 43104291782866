import { Link } from 'react-router-dom'
import useGetAllDestinations from '../../../../hooks/useGetAllDestinations'

import styles from './NavMenu.module.sass'
import clientStyles from '../../../Client.module.sass'

function NavMenu() {
    const destinations = useGetAllDestinations()

    return (
        <div className={styles['nav--menu']}>
            <div className={clientStyles['page--size']}>
                <div className={styles['nav--items']}>
                    {Object.values(destinations).map((destination) => (
                        <Link key={destination.id} to={`/destinations/${destination.destination_name.toLowerCase()}`} className={styles['item']}>{destination.destination_name}</Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NavMenu