import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../api/useAxiosPrivate'

const useAccountById = (id) => {
    const axiosPrivate = useAxiosPrivate()
    const [account, setAccount] = useState({})

    useEffect(() => {
        if (!id) return
        
        let isMounted = true
        const controller = new AbortController()

        const getDataForAccount = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/accounts/${id}`, { signal: controller.signal })
                isMounted && setAccount(response.data)
            } catch (error) {
                console.error("Error fetching account data: ", error)
            }
        }

        getDataForAccount()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [id])

    return account
}

export default useAccountById