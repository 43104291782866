// import useGetAllCoupons from '../../hooks/useGetAllCoupons'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'
// import CouponsTable from '../Components/Tables/CouponsTable'

import styles from '../Admin.module.sass'

function Invoicing() {
    // const coupons = useGetAllCoupons()

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Invoicing Data'} />
                    <div className={styles['container']}>
                        {/* <CouponsTable coupons={coupons} itemsPerPage={10} hasAddButton={true} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoicing