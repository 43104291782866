import styles from './Banner.module.sass'
import clientStyles from '../../../Client.module.sass'

function Banner({ size = 'L', ...props }) {
    const { img, title, subtitle } = props

    const landingBanner = '/assets/banners/landing_banner.jpg'

    const bannerStyle = {
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center 10%'
    }

    return (
        <div className={`${styles['banner--container']} ${styles[`banner--${size}`]}`} style={bannerStyle}>
            <div className={clientStyles['page--size']}>
                <div className={styles['banner--title']}>
                    <div className={styles['banner--title__inner']}>
                        <h1 className={styles['title']}>{title}</h1>
                        {subtitle && <h2 className={styles['subtitle']}>{subtitle}</h2>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner