import { forwardRef } from 'react'
import { useFormattedDate } from '../functions/useDateTimeFormat'

import styles from './Documents.module.sass'

const GenerateContract = forwardRef(({ data, contact, invoicing }, ref) => {
    const logo = '/assets/logos/logo_black.svg'
    const now = useFormattedDate(new Date())
    const orderDate = data?.order_date ? useFormattedDate(data.order_date) : now

    return (
        <div style={{ position: 'fixed', top: '-9999999px', left: '-9999999px' }}>
            <div ref={ref}>
                <div className={styles['document--header']}>
                    <div className={styles['company-logo']}>
                        <img src={logo} alt='Destiny Tour Logo' width={250} height={140.6} />
                    </div>
                    <div className={styles['company-details']}>
                        <p className={styles['text']}><b>Destiny Tour</b></p>
                        <p className={styles['text']}>Tourism License 2222 / 4444</p>
                        <p className={styles['text']}>J20/1234/4567, RO12345678</p>
                        <p className={styles['text']}>Bucharest, Romania</p>
                        <p className={styles['text']}>Phone: +40712345678</p>
                        <p className={styles['text']}>Email: office@destinytour.ro</p>
                        <p className={styles['text']}>www.destinytour.ro</p>
                    </div>
                </div>

                <h1 className={styles['document--title']}>Contract with the traveller</h1>
                <h2 className={styles['document--subtitle']}>for the sale of travel service packages</h2>
                <p className={styles['document--date']}>from {orderDate}</p>

                <div className={styles['document--content']}>
                    <div className={styles['section']}>
                        <h3 className={styles['title']}>1. The contracted parties:</h3>
                        <p className={styles['paragraph']}><b>Destiny Tour</b>, based in Bucharest, Str. Name of the Street, No. 99, Bl. 1, Sc. A, Et. 1, Ap. 3; Phone: +40712345678
                            Email: office@destinytour.ro; LEI Account: RO35 BTRL RONC RT55 1234 1234 and EURO Account: RO82 BTRL EURC
                            RT06 1234 1234, opened at Transilvanian Bank, registered at the Trade Registry under no. J20/1234/4567; CUI: RO12345678,
                            holding the Insurance Policy issued by Company Name series I no. 123456 valid from 15.05.2022 until 15.05.2026 regarding the
                            reimbursement of repatriation expenses and/or the amounts paid by the traveler in the event of <b>Destiny Tour</b>'s insolvency, displayed
                            on the website www.destinytour.ro, represented by CEO Name as administrator.</p>
                        <p className={styles['paragraph']}>and</p>
                        {invoicing?.physical_name ? (
                            <p className={styles['paragraph']}>The traveler/traveller's representative <b>{invoicing?.physical_name}</b>, domiciled <b>{invoicing?.physical_billing_address}</b>, 
                                phone <b>{invoicing?.physical_phone}</b>, holder of identity card CNP <b>{invoicing?.physical_identification_number}</b>, agreed to conclude this contract.</p>
                        ) : (
                            <p className={styles['paragraph']}><b>{invoicing?.legal_name}</b> commercial company based in <b>{invoicing?.legal_billing_address}</b>, phone <b>{invoicing?.legal_phone}</b>, registered at 
                                the Trade Register under no. <b>{invoicing?.legal_register}</b>, CUI: <b>{invoicing?.legal_identification_number}</b> represented by <b>{contact?.first_name} {contact?.last_name}</b>, agreed to conclude this contract.</p>
                        )}
                    </div>

                    <div className={styles['section']}>
                        <h3 className={styles['title']}>2. Subject of the contract:</h3>
                        <p className={styles['paragraph']}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className={styles['paragraph']}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                        <p className={styles['paragraph']}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className={styles['paragraph']}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <p className={styles['paragraph']}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                        <p className={styles['paragraph']}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                        <p className={styles['paragraph']}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                    </div>

                    <div className={`${styles['section']} ${styles['signature--footer']}`}>
                        <div className={styles['agency-signature']}>
                            <p className={styles['text']}><b>Destiny Tour Agency</b></p>
                            <p className={styles['text']}>Legal representative <b>First & Last Name</b></p>
                            <p className={styles['text']}><b>First & Last Name</b> - General Manager</p>
                            <p className={styles['text']}>By proxy <b>First & Last Name</b></p>
                        </div>
                        <div className={styles['traveller-signature']}>
                            <p className={styles['text']}><b>Traveller (First & Last Name)</b></p>
                            <p className={styles['text']}>{invoicing?.physical_name ? invoicing?.physical_name : invoicing?.legal_name}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default GenerateContract