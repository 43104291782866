import styles from './NotFoundError.module.sass'

function NotFoundError({ description }) {
    return (
        <div className={styles['error-not-found']}>
            <h1 className={styles['title']}>404 Not Found</h1>
            <p className={styles['description']}>{description}</p>
        </div>
    )
}

export default NotFoundError