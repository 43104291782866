import { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'

import { BASE_URL } from '../../api/axios'
import { useFormattedDate } from '../../functions/useDateTimeFormat'

import useAxiosPrivate from '../../api/useAxiosPrivate'
import useAuth from '../../authManager/useAuth'
import useGetAllDestinations from '../../hooks/useGetAllDestinations'
import useGetAllAdditionalServicesByTourId from '../../hooks/useGetAllAdditionalServicesByTourId'

import usePersonInvoicingById from '../../hooks/order_methods/usePersonInvoicingById'
import useBusinessInvoicingById from '../../hooks/order_methods/useBusinessInvoicingById'

import GenerateContract from '../../Documents/GenerateContract'
import GenerateInvoice from '../../Documents/GenerateInvoice'

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

import clientStyles from '../Client.module.sass'

function Checkout() {
    const contractRef = useRef()
    const invoiceRef = useRef()

    const { state } = useLocation()
    const navigate = useNavigate()
    const [checkout, setCheckout] = useState({ payment_method: 1 })
    const [selectedServices, setSelectedServices] = useState([])
    const [coupon, setCoupon] = useState({ code: '', message: '', isValid: false })
    const [payment, setPayment] = useState(1)
    const [isBooked, setIsBooked] = useState(false)

    const [validationMessage, setValidationMessage] = useState({ message: '', isValid: false })

    const { auth } = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const servicesByTourId = useGetAllAdditionalServicesByTourId(checkout.id)

    const person_details = usePersonInvoicingById(auth.id)
    const business_details = useBusinessInvoicingById(auth.id)

    const [isLegalPerson, setIsLegalPerson] = useState(false)
    const destinations = useGetAllDestinations()

    // handle the contact inputs
    const handleContactInputs = (e) => {
        const { name, value } = e.target

        setCheckout({
            ...checkout,
            contact_details: {
                ...checkout.contact_details,
                [name]: value
            }
        })
    }

    // handle the invoicing inputs
    const handleInvoicingInputs = (e) => {
        const { name, value } = e.target

        setCheckout({
            ...checkout,
            invoicing_details: {
                ...checkout.invoicing_details,
                [name]: value
            }
        })
    }

    // handle the traveller inputs
    const handleTravellerInputs = (e, i, type) => {
        const { name, value } = e.target

        setCheckout({
            ...checkout,
            traveller_details: {
                ...checkout.traveller_details,
                [type]: {
                    ...(checkout.traveller_details?.[type] || {}),
                    [i]: {
                        ...(checkout.traveller_details?.[type]?.[i] || {}),
                        [name]: value
                    }
                }
            }
        })
    }

    // toggle for invoicing method
    const toggleInvoicingForm = (e) => {
        const { checked } = e.target

        if (checked) {
            setIsLegalPerson(!isLegalPerson)

            if (isLegalPerson) {
                setCheckout({
                    ...checkout,
                    invoicing_details: {
                        physical_name: person_details?.name,
                        physical_identification_number: person_details?.identification_number,
                        physical_email: person_details?.email,
                        physical_phone: person_details?.phone,
                        physical_bank_name: person_details?.bank_name,
                        physical_bank_iban: person_details?.bank_iban,
                        physical_billing_address: person_details?.address
                    }
                })
            } else {
                setCheckout({
                    ...checkout,
                    invoicing_details: {
                        legal_name: business_details?.name,
                        legal_identification_number: business_details?.identification_number,
                        legal_register: business_details?.business_register,
                        legal_email: business_details?.email,
                        legal_phone: business_details?.phone,
                        legal_bank_name: business_details?.bank_name,
                        legal_bank_iban: business_details?.bank_iban,
                        legal_billing_address: business_details?.address
                    }
                })
            }
        }
    }

    const handleCouponInput = (e) => {
        const { value } = e.target
        setCoupon({ ...coupon, code: value })
    }

    // handle the coupon code
    const checkCouponCode = async (e) => {
        e.preventDefault()

        // this is to backup the original value parsed from offer
        const totalPriceBackup = checkout.total_price_backup !== undefined ? checkout.total_price_backup : checkout.total_price

        if (coupon.code === '') {
            return setCoupon({ ...coupon, message: 'You should fill the coupon input before applying it.', isValid: false })
        }

        if (checkout?.coupon) {
            let priceWithoutDiscount
            if (checkout.coupon.price_type === 'fixed') {
                priceWithoutDiscount = checkout.total_price + checkout.coupon.price_value
            } else {
                const discountAmount = (totalPriceBackup * checkout.coupon.price_value) / 100
                priceWithoutDiscount = checkout.total_price + discountAmount
            }

            setCoupon({ code: '', message: '', isValid: false })
            setCheckout({ ...checkout, total_price: priceWithoutDiscount, coupon: '' })

            return
        }

        await axiosPrivate.get(`/api/v1/tours/coupons/validate/${coupon.code}`)
            .then((response) => {
                let priceWithDiscount
                const coupon_data = response.data
                if (coupon_data === '') {
                    return setCoupon({ ...coupon, message: 'This coupon does not exist or is not valid.', isValid: false })
                }

                if (coupon_data.price_type === 'fixed') {
                    priceWithDiscount = checkout.total_price - coupon_data.price_value
                } else {
                    const discountAmount = (totalPriceBackup * coupon_data.price_value) / 100
                    priceWithDiscount = checkout.total_price - discountAmount
                }

                setCheckout({
                    ...checkout,
                    coupon: coupon_data,
                    total_price: priceWithDiscount,
                    total_price_backup: totalPriceBackup
                })

                setCoupon({ ...coupon, message: 'Your coupon was successfully applied.', isValid: true })
                setTimeout(() => {
                    setCoupon({ ...coupon, message: '', isValid: false })
                }, 3000)
            })
            .catch((error) => {
                setCoupon({ ...coupon, message: 'Something went wrong with this coupon. Please try again later...', isValid: false })
            })
    }

    // additional services calculation to final price
    const handleToggleService = (service) => {
        let updatedServices
        const serviceIndex = selectedServices.findIndex((selected) => selected.id === service.id)

        if (serviceIndex !== -1) {
            updatedServices = [...selectedServices]
            updatedServices.splice(serviceIndex, 1)
            setSelectedServices(updatedServices)

            const totalService = checkout.total_price - ((checkout.num_of_adults + checkout.num_of_childs) * service.price_value)
            setCheckout({
                ...checkout,
                total_price: totalService,
                additional_services: updatedServices
            })
        } else {
            updatedServices = [...selectedServices, service]
            setSelectedServices(updatedServices)

            const totalService = checkout.total_price + ((checkout.num_of_adults + checkout.num_of_childs) * service.price_value)
            const totalPriceBackup = checkout.total_price_backup !== undefined ? checkout.total_price_backup : checkout.total_price
            setCheckout({
                ...checkout,
                total_price: totalService,
                total_price_backup: totalPriceBackup,
                additional_services: updatedServices
            })
        }
    }

    // handle payment method
    const handleMethodClick = (option) => {
        setPayment(option)
    }

    // handle reservation
    const handleReservation = async () => {
        if (!checkout?.contact_details?.first_name || !checkout?.contact_details?.last_name
        || !checkout?.contact_details?.email_address || !checkout?.contact_details?.phone) {
            return setValidationMessage({ message: 'All contact inputs should be filled.', isValid: false })
        }

        if (!checkout?.contact_details?.first_name || !checkout?.contact_details?.last_name
        || !checkout?.contact_details?.email_address || !checkout?.contact_details?.phone) {
            return setValidationMessage({ message: 'All contact inputs should be filled.', isValid: false })
        }

        if (!isLegalPerson) {
            if (!checkout?.invoicing_details?.physical_name || !checkout?.invoicing_details?.physical_identification_number
            || !checkout?.invoicing_details?.physical_email || !checkout?.invoicing_details?.physical_phone
            || !checkout?.invoicing_details?.physical_billing_address) {
                return setValidationMessage({ message: 'All physical invoicing inputs should be filled.', isValid: false })
            }
        } else {
            if (!checkout?.invoicing_details?.legal_name || !checkout?.invoicing_details?.legal_identification_number
            || !checkout?.invoicing_details?.legal_register || !checkout?.invoicing_details?.legal_email
            || !checkout?.invoicing_details?.legal_phone || !checkout?.invoicing_details?.legal_billing_address) {
                    return setValidationMessage({ message: 'All legal person invoicing inputs should be filled.', isValid: false })
                }
        }

        // targeting the document components
        const element1 = contractRef.current
        const element2 = invoiceRef.current

        // making output options for pdfs
        const opt = {
            margin: 10,
            image: {
                type: 'jpeg',
                quality: 0.98
            },
            html2canvas: {
                dpi: 192,
                scale: 4,
                scrollX: 0,
                scrollY: 0,
                letterRendering: true,
                useCORS: true
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                hotfixes: ['px_scaling']
            },
            pagebreak: {
                mode: ['css', 'legacy']
            }
        }

        // getting the documents ready
        const pdfBlob1 = await html2pdf().from(element1).set(opt).outputPdf('blob')
        const pdfBlob2 = await html2pdf().from(element2).set(opt).outputPdf('blob')

        // building the form data
        const formData = new FormData()
        Object.keys(checkout).forEach((key) => {
            const value = checkout[key]
            const serializedValue = typeof value === 'object' ? JSON.stringify(value) : value
            formData.append(key, serializedValue)
        })

        formData.append('contract', pdfBlob1, 'contract.pdf')
        formData.append('invoice', pdfBlob2, 'invoice.pdf')

        formData.append('payment_method', payment)
        auth.id && formData.append('account_id', auth.id)

        try {
            const response = await axiosPrivate.post('/api/v1/orders/checkout/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (response.data.status === 'OK') {
                setIsBooked(!isBooked)
                window.scrollTo(0, 0)
            }
        } catch (error) {
            console.error('Error uploading files:', error)
        }
    }

    // we handle the page state, if we access this page without to book, then we are restricted to use
    useEffect(() => {
        if (state === null) {
            navigate('/')
        } else {
            setCheckout(state)
        }
    }, [])

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // useEffect(() => {
    //     if (person_details && checkout?.invoicing_details === undefined) {
    //         setCheckout((prevCheckout) => ({
    //             ...prevCheckout,
    //             invoicing_details: {
    //                 physical_name: person_details?.name
    //             }
    //         }))
    //     }
    // }, [person_details])

    return (
        <>
            <Helmet>
                <title>{`Destiny Tour - Checkout - ${checkout.tour_title}`}</title>
            </Helmet>

            <Header />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['page--gap__XL']}>
                    <div className={clientStyles['page--inner__no-banner']}>
                        {checkout && (
                            <>
                                {isBooked === false ? (
                                    <>
                                        <div className={clientStyles['checkout--layout']}>
                                            <div className={clientStyles['checkout--details']}>
                                                <div className={clientStyles['checkout--section']}>
                                                    <div className={clientStyles['section--title']}>Contact Details</div>

                                                    <div className={clientStyles['section--row']}>
                                                        <div className={clientStyles['details--row']}>
                                                            <div className={clientStyles['details']}>
                                                                <div className={clientStyles['input--field']}>
                                                                    <input type='text' className={clientStyles['input']} id='contact_first-name' name='first_name' onChange={(e) => handleContactInputs(e)} />
                                                                    <label htmlFor='contact_first-name' className={`${clientStyles['label']} ${checkout?.contact_details && checkout?.contact_details?.first_name ? clientStyles['filled'] : ''}`}>* First Name</label>
                                                                </div>
                                                                <div className={clientStyles['input--field']}>
                                                                    <input type='text' className={clientStyles['input']} id='contact_last-name' name='last_name' onChange={(e) => handleContactInputs(e)} />
                                                                    <label htmlFor='contact_last-name' className={`${clientStyles['label']} ${checkout?.contact_details && checkout?.contact_details?.last_name ? clientStyles['filled'] : ''}`}>* Last Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={clientStyles['details--row']}>
                                                            <div className={clientStyles['details']}>
                                                                <div className={clientStyles['input--field']}>
                                                                    <input type='text' className={clientStyles['input']} id='contact_email' name='email_address' onChange={(e) => handleContactInputs(e)} />
                                                                    <label htmlFor='contact_email' className={`${clientStyles['label']} ${checkout?.contact_details && checkout?.contact_details?.email_address ? clientStyles['filled'] : ''}`}>* Email Address</label>
                                                                </div>
                                                                <div className={clientStyles['input--field']}>
                                                                    <input type='text' className={clientStyles['input']} id='contact_phone' name='phone' onChange={(e) => handleContactInputs(e)} />
                                                                    <label htmlFor='contact_phone' className={`${clientStyles['label']} ${checkout?.contact_details && checkout?.contact_details?.phone ? clientStyles['filled'] : ''}`}>* Phone</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={clientStyles['checkout--section']}>
                                                    <div className={clientStyles['section--title']}>Traveller Details</div>

                                                    <div className={clientStyles['section--row']}>
                                                        <div className={clientStyles['subtitle']}>Adult Details</div>
                                                        {Array.from({ length: checkout.num_of_adults }, (_, i) => (
                                                            <div key={i} className={clientStyles['details--row']}>
                                                                <div className={clientStyles['row--label']}>Adult {i + 1}:</div>
                                                                <div className={clientStyles['details']}>
                                                                    <div className={clientStyles['input--field']}>
                                                                        <input type='text' className={clientStyles['input']} id={`adult_name--${i}`} name='name' onChange={(e) => handleTravellerInputs(e, i, 'adult')} />
                                                                        <label htmlFor={`adult_name--${i}`} className={`${clientStyles['label']} ${checkout?.traveller_details?.adult && checkout?.traveller_details?.adult[i]?.name ? clientStyles['filled'] : ''}`}>* First and Last Name</label>
                                                                    </div>
                                                                    <div className={clientStyles['input--field']}>
                                                                        <input type='text' className={clientStyles['input']} id={`adult_identification_number--${i}`} name='identification_number' onChange={(e) => handleTravellerInputs(e, i, 'adult')} />
                                                                        <label htmlFor={`adult_identification_number--${i}`} className={`${clientStyles['label']} ${checkout?.traveller_details?.adult && checkout?.traveller_details?.adult[i]?.identification_number ? clientStyles['filled'] : ''}`}>* Identification Number</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    {checkout.num_of_childs ? (
                                                        <div className={clientStyles['section--row']}>
                                                            <div className={clientStyles['subtitle']}>Children Details</div>
                                                            {Array.from({ length: checkout.num_of_childs }, (_, i) => (
                                                                <div key={i} className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['row--label']}>Child {i + 1}:</div>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input type='text' className={clientStyles['input']} id={`child_name--${i}`} name='name' onChange={(e) => handleTravellerInputs(e, i, 'child')} />
                                                                            <label htmlFor={`child_name--${i}`} className={`${clientStyles['label']} ${checkout?.traveller_details?.child && checkout?.traveller_details?.child[i]?.name ? clientStyles['filled'] : ''}`}>* First and Last Name</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input type='text' className={clientStyles['input']} id={`child_identification_number--${i}`} name='identification_number' onChange={(e) => handleTravellerInputs(e, i, 'child')} />
                                                                            <label htmlFor={`child_identification_number--${i}`} className={`${clientStyles['label']} ${checkout?.traveller_details?.child && checkout?.traveller_details?.child[i]?.identification_number ? clientStyles['filled'] : ''}`}>* Identification Number</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <select className={clientStyles['input']} name='age' onChange={(e) => handleTravellerInputs(e, i, 'child')}>
                                                                                <option value='0'>Age</option>
                                                                                {[...Array.from({ length: 11 }, (_, i) => (
                                                                                    <option key={i} value={i + 1}>{i + 1 === 1 ? `${i + 1} year` : `${i + 1} years`}</option>
                                                                                ))]}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : ''}
                                                </div>

                                                <div className={clientStyles['checkout--section']}>
                                                    <div className={clientStyles['section--title']}>Invoice Details</div>

                                                    <div className={clientStyles['section--row']}>
                                                        <div className={clientStyles['invoicing--type']}>
                                                            <div className={clientStyles['form-field']}>
                                                                <input id='physical_person-checkbox' type='checkbox' name='checkbox' onChange={(e) => toggleInvoicingForm(e)} checked={!isLegalPerson ? true : false} />
                                                                <label htmlFor='physical_person-checkbox'>Physical Person</label>
                                                            </div>
                                                            <div className={clientStyles['form-field']}>
                                                                <input id='legal_person-checkbox' type='checkbox' name='checkbox' onChange={(e) => toggleInvoicingForm(e)} checked={isLegalPerson ? true : false} />
                                                                <label htmlFor='legal_person-checkbox'>Legal Person</label>
                                                            </div>
                                                        </div>
                                                        {!isLegalPerson ? (
                                                            <div className={clientStyles['physical-person--form']}>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_name'
                                                                                name='physical_name'
                                                                                value={checkout.invoicing_details?.physical_name || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_name'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_name ? clientStyles['filled'] : ''}`}
                                                                            >* First and Last Name</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_identification_number'
                                                                                name='physical_identification_number'
                                                                                value={checkout.invoicing_details?.physical_identification_number || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_identification_number'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_identification_number ? clientStyles['filled'] : ''}`}
                                                                            >* Identification Number</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_email'
                                                                                name='physical_email'
                                                                                value={checkout.invoicing_details?.physical_email || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_email'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_email ? clientStyles['filled'] : ''}`}
                                                                            >* Email Address</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_phone'
                                                                                name='physical_phone'
                                                                                value={checkout.invoicing_details?.physical_phone || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_phone'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_phone ? clientStyles['filled'] : ''}`}
                                                                            >* Phone</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_bank_name'
                                                                                name='physical_bank_name'
                                                                                value={checkout.invoicing_details?.physical_bank_name || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_bank_name'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_bank_name ? clientStyles['filled'] : ''}`}
                                                                            >Bank Name</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_bank_iban'
                                                                                name='physical_bank_iban'
                                                                                value={checkout.invoicing_details?.physical_bank_iban || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_bank_iban'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_bank_iban ? clientStyles['filled'] : ''}`}
                                                                            >Bank IBAN</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='physical_billing_address'
                                                                                name='physical_billing_address'
                                                                                value={checkout.invoicing_details?.physical_billing_address || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='physical_billing_address'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.physical_billing_address ? clientStyles['filled'] : ''}`}
                                                                            >* Billing Address</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className={clientStyles['legal-person--form']}>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_name'
                                                                                name='legal_name'
                                                                                value={checkout.invoicing_details?.legal_name || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_name'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_name ? clientStyles['filled'] : ''}`}
                                                                            >* Company Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_identification_number'
                                                                                name='legal_identification_number'
                                                                                value={checkout.invoicing_details?.legal_identification_number || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_identification_number'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_identification_number ? clientStyles['filled'] : ''}`}
                                                                            >* Identification Number</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_register'
                                                                                name='legal_register'
                                                                                value={checkout.invoicing_details?.legal_register || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_register'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_register ? clientStyles['filled'] : ''}`}
                                                                            >* Business Register</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_email'
                                                                                name='legal_email'
                                                                                value={checkout.invoicing_details?.legal_email || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_email'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_email ? clientStyles['filled'] : ''}`}
                                                                            >* Email Address</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_phone'
                                                                                name='legal_phone'
                                                                                value={checkout.invoicing_details?.legal_phone || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_phone'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_phone ? clientStyles['filled'] : ''}`}
                                                                            >* Phone</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_bank_name'
                                                                                name='legal_bank_name'
                                                                                value={checkout.invoicing_details?.legal_bank_name || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_bank_name'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_bank_name ? clientStyles['filled'] : ''}`}
                                                                            >Bank Name</label>
                                                                        </div>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_bank_iban'
                                                                                name='legal_bank_iban'
                                                                                value={checkout.invoicing_details?.legal_bank_iban || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_bank_iban'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_bank_iban ? clientStyles['filled'] : ''}`}
                                                                            >Bank IBAN</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={clientStyles['details--row']}>
                                                                    <div className={clientStyles['details']}>
                                                                        <div className={clientStyles['input--field']}>
                                                                            <input
                                                                                type='text'
                                                                                className={clientStyles['input']}
                                                                                id='legal_billing_address'
                                                                                name='legal_billing_address'
                                                                                value={checkout.invoicing_details?.legal_billing_address || ''}
                                                                                onChange={(e) => handleInvoicingInputs(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor='legal_billing_address'
                                                                                className={`${clientStyles['label']} ${checkout.invoicing_details?.legal_billing_address ? clientStyles['filled'] : ''}`}
                                                                            >* Billing Address</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className={clientStyles['checkout--section']}>
                                                    <div className={clientStyles['section--title']}>Payment Methods</div>
                                                    <div className={clientStyles['payment--methods']}>
                                                        <div className={`${clientStyles['method']} ${payment === 1 ? clientStyles['active'] : ''}`} onClick={() => handleMethodClick(1)}>
                                                            <div className={clientStyles['title']}>At the Agency</div>
                                                            <div className={clientStyles['description']}>Apt. 689 776 Feeney Lane, Morganstad, NE 17863</div>
                                                        </div>
                                                        <div className={`${clientStyles['method']} ${payment === 2 ? clientStyles['active'] : ''}`} onClick={() => handleMethodClick(2)}>
                                                            <div className={clientStyles['title']}>Banking Transfer</div>
                                                            <div className={clientStyles['description']}>All banking data will be in the invoice sent to the email after the reservation is completed.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={clientStyles['checkout--summary']}>
                                                <div className={clientStyles['checkout--section']}>
                                                    <div className={clientStyles['summary--heading']}>
                                                        <div className={clientStyles['image']}>
                                                            <img src={BASE_URL + checkout.representative_image_url} alt={checkout.tour_title} />
                                                        </div>
                                                        <div className={clientStyles['details']}>
                                                            <h2 className={clientStyles['tour--title']}>{checkout.tour_title}</h2>
                                                            <div className={clientStyles['tour--destination']}>
                                                                <i className={`fa-solid fa-map-pin ${clientStyles['icon']}`}></i>
                                                                {destinations[checkout.destination_id - 1]?.destination_name}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={clientStyles['summary--tour']}>
                                                        <div className={clientStyles['price--row']}>
                                                            <div className={clientStyles['label']}>Departure Date</div>
                                                            <div className={clientStyles['price']}>{useFormattedDate(checkout.departure_date)}</div>
                                                        </div>
                                                        <div className={clientStyles['price--row']}>
                                                            <div className={clientStyles['label']}>Days</div>
                                                            <div className={clientStyles['price']}>{checkout.tour_days} {checkout.tour_days === 1 ? 'day' : `days / ${checkout.tour_days - 1} night${checkout.tour_days - 1 === 1 ? '' : 's'}`}</div>
                                                        </div>
                                                        <div className={clientStyles['price--row']}>
                                                            <div className={clientStyles['label']}>Num. of Adult(s)</div>
                                                            <div className={clientStyles['price']}>{checkout.num_of_adults}</div>
                                                        </div>
                                                        {checkout.num_of_childs ? (
                                                            <div className={clientStyles['price--row']}>
                                                                <div className={clientStyles['label']}>Num. of Children(s)</div>
                                                                <div className={clientStyles['price']}>{checkout.num_of_childs}</div>
                                                            </div>
                                                        ) : ''}
                                                    </div>

                                                    <div className={clientStyles['divider']}></div>

                                                    <div className={clientStyles['price--calculation']}>
                                                        <h3 className={clientStyles['title']}>Coupon Code</h3>
                                                        <form className={clientStyles['coupon--form']} onSubmit={(e) => checkCouponCode(e)}>
                                                            <input type='text' className={clientStyles['input']} value={coupon.code} name='coupon_code' onChange={(e) => handleCouponInput(e)} disabled={checkout?.coupon} />
                                                            <input type='submit' className={clientStyles['button']} value={checkout?.coupon ? 'Remove' : 'Apply'} />
                                                        </form>
                                                        {coupon && (
                                                            <p className={`${clientStyles['coupon--message']} ${coupon.isValid ? clientStyles['success'] : clientStyles['failure']}`}>{coupon.message}</p>
                                                        )}

                                                        <div className={clientStyles['divider']}></div>

                                                        <h3 className={clientStyles['title']}>Price Details</h3>
                                                        <div className={clientStyles['price--row']}>
                                                            <div className={clientStyles['label']}>Adult(s) Price</div>
                                                            <div className={clientStyles['price']}>
                                                                ({checkout.num_of_adults} x {checkout.adult_price}€)
                                                            </div>
                                                        </div>
                                                        {checkout.num_of_childs ? (
                                                            <div className={clientStyles['price--row']}>
                                                                <div className={clientStyles['label']}>Children(s) Price</div>
                                                                <div className={clientStyles['price']}>
                                                                    ({checkout.num_of_childs} x {checkout.child_price}€)
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        {selectedServices.length ? (
                                                            <>
                                                                <h3 className={clientStyles['title']}>Additional Services</h3>
                                                                {selectedServices.map((service, i) => (
                                                                    <div key={i} className={clientStyles['price--row']}>
                                                                        <div className={clientStyles['label']}>{service.name}</div>
                                                                        <div className={clientStyles['price']}>
                                                                            ({checkout.num_of_adults + checkout.num_of_childs} x {service.price_value + '€'})
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : null}

                                                        {checkout?.coupon ? (
                                                            <>
                                                                <h3 className={clientStyles['title']}>Coupon</h3>
                                                                <div className={clientStyles['price--row']}>
                                                                    <div className={clientStyles['label']}>{checkout.coupon.name}</div>
                                                                    <div className={clientStyles['price']}>
                                                                        {checkout.coupon.price_type === 'fixed' ? `-${checkout.coupon.price_value}€` : `-${checkout.coupon.price_value}%`} discount from main price
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        <div className={clientStyles['divider']}></div>

                                                        <div className={`${clientStyles['price--row']} ${clientStyles['total--price']}`}>
                                                            <div className={clientStyles['label']}>Total Price</div>
                                                            <div className={clientStyles['price']}>{checkout.total_price}€</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {servicesByTourId.length ? (
                                                    <div className={clientStyles['checkout--services']}>
                                                        <h3 className={clientStyles['title']}>Additional Services</h3>
                                                        {Array.isArray(servicesByTourId) && servicesByTourId.map((service, i) => (
                                                            <div key={i} className={clientStyles['service--box']}>
                                                                <input id={`service--${i}`} type='checkbox' name='checkbox' />
                                                                <label htmlFor={`service--${i}`} onClick={() => handleToggleService(service)}>
                                                                    <span className={clientStyles['name']}>{service.name}</span>
                                                                    <span className={clientStyles['price']}>{service.price_value + '€'}</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <GenerateContract
                                            data={checkout}
                                            contact={checkout?.contact_details}
                                            invoicing={checkout?.invoicing_details}
                                            ref={contractRef}
                                        />
                                        <GenerateInvoice
                                            data={checkout}
                                            travellers={checkout?.traveller_details}
                                            invoicing={checkout?.invoicing_details}
                                            ref={invoiceRef}
                                        />

                                        {validationMessage.message && (
                                            <p className={`${clientStyles['message']} ${clientStyles['red']} ${clientStyles['message--margin-top']}`}>{validationMessage.message}</p>
                                        )}

                                        <button className={`${clientStyles['button']} ${clientStyles['book--button']}`} onClick={() => handleReservation()}>Book now</button>
                                    </>
                                ) : (
                                    <div className={clientStyles['checkout--layout']}>
                                        <div className={`${clientStyles['checkout--section']} ${clientStyles['checkout--finalstep']}`}>
                                            <i className={`fa-regular fa-thumbs-up ${clientStyles['icon']}`}></i>
                                            <p className={clientStyles['text']}>Congratulations ✌️</p>
                                            <p className={clientStyles['text']}>Your reservation is done and you are almost ready to go 🚌</p>
                                            <p className={clientStyles['text']}>We sent you on your invoicing email ✉️ the contract and the invoice.</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Checkout