import { useState, useEffect } from 'react'

import Header from '../../Components/Header/Header'
import ProfileHeader from '../../Components/Profile/ProfileHeader/ProfileHeader'
import ProfileNavMenu from '../../Components/Profile/ProfileNavMenu/ProfileNavMenu'
import Footer from '../../Components/Footer/Footer'

import useAuth from '../../../authManager/useAuth'
import useFieldValidation from '../../../functions/useFieldValidation'
import usePutAccountPassword from '../../../hooks/account_methods/usePutAccountPassword'

import clientStyles from '../../Client.module.sass'

function ChangePassword() {
    const passwordImage = '/assets/icons/change_password.svg'

    const { auth } = useAuth()
    const { updateAccountPassword } = usePutAccountPassword()

    const [newPassword, setNewPassword] = useState({ id: auth.id, current_password: '', password: '', secondPassword: '' })
    const [message, setMessage] = useState({ color: '', text: '' })

    const { fieldValidations, checkFieldValidation } = useFieldValidation()
    const isAnyFieldInvalid = Object.values(fieldValidations).some((field) => !field.isValid)

    const updateInput = (e) => {
        const { name, value } = e.target
        setNewPassword({ ...newPassword, [name]: value })
        checkFieldValidation(name, value)
    }

    const changePassword = async (e) => {
        e.preventDefault()

        // precheck if all mandatory fields are filled
        if (!newPassword.current_password || !newPassword.password || !newPassword.secondPassword)
            return setMessage({ color: 'red', text: 'All fields marked with * must be filled.' })

        // checking if inputs meets the criteria
        if (isAnyFieldInvalid || Object.keys(fieldValidations).length === 0)
            return setMessage({ color: 'red', text: 'One or more fields are not filled properly.' })
        
        // checking if both passwords matches
        if(newPassword.password !== newPassword.secondPassword)
            return setMessage({ color: 'red', text: 'The two passwords, the new one and the verify one does not match.' })

        const response = await updateAccountPassword(newPassword)
        if (response?.data) {
            if (response.data.status === 200) {
                setMessage({ color: 'green', text: response.data.message })
                setNewPassword({ id: '', current_password: '', password: '', secondPassword: '' })
                setTimeout(() => {
                    setMessage({ color: '', text: '' })
                }, 3000)
            } else if (response.data.status === 400) {
                setMessage({ color: 'red', text: response.data.message })
            }
        }
    }

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <ProfileHeader />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['profile--flex']}>
                    <ProfileNavMenu />

                    <div className={clientStyles['profile--page']}>
                        <h2 className={clientStyles['page--title']}>Change Password</h2>
                        <div className={clientStyles['page--panel']}>
                            <div className={clientStyles['password--container']}>
                                <h3 className={clientStyles['password--title']}>Here you can change your password with a new one</h3>
                                <p className={clientStyles['password--description']}>To change your password, please fill in all the fields below.</p>
                                <p className={clientStyles['password--description']}>Your password needs to contain at least 6 characters, maximum 32 characters, also
                                    needs to include at lease one uppercase letter, one lowercase letter, one number and one special character to make sure your password is strong enough.</p>

                                <div className={clientStyles['password--form__container']}>
                                    <form className={clientStyles['password--form']} onSubmit={(e) => changePassword(e)}>
                                        <div className={clientStyles['input--row']}>
                                            <div className={clientStyles['input--field']}>
                                                <input type='password' className={clientStyles['input']} id='current_password' name={'current_password'} value={newPassword.current_password} onChange={(e) => updateInput(e)} />
                                                <label htmlFor='current_password' className={`${clientStyles['label']} ${newPassword.current_password ? clientStyles['filled'] : ''}`}>
                                                    * Current Password
                                                </label>
                                            </div>
                                            {fieldValidations?.current_password?.errorMessage &&
                                                <div className={`${clientStyles['form-field']} ${clientStyles['input-error']}`}>
                                                    <p className={clientStyles['field-error']}>{fieldValidations.current_password.errorMessage}</p>
                                                </div>
                                            }
                                        </div>

                                        <div className={clientStyles['input--row']}>
                                            <div className={clientStyles['input--field']}>
                                                <input type='password' className={clientStyles['input']} id='password' name={'password'} value={newPassword.password} onChange={(e) => updateInput(e)} />
                                                <label htmlFor='password' className={`${clientStyles['label']} ${newPassword.password ? clientStyles['filled'] : ''}`}>
                                                    * New Password
                                                </label>
                                            </div>
                                            {fieldValidations?.password?.errorMessage &&
                                                <div className={`${clientStyles['form-field']} ${clientStyles['input-error']}`}>
                                                    <p className={clientStyles['field-error']}>{fieldValidations.password.errorMessage}</p>
                                                </div>
                                            }
                                        </div>

                                        <div className={clientStyles['input--row']}>
                                            <div className={clientStyles['input--field']}>
                                                <input type='password' className={clientStyles['input']} id='secondPassword' name={'secondPassword'} value={newPassword.secondPassword} onChange={(e) => updateInput(e)} />
                                                <label htmlFor='secondPassword' className={`${clientStyles['label']} ${newPassword.secondPassword ? clientStyles['filled'] : ''}`}>
                                                    * Verify New Password
                                                </label>
                                            </div>
                                            {fieldValidations?.secondPassword?.errorMessage &&
                                                <div className={`${clientStyles['form-field']} ${clientStyles['input-error']}`}>
                                                    <p className={clientStyles['field-error']}>{fieldValidations.secondPassword.errorMessage}</p>
                                                </div>
                                            }
                                        </div>

                                        {message.text &&
                                            <div className={`${clientStyles['message']} ${clientStyles[message.color]}`}>
                                                <p className={clientStyles['message--text']}>{message.text}</p>
                                            </div>
                                        }

                                        <button className={clientStyles['button']}>
                                            <i className='fa-solid fa-pen-to-square'></i>
                                            Update
                                        </button>
                                    </form>

                                    <div className={clientStyles['password--image']}>
                                        <img src={passwordImage} alt='Password Changer' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ChangePassword