import { useState, useEffect } from 'react'
import { FormModal, FormModalField } from '../FormModal/FormModal'
import useAxiosPrivate from '../../../api/useAxiosPrivate'
import { useFormattedDate, useDefaultDate } from '../../../functions/useDateTimeFormat'
import useGetAllTours from '../../../hooks/useGetAllTours'

import styles from './OrderData.module.sass'
import adminStyles from '../../Admin.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'

function OrderSummary({ data }) {
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [orderSummary, setOrderSummary] = useState({})
    const [tourDates, setTourDates] = useState([])

    const axiosPrivate = useAxiosPrivate()
    const tours = useGetAllTours()

    const openSummaryOrderModal = () => {
        setOpenModal(!openModal)

        const selectedTour = tours.find((tour) => tour.id === orderSummary.tour_id)
        if (selectedTour) {
            const valabilityDates = JSON.parse(selectedTour.tour_valability_dates)
            setTourDates(valabilityDates)
        }
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setOrderSummary({ ...orderSummary, [name]: value })

        if (name === 'tour_id') {
            const selectedTour = tours.find((tour) => tour.id === parseInt(value))
            if (selectedTour) {
                const valabilityDates = JSON.parse(selectedTour.tour_valability_dates)
                setTourDates(valabilityDates)
            }
        }
    }

    const updateOrderSummary = async (e) => {
        e.preventDefault()

        await axiosPrivate.put(`/api/v1/orders/update/ordersummary/${data?.id}`, orderSummary)
            .then((response) => {
                setValidation({ message: response.data.message, isValid: true })

                setTimeout(() => {
                    setValidation({ message: '', isValid: false })
                    window.location.reload()
                }, 3000)
            })
            .catch((error) => {
                setValidation({ message: error.response.data.message, isValid: false })
            })
    }

    useEffect(() => {
        setOrderSummary({
            order_date: data?.order_date,
            tour_id: data?.tour_id,
            departure_date: data?.departure_date,
            tour_days: data?.tour_days,
            payment_method: data?.payment_method
        })
    }, [data])

    return (
        <>
            <div className={styles['order--details-box']}>
                <h2 className={adminStyles['box-title']}>
                    Order Summary

                    <button className={styles['order--edit-btn']} onClick={() => openSummaryOrderModal()}>
                        Edit
                        <i className='fa-solid fa-pen-to-square'></i>
                    </button>
                </h2>
                <div className={styles['box--content']}>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Order Number:</div>
                        <div className={styles['value']}>#{data?.id}</div>
                    </div>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Order Date:</div>
                        <div className={styles['value']}>{useFormattedDate(data?.order_date)}</div>
                    </div>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Tour:</div>
                        <div className={styles['value']}>{data?.tour_title}</div>
                    </div>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Tour Date:</div>
                        <div className={styles['value']}>{useFormattedDate(data?.departure_date)}</div>
                    </div>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Tour Days:</div>
                        <div className={styles['value']}>{data?.tour_days} {data?.tour_days === 1 ? 'day' : `days / ${data?.tour_days - 1} night${data?.tour_days - 1 === 1 ? '' : 's'}`}</div>
                    </div>
                    <div className={styles['row']}>
                        <div className={styles['label']}>Payment Method:</div>
                        <div className={styles['value']}>{data?.payment_method === 1 ? 'At the Agency' : 'Banking Transfer'}</div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title={'Edit Order Summary'} closeFunction={() => openSummaryOrderModal()}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateOrderSummary(e)}>
                        <div className={modalStyles['column']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Order Date</label>
                                <input type='date' name='order_date' value={useDefaultDate(orderSummary.order_date)} className={modalStyles['input']} onChange={(e) => updateFormInput(e)} />
                            </div>
                        </div>
                        <div className={modalStyles['column']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Tour</label>
                                <select className={modalStyles['input']} name='tour_id' value={orderSummary.tour_id} onChange={(e) => updateFormInput(e)}>
                                    {tours.map((tour) => (
                                        <option key={tour.id} value={tour.id}>{tour.tour_title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={modalStyles['column']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Tour Date</label>
                                <select className={modalStyles['input']} name='departure_date' value={orderSummary.departure_date} onChange={(e) => updateFormInput(e)}>
                                    {tourDates.map((date, i) => (
                                        <option key={i} value={date}>{useFormattedDate(date)}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={modalStyles['column']}>
                            <FormModalField inputLabel='Tour Days' inputName='tour_days' inputValue={orderSummary.tour_days} inputUpdate={(e) => updateFormInput(e)} />
                        </div>
                        <div className={modalStyles['column']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Payment Method</label>
                                <select className={modalStyles['input']} name='payment_method' value={orderSummary.payment_method} onChange={(e) => updateFormInput(e)}>
                                    <option value='1'>At the Agency</option>
                                    <option value='2'>Banking Transfer</option>
                                </select>
                            </div>
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            Update
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default OrderSummary