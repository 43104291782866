import { useState } from 'react'
import useRoleList from '../functions/useRoleList'

const useAccountRole = (roles) => {
    const roleList = useRoleList()
    const [userRoles, setUserRoles] = useState({
        isEditor: roles?.includes(roleList.editor) || false,
        isAdmin: roles?.includes(roleList.admin) || false
    })

    return userRoles
}

export default useAccountRole