import { Link } from 'react-router-dom'

import styles from './Logo.module.sass'

function Logo() {
    const headerLogo = '/assets/logos/logo_black.svg'

    return (
        <div className={styles['logo--container']}>
            <Link to='/'><img src={headerLogo} alt='Destiny Tour Logo' /></Link>
        </div>
    )
}

export default Logo