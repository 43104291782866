import { useState, useEffect } from 'react'
import { BASE_URL } from '../../../api/axios'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import useAxiosPrivate from '../../../api/useAxiosPrivate'
import useTourById from '../../../hooks/useTourById'
import useTourGalleryById from '../../../hooks/useTourGalleryById'
import useGetAllDestinations from '../../../hooks/useGetAllDestinations'
import useGetAllAdditionalServices from '../../../hooks/useGetAllAdditionalServices'

import Pagination from './Pagination/Pagination'
import { FormModal, FormModalField } from '../FormModal/FormModal'

import styles from './Tables.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function ToursTable({ tours, itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const currentItems = tours.length && tours.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [data, setData] = useState({
        id: '',
        tour_link: '',
        tour_title: '',
        tour_tags: '',
        tour_days: '',
        representative_image_url: '',
        map: '',
        num_of_adults: '',
        adult_price: '',
        num_of_childs: '',
        child_price: '',
        destination_id: '',
        tour_valability_dates: [],
        additional_services: []
    })
    const [editorContent, setEditorContent] = useState('')
    const [gallery, setGallery] = useState({})

    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [isNew, setIsNew] = useState(false)

    const axiosPrivate = useAxiosPrivate()
    const tour = useTourById(data.id)
    const imgGallery = useTourGalleryById(data.id)
    const destinations = useGetAllDestinations()
    const services = useGetAllAdditionalServices()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const addNewTour = () => {
        setOpenModal(!openModal)
        setEditorContent('')
        setGallery({})
        setData({
            id: '',
            tour_link: '',
            tour_title: '',
            tour_tags: '',
            tour_days: '',
            representative_image_url: '',
            map: '',
            num_of_adults: 1,
            adult_price: '',
            num_of_childs: 0,
            child_price: '',
            destination_id: 1,
            tour_valability_dates: [],
            additional_services: []
        })
        setIsNew(!isNew)
    }

    const handleModal = (id) => {
        if (openModal && isNew) {
            setIsNew(!isNew)
        }

        setOpenModal(!openModal)
        setData({ ...data, id })
        setValidation({ message: '', isValid: false })
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target;
        if (e.target.files && e.target.files[0]) {
            setData({ ...data, [name]: e.target.files[0] })
        } else {
            setData({ ...data, [name]: value })
        }
    }

    const deleteRepresentativeImage = () => {
        setData({ ...data, representative_image_url: '' })
    }

    const handleValabilityDates = (e) => {
        e.preventDefault()

        setData({ ...data, tour_valability_dates: [...data.tour_valability_dates, ''] })
    }

    const updateDateInput = (e, i) => {
        const { value } = e.target
        const updatedDates = [...data.tour_valability_dates]
        updatedDates[i] = value

        setData({ ...data, tour_valability_dates: updatedDates })
    }

    const deleteDateInput = (i) => {
        const updatedDates = [...data.tour_valability_dates]
        updatedDates.splice(i, 1)

        setData({ ...data, tour_valability_dates: updatedDates })
    }

    const handleImageUpload = (e, i) => {
        const file = e.target.files[0]

        if (file) {
            setGallery({ ...gallery, [i]: file })
        }
    }

    const deleteImageGallery = (i) => {
        const updatedGallery = { ...gallery }
        delete updatedGallery[i]
        setGallery(updatedGallery)
    }

    const updateAdditionalServices = (e, serviceId) => {
        const { checked } = e.target

        setData((data) => {
            const updatedServices = checked
                ? [...data.additional_services, serviceId]
                : data.additional_services.filter((id) => id !== serviceId)

            return {
                ...data,
                additional_services: updatedServices
            }
        })
    }

    const updateData = async (e) => {
        e.preventDefault()

        if (Object.values(gallery).length < 3) {
            return setValidation({ message: 'There should be uploaded at least 3 images in the gallery.', isValid: false })
        }

        const formData = new FormData()

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key])
        })

        formData.append('tour_content', editorContent)

        Object.values(gallery).forEach((file) => {
            if (file.image_url) {
                formData.append('tour_images', file.image_url)
            } else {
                formData.append('tour_images', file)
            }
        })

        if (isNew) {
            await axiosPrivate.post('/api/v1/tours/item/add/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setEditorContent('')
                    setGallery({})
                    setData({
                        id: '',
                        tour_link: '',
                        tour_title: '',
                        tour_tags: '',
                        tour_days: '',
                        representative_image_url: '',
                        map: '',
                        num_of_adults: 1,
                        adult_price: '',
                        num_of_childs: 0,
                        child_price: '',
                        destination: 1,
                        tour_valability_dates: [],
                        additional_services: []
                    })
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } else {
            await axiosPrivate.put(`/api/v1/tours/item/update/${data.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        }
    }

    useEffect(() => {
        if (data.id) {
            setData({
                ...data,
                tour_link: tour.tour_link,
                tour_title: tour.tour_title,
                tour_tags: tour.tour_tags,
                tour_days: tour.tour_days,
                representative_image_url: tour.representative_image_url,
                num_of_adults: tour.num_of_adults,
                adult_price: tour.adult_price,
                num_of_childs: tour.num_of_childs,
                child_price: tour.child_price,
                map: tour.map,
                destination_id: tour.destination_id,
                tour_valability_dates: JSON.parse(tour.tour_valability_dates),
                additional_services: tour.additional_services
            })
            setEditorContent(tour.tour_content)
            setGallery(imgGallery)
        }
    }, [tour, imgGallery])

    return (
        <>
            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Tours</h2>

                        {hasAddButton && (
                            <button className={styles['button']} onClick={() => addNewTour()}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['tours--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Representative Image</div>
                                <div className={styles['heading']}>Tour Title</div>
                                <div className={styles['heading']}>Tour Tags</div>
                                <div className={styles['heading']}>Tour Price</div>
                                <div className={styles['heading']}>Tour Destination</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['tours--table']}`}>
                            {currentItems ? (
                                <>
                                    {currentItems.map((tour, i) => (
                                        <div key={i} className={styles['row']}>
                                            <div className={styles['text']}>{i + 1}</div>
                                            <div className={styles['text']}>
                                                <img src={BASE_URL + tour.representative_image_url} alt={tour?.tour_title} />
                                            </div>
                                            <div className={styles['text']}>{tour.tour_title ? tour.tour_title : 'No title yet.'}</div>
                                            <div className={styles['text']}>{tour.tour_tags ? tour.tour_tags : 'No tags yet.'}</div>
                                            <div className={styles['text']}>
                                                {tour.child_price
                                                    ? 'from ' + tour.child_price + '€'
                                                    : tour.adult_price
                                                        ? 'from ' + tour.adult_price + '€'
                                                        : 'No price yet.'}
                                            </div>
                                            <div className={styles['text']}>{destinations[tour.destination_id - 1]?.destination_name}</div>
                                            <div className={styles['text']}>
                                                <div className={styles['tool--button']} onClick={() => handleModal(tour.id)}>
                                                    <i className='fa-solid fa-highlighter'></i>
                                                    Edit
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className={globalStyles['no-data--message']}>There is not data yet...</p>
                            )}

                            <Pagination totalItems={tours.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title={isNew ? 'Add Tour' : 'Edit Tour'} closeFunction={handleModal} modalSize={'XXL'}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Tour Link' inputName='tour_link' inputValue={data.tour_link} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Tour Title' inputName='tour_title' inputValue={data.tour_title} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Tour Tags' inputName='tour_tags' inputValue={data.tour_tags} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Tour Days' inputName='tour_days' inputValue={data.tour_days} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Destinations</label>

                                    <select className={modalStyles['input']} name='destination_id' value={data.destination_id} onChange={(e) => updateFormInput(e)}>
                                        {destinations.map((destination, i) => (
                                            <option key={i} value={destination.id}>{destination.destination_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Content</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorContent}
                                        onChange={(event, editor) => {
                                            const content = editor.getData()
                                            setEditorContent(content)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Maximum Num. of Adults</label>

                                    <select className={modalStyles['input']} name='num_of_adults' value={data.num_of_adults} onChange={(e) => updateFormInput(e)}>
                                        {[...Array(20).keys()].map((i) => (
                                            <option key={i} value={i + 1}>{i + 1}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Adult Price' inputName='adult_price' inputValue={data.adult_price} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Maximum Num. of Childrens</label>

                                    <select className={modalStyles['input']} name='num_of_childs' value={data.num_of_childs} onChange={(e) => updateFormInput(e)}>
                                        {[...Array(21).keys()].map((i) => (
                                            <option key={i} value={i}>{i}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Children Price' inputName='child_price' inputValue={data.child_price} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Maximum Num. of Childrens</label>
                                <div className={modalStyles['services--layout']}>
                                    {services.map((service, i) => (
                                        <div key={i} className={modalStyles['service--item']}>
                                            <input
                                                id={`service--${i}`}
                                                type='checkbox'
                                                name='checkbox'
                                                value={service.id}
                                                checked={data.additional_services?.includes(service.id)}
                                                onChange={(e) => updateAdditionalServices(e, service.id)}
                                            />
                                            <label htmlFor={`service--${i}`}>
                                                {service.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Tour Map' inputName='map' inputValue={data.map} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Valability Dates</label>

                                    <div className={modalStyles['dynamically--columns']}>
                                        {data.tour_valability_dates.map((date, i) => (
                                            <div key={i} className={`${modalStyles['column']} ${modalStyles['date--container']}`}>
                                                <input type='date' value={date} className={modalStyles['input']} onChange={(e) => updateDateInput(e, i)} />
                                                <div className={modalStyles['delete--button']} onClick={() => deleteDateInput(i)}>X</div>
                                            </div>
                                        ))}
                                    </div>

                                    <button className={globalStyles['button']} onClick={(e) => handleValabilityDates(e)}>Add New Date</button>
                                </div>
                            </div>
                        </div>

                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Representative Image</label>

                                    <div className={globalStyles['image--gallery']}>
                                        <div className={globalStyles['item']}>
                                            {!data.representative_image_url ? (
                                                <>
                                                    <label htmlFor='representative_image' className={globalStyles['upload-button']}>
                                                        <i className={`fa-solid fa-arrow-up-from-bracket`}></i>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        id='representative_image'
                                                        name='representative_image_url'
                                                        className={globalStyles['upload-input']}
                                                        accept='image/png, image/jpg, image/jpeg, image/svg'
                                                        onChange={(e) => updateFormInput(e)}
                                                    />
                                                </>
                                            ) : (
                                                <div className={globalStyles['image-uploaded']}>
                                                    <img src={data.representative_image_url?.name
                                                        ? URL.createObjectURL(data.representative_image_url)
                                                        : BASE_URL + data.representative_image_url} alt={data.tour_title} />
                                                    <div className={globalStyles['delete-image']} onClick={() => deleteRepresentativeImage()}>X</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Image Gallery</label>

                                    <div className={globalStyles['image--gallery']}>
                                        {[...Array(5).keys()].map((i) => (
                                            <div key={i} className={globalStyles['item']}>
                                                {!gallery[i] ? (
                                                    <>
                                                        <label htmlFor={`image-${i + 1}`} className={globalStyles['upload-button']}>
                                                            <i className={`fa-solid fa-arrow-up-from-bracket`}></i>
                                                        </label>
                                                        <input
                                                            type='file'
                                                            id={`image-${i + 1}`}
                                                            className={globalStyles['upload-input']}
                                                            accept='image/png, image/jpg, image/jpeg, image/svg'
                                                            onChange={(e) => handleImageUpload(e, i)}
                                                        />
                                                    </>
                                                ) : (
                                                    <div className={globalStyles['image-uploaded']}>
                                                        <img src={!gallery[i].image_url
                                                            ? URL.createObjectURL(gallery[i])
                                                            : BASE_URL + gallery[i].image_url} alt={data.tour_title} />
                                                        <div className={globalStyles['delete-image']} onClick={() => deleteImageGallery(i)}>X</div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            {isNew ? 'Add' : 'Update'}
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default ToursTable