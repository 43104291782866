import { Link } from 'react-router-dom'

import { BASE_URL, CLIENT_BASE_URL } from '../../../../api/axios'
import useGetAllTours from '../../../../hooks/useGetAllTours'
import useGetAllDestinations from '../../../../hooks/useGetAllDestinations'
import { useFormattedDate } from '../../../../functions/useDateTimeFormat'

import styles from './ToursGrid.module.sass'
import clientStyles from '../../../../styles/Globals.module.sass'

function ToursGrid() {
    const tours = useGetAllTours()
    const destinations = useGetAllDestinations()

    return (
        <div className={clientStyles['page--size']}>
            <div className={clientStyles['page--gap__XL']}>
                <h2 className={clientStyles['module--title']}>Tours Would You Like</h2>
                <p className={clientStyles['module--description']}>Do you think finally to take a break? Now you can choose from one of the following tours we have and make your future holiday come true.</p>
                {tours.length
                    ? (
                        <div className={styles['grid-card--image']}>
                            {tours.map((tour, i) => (
                                <div key={i} className={styles['card']}>
                                    <div className={styles['image']}>
                                        <img src={BASE_URL + tour.representative_image_url} alt={tour.tour_title} />
                                    </div>
                                    <div className={styles['card--details']}>
                                        <div className={styles['title']}>{tour.tour_title}</div>
                                        <div className={styles['destination']}>
                                            <i className={`fa-solid fa-map-pin ${styles['card-icon']}`}></i>
                                            {destinations[tour.destination_id - 1]?.destination_name}
                                        </div>
                                        <div className={styles['days']}>
                                            <i className={`fa-solid fa-hourglass-half ${styles['card-icon']}`}></i>
                                            {tour.tour_days} {tour.tour_days === 1 ? 'day' : `days / ${tour.tour_days - 1} night${tour.tour_days - 1 === 1 ? '' : 's'}`}
                                        </div>
                                        <div className={styles['valabilities']}>
                                            <i className={`fa-solid fa-calendar-days ${styles['card-icon']}`}></i>
                                            {JSON.parse(tour.tour_valability_dates).map((date, i) => (
                                                <span key={i} className={styles['date']}>{useFormattedDate(date)}</span>
                                            ))}
                                        </div>
                                        <div className={styles['price']}>
                                            {tour.child_price || tour.adult_price ? (
                                                <>
                                                    <span className={styles['from']}>from</span>
                                                    {tour.child_price ? tour.child_price : tour.adult_price}€
                                                </>
                                            ) : (
                                                <p className={styles['no-price']}>No price yet.</p>
                                            )}
                                        </div>
                                    </div>
                                    <Link to={'/tours/' + tour.tour_link} className={styles['button']}>Book Now</Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className={clientStyles['module--loading']}>Loading ...</p>
                    )}
            </div>
        </div>
    )
}

export default ToursGrid