import axios from '../api/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
    const { setAuth } = useAuth()

    const refresh = async () => {
        const response = await axios.get('/api/v1/refresh', { withCredentials: true })
        setAuth(() => {
            return {
                id: response.data.id,
                name: response.data.name,
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                twofa_enabled: response.data.twofa_enabled
            }
        })
        return response.data.accessToken
    }
    
    return refresh
}

export default useRefreshToken