import { useState } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import useAuth from '../../../../authManager/useAuth'
import useAccountById from '../../../../hooks/account_methods/useAccountById'
import useAccountRole from '../../../../hooks/useAccountRole'

import Logo from '../Logo/Logo'
import ProfileNavLink from '../../General/ProfileNavLink/ProfileNavLink'

import styles from './NavBar.module.sass'
import clientStyles from '../../../Client.module.sass'

function NavBar() {
    const profilePicture = '/assets/boy.png'

    const [showDropdown, setShowDropdown] = useState(false)

    const { auth } = useAuth()
    const account = useAccountById(auth.id)
    const userRoles = useAccountRole(auth.roles)
    const { isEditor, isAdmin } = userRoles

    const toggleDropdown = () => setShowDropdown(!showDropdown)

    return (
        <>
            <div className={styles['navbar--container']}>
                <div className={`${clientStyles['page--size']} ${styles['navbar']}`}>
                    <Logo />
                    <div className={styles['navbar--links']}>
                        {!auth?.id ? (
                            <>
                                <Link to='/login/' className={styles['link']}>Login</Link>
                                <Link to='/register/' className={`${styles['link']} ${styles['signup--button']}`}>Sign Up</Link>
                            </>
                        ) : (
                            <>
                                <div className={styles['profile']}>
                                    <div className={styles['profile--button']} onClick={() => toggleDropdown()}>
                                        {profilePicture
                                            ? <img className={styles['picture']} src={profilePicture} alt={`${account?.name}`} />
                                            : <Skeleton style={{ width: '4.5rem', height: '4.5rem', borderRadius: '100%' }} />
                                        }
                                        <div className={styles['name']}>
                                            {account?.name
                                                ? account.name
                                                : <Skeleton style={{ width: '12.5rem', height: '2rem', borderRadius: '1rem' }} />
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles['profile--dropdown']} ${showDropdown ? styles['active'] : ''}`}>
                                        <div className={`${styles['row']} ${styles['picture-name']}`}>
                                            <img className={styles['picture']} src={profilePicture} alt={`${account?.name}`} />
                                            <div className={styles['name']}>{account?.name}</div>
                                        </div>
                                        <div className={styles['row']}>
                                            {(isAdmin || isEditor) && <ProfileNavLink to='/admin/' label='Admin Panel' iconClass='fa-chart-line' />}
                                            <ProfileNavLink to='/account/' label='My Account' iconClass='fa-user' />
                                            <ProfileNavLink to='/account/change-password/' label='Change Password' iconClass='fa-eye-slash' />
                                        </div>
                                        <div className={styles['row']}>
                                            <ProfileNavLink label='Logout' iconClass='fa-right-from-bracket' isLogout={true} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar