import { useState, useEffect } from 'react'

import useAuth from '../../../authManager/useAuth'
import useRefreshToken from '../../../authManager/useRefreshToken'
import useUpdateTwoFA from '../../../hooks/account_methods/useUpdateTwoFA'

import Header from '../../Components/Header/Header'
import ProfileHeader from '../../Components/Profile/ProfileHeader/ProfileHeader'
import ProfileNavMenu from '../../Components/Profile/ProfileNavMenu/ProfileNavMenu'
import { FormModal } from '../../Components/General/FormModal/FormModal'
import Footer from '../../Components/Footer/Footer'

import clientStyles from '../../Client.module.sass'

function TwoFAAuthentication() {
    const [otpCode, setOtpCode] = useState('')
    const [otpSteps, setOtpSteps] = useState(1)
    const [otpError, setOtpError] = useState('')

    const [openModal, setOpenModal] = useState(false)
    const [message, setMessage] = useState({ color: '', text: '' })

    const emailIcon = '/assets/icons/email.svg'
    const TwoFAEnabledImage = '/assets/icons/authentication_enabled.svg'
    const TwoFADisabledImage = '/assets/icons/authentication_disabled.svg'

    const { auth } = useAuth()
    const refresh = useRefreshToken()
    const { updateTwoFA } = useUpdateTwoFA()

    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    const handleChange = (e) => {
        if (otpSteps !== 1) {
            const { value } = e.target

            if (value.length === 0) {
                setOtpError('Your OTP code can not be empty.')
            } else if (!(/^\d+$/).test(value)) {
                setOtpError('Your OTP code should contain just digit characters.')
            } else if (value.length !== 6) {
                setOtpError('Your OTP code should be exactly 6 digits.')
            } else {
                setOtpError('')
            }

            setOtpCode(value)
        }
    }

    const handleOtpCode = async () => {
        if (otpSteps === 1) {
            try {
                const response = await updateTwoFA(auth.id)
                setMessage({ color: 'green', text: response.message })
                setOtpSteps(2)
            } catch (error) {
                if (error.response?.status === 406) {
                    setMessage({ color: 'red', text: error.response.data.message })
                    setOtpSteps(2)
                } else {
                    setMessage({ color: 'red', text: 'An unexpected error occured trying to update your 2FA authentication.' })
                }
            }
        } else {
            try {
                const response = await updateTwoFA(auth.id, otpCode, !auth.twofa_enabled ? 'enable' : 'disable')
                setMessage({ color: 'green', text: response.message })
                setOtpCode('')
                setOtpSteps(1)
                await refresh() // we refresh the token for safety and to refresh the twofa_enabled status
                setTimeout(() => {
                    setMessage({ color: '', text: '' })
                }, 3000)
            } catch (error) {
                if (error.response?.status === 406) {
                    setMessage({ color: 'red', text: error.response.data.message })
                } else {
                    setMessage({ color: 'red', text: 'An unexpected error occured trying to update your 2FA authentication.' })
                }
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <ProfileHeader />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['profile--flex']}>
                    <ProfileNavMenu />

                    <div className={clientStyles['profile--page']}>
                        <h2 className={clientStyles['page--title']}>2FA Authentication</h2>
                        <div className={clientStyles['page--panel']}>
                            <div className={clientStyles['twofactor--container']}>
                                {auth.twofa_enabled ? (
                                    <div className={clientStyles['status']}>
                                        <img className={clientStyles['status--image']} src={TwoFAEnabledImage} alt='2FA Authentication Enabled' />
                                        <p className={clientStyles['status--text']}>Two factor authentication is now enabled.</p>
                                    </div>
                                ) : (
                                    <div className={clientStyles['status']}>
                                        <img className={clientStyles['status--image']} src={TwoFADisabledImage} alt='2FA Authentication Disabled' />
                                        <p className={clientStyles['status--text']}>Two factor authentication is not enabled yet.</p>
                                    </div>
                                )}

                                <p className={clientStyles['description']}>Two factor authentication adds an additional layer of security
                                    to your account by requiring more than just a password to sign in.</p>
                            </div>

                            <div className={clientStyles['twofactor--methods']}>
                                <div className={clientStyles['box--method']} onClick={() => toggleModal()}>
                                    <div className={clientStyles['box--content']}>
                                        <div className={clientStyles['bubble--icon']}>
                                            <img className={clientStyles['status--image']} src={emailIcon} alt='Email Authentication' />
                                        </div>
                                        <div className={clientStyles['information']}>
                                            <span className={clientStyles['type']}>Email Address</span>
                                            <span className={clientStyles['hint']}>You will receive 6 digit codes through email on every sign in.</span>
                                        </div>
                                    </div>
                                    <div className={clientStyles['box--switch']}>
                                        <div className={`${clientStyles['switch']} ${auth.twofa_enabled ? clientStyles['enabled'] : clientStyles['disabled']}`}>
                                            <div className={clientStyles['dot']} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title='2FA Authentication Email' closeFunction={toggleModal}>
                    <div className={clientStyles['twofactor--container']}>
                        <div className={clientStyles['otp--image']}>
                            <img src={emailIcon} alt='2FA Registration' />
                        </div>

                        <h2 className={clientStyles['title']}>
                            Your 2FA authentication is {auth.twofa_enabled ? 'enabled' : 'disabled'}.
                        </h2>

                        <div className={clientStyles['steps']}>
                            <div className={clientStyles['step']}>
                                <div className={clientStyles['step--number']}>1</div>
                                <div className={clientStyles['step--content']}>
                                    <p className={clientStyles['text']}>This is a method that on every log in requests a code through your email address.
                                        Before enabling the 2FA authentication through email, we need to verify the OTP code that will be sent in your email.</p>
                                    <p className={clientStyles['text']}>Be aware to check the <strong>Inbox folder</strong> in your email and also if there is no email, to check also <strong>Spam folder</strong>.</p>
                                    <p className={clientStyles['text']}>You can also disable your authenticator here if it's turned on following exact the enabling steps.</p>
                                </div>
                            </div>

                            <div className={clientStyles['step']}>
                                <div className={clientStyles['step--number']}>2</div>
                                <div className={clientStyles['step--content']}>
                                    <p className={clientStyles['text']}>
                                        {otpSteps === 1
                                            ? 'Please press on "Get a Code" button to get an OTP code:'
                                            : 'Enter the confirmation code sent through email:'
                                        }
                                    </p>

                                    <form className={clientStyles['otp--form']} onSubmit={(e) => handleSubmit(e)}>
                                        <div className={clientStyles['input--container']}>
                                            <input
                                                type='text'
                                                className={clientStyles['otp--input']}
                                                value={otpCode}
                                                onChange={(e) => handleChange(e)}
                                                placeholder={otpSteps === 1 ? 'Press on Get a Code' : 'Enter your code here...'}
                                                disabled={otpSteps === 1 ? true : false}
                                            />

                                            <input
                                                type='submit'
                                                className={clientStyles['otp--button']}
                                                value={otpSteps === 1 ? 'Get a Code' : 'Confirm'}
                                                onClick={() => handleOtpCode()}
                                            />
                                        </div>

                                        {otpError && (
                                            <p className={clientStyles['otp--error']}>{otpError}</p>
                                        )}

                                        {message.text &&
                                            <div className={`${clientStyles['message']} ${clientStyles[message.color]}`}>
                                                <p className={clientStyles['message--text']}>{message.text}</p>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormModal>
            )}

            <Footer />
        </>
    )
}

export default TwoFAAuthentication