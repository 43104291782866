import useAxiosPrivate from '../../api/useAxiosPrivate'

const usePutPersonInvoicingById = () => {
    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController()

    const updatePersonInvoicingDetails = async (data) => {
        try {
            const response = await axiosPrivate.put(`/api/v1/accounts/person-invoicing/${data.id}`, data, { signal: controller.signal })
            return response
        } catch (error) {
            console.log(error)
        } finally {
            controller.abort()
        }
    }

    return { updatePersonInvoicingDetails }
}

export default usePutPersonInvoicingById