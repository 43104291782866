import { useState, useEffect } from 'react'

import useAxiosPrivate from '../../../api/useAxiosPrivate'
import useGetAdditionalServiceById from '../../../hooks/useGetAdditionalServiceById'

import Pagination from './Pagination/Pagination'
import { FormModal, FormModalField } from '../FormModal/FormModal'

import styles from './Tables.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function AdditionalServicesTable({ services, itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const currentItems = services.length && services.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [data, setData] = useState({ id: '', name: '', price_value: '' })
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [isNew, setIsNew] = useState(false)

    const axiosPrivate = useAxiosPrivate()
    const service = useGetAdditionalServiceById(data.id)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const addNewService = () => {
        setOpenModal(!openModal)
        setData({ id: '', name: '', price_value: '' })
        setValidation({ message: '', isValid: false })
        setIsNew(!isNew)
    }

    const handleModal = (id) => {
        if (openModal && isNew) {
            setIsNew(!isNew)
        }

        setOpenModal(!openModal)
        setData({ ...data, id })
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const updateData = async (e) => {
        e.preventDefault()

        if (isNew) {
            await axiosPrivate.post('/api/v1/tours/additional-services/add/', data)
                .then((response) => {
                    setData({ id: '', name: '', price_value: '' })
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })

        } else {
            await axiosPrivate.put(`/api/v1/tours/additional-services/update/${data.id}`, data)
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        }
    }

    useEffect(() => {
        if (data.id) {
            setData({
                ...data,
                name: service.name,
                price_value: service.price_value
            })
        }
    }, [service])

    return (
        <>
            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Additional Services</h2>

                        {hasAddButton && (
                            <button className={styles['button']} onClick={() => addNewService()}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['additional-services--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Service Name</div>
                                <div className={styles['heading']}>Value</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['additional-services--table']}`}>
                            {currentItems ? (
                                <>
                                    {currentItems.map((service, i) => (
                                        <div key={i} className={styles['row']}>
                                            <div className={styles['text']}>{i + 1}</div>
                                            <div className={styles['text']}>{service.name ? service.name : 'No service name.'}</div>
                                            <div className={styles['text']}>
                                                {service.price_value
                                                    ? service.price_value + '€'
                                                    : 'No service price.'
                                                }
                                            </div>
                                            <div className={styles['text']}>
                                                <div className={styles['tool--button']} onClick={() => handleModal(service.id)}>
                                                    <i className='fa-solid fa-highlighter'></i>
                                                    Edit
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className={globalStyles['no-data--message']}>There is not data yet...</p>
                            )}

                            <Pagination totalItems={services.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title={isNew ? 'Add Additional Service' : 'Edit Additional Service'} closeFunction={handleModal}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Additional Service Name' inputName='name' inputValue={data.name} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Additional Service Value' inputName='price_value' inputValue={data.price_value} inputUpdate={updateFormInput} />
                            </div>
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            {isNew ? 'Add': 'Update'}
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default AdditionalServicesTable