import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import useAuth from '../../../../../authManager/useAuth'
import useAccountById from '../../../../../hooks/account_methods/useAccountById'

import styles from './ProfileAvatar.module.sass'
import clientStyles from '../../../../Client.module.sass'

function ProfileAvatar() {
    const profilePicture = '/assets/boy.png'

    const { auth } = useAuth()
    const account = useAccountById(auth.id)

    return (
        <>
            <div className={clientStyles['page--size']}>
                <div className={styles['avatar--container']}>
                    <div className={styles['avatar--image']}>
                        <img src={profilePicture} alt='Profile Picture' />
                    </div>
                    <div className={styles['avatar--details']}>
                        <div className={styles['name']}>
                            {account?.name
                                ? account.name
                                : <Skeleton style={{ width: '15rem', height: '2.6rem', borderRadius: '1rem' }} />
                            }
                        </div>
                        <div className={styles['email']}>
                            {account?.email
                                ? account.email
                                : <Skeleton style={{ width: '25rem', height: '1.8rem', borderRadius: '1rem' }} />
                            }</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileAvatar