import { NavLink, useLocation } from 'react-router-dom'
import useLogout from '../../../../authManager/useLogout'

import styles from './ProfileNavLink.module.sass'

function ProfileNavLink({ to, label, iconClass, hasArrow = true , isLogout }) {
    const location = useLocation()
    const isActive = location.pathname.endsWith(to)

    const logout = useLogout()
    const signOut = async () => await logout()

    return (
        <>
            {!isLogout
                ? (
                    <NavLink className={`${styles['profile--button']} ${isActive ? styles['active'] : ''}`} to={to}>
                        <div className={styles['with-icon']}>
                            <i className={`fa-solid ${iconClass} ${styles['icon']}`}></i>
                            {label}
                        </div>
                        {hasArrow ? <i className={`fa-solid fa-chevron-right ${styles['arrow']}`}></i> : ''}
                    </NavLink>
                ) : (
                    <div className={styles['profile--button']} onClick={() => signOut()}>
                        <div className={styles['with-icon']}>
                            <i className={`fa-solid ${iconClass} ${styles['icon']}`}></i>
                            {label}
                        </div>
                    </div>
                )}
        </>
    )
}

export default ProfileNavLink