import { BrowserRouter, Routes, Route } from 'react-router-dom'

// utils
import useRoleList from './functions/useRoleList'
import RequireAuth from './authManager/RequireAuth'
import PersistLogin from './authManager/PersistLogin'

// accounts components
import Login from './admin/Accounts/Login'
import Register from './admin/Accounts/Register'
import Recovery from './admin/Accounts/Recovery'
import PasswordRecovery from './admin/Accounts/PasswordRecovery'

const accountRoutes = [
	{ path: '/login/', exact: true, component: Login },
	{ path: '/register/', exact: true, component: Register },
	{ path: '/register/:token', exact: true, component: Register },
	{ path: '/recovery/', exact: true, component: Recovery },
	{ path: '/recovery/password/:token', exact: true, component: PasswordRecovery }
]

// admin components
import AdminDashboard from './admin/Pages/Dashboard'
import AdminUsers from './admin/Pages/Users'
import AdminInvoicing from './admin/Pages/Invoicing'
import AdminTours from './admin/Pages/Tours'
import AdminDestinations from './admin/Pages/Destinations'
import AdminCoupons from './admin/Pages/Coupons'
import AdminAdditionalServices from './admin/Pages/AdditionalServices'
import AdminOrders from './admin/Pages/Orders'
import AdminOrderDetails from './admin/Pages/OrderDetails'
import AdminNewsletter from './admin/Pages/Newsletter'
import AdminNotFound from './admin/Pages/NotFound'

const adminRoutes = [
	{ path: '/admin/', exact: true, component: AdminDashboard },
	{ path: '/admin/users/', exact: true, component: AdminUsers },
	{ path: '/admin/invoicing/', exact: true, component: AdminInvoicing },
	{ path: '/admin/tours/', exact: true, component: AdminTours },
	{ path: '/admin/destinations/', exact: true, component: AdminDestinations },
	{ path: '/admin/coupons/', exact: true, component: AdminCoupons },
	{ path: '/admin/additional-services/', exact: true, component: AdminAdditionalServices },
	{ path: '/admin/orders/', exact: true, component: AdminOrders },
	{ path: '/admin/orders/:id', exact: true, component: AdminOrderDetails },
	{ path: '/admin/newsletter/', exact: true, component: AdminNewsletter },
	{ path: '/admin/*', exact: true, component: AdminNotFound }
]

// client components
import Landing from './client/Pages/Landing'
import Profile from './client/Pages/Profile/Profile'
import ChangePassword from './client/Pages/Profile/ChangePassword'
import TwoFAAuthentication from './client/Pages/Profile/TwoFAAuthentication'
import MyBookings from './client/Pages/Profile/MyBookings'
import MyWishlist from './client/Pages/Profile/MyWishlist'
import Order from './client/Pages/Profile/Order'
import TourPage from './client/Pages/TourPage'
import Checkout from './client/Pages/Checkout'
import Destinations from './client/Pages/Destinations'

const clientRoutes = [
	{ path: '/', exact: true, component: Landing },
	{ path: '/*', exact: true, component: Landing },
	{ path: '/tours/:link', exact: true, component: TourPage },
	{ path: '/payments/*', exact: true, component: Landing },
	{ path: '/payments/checkout/', exact: true, component: Checkout },
	{ path: '/destinations/', exact: true, component: Destinations },
	{ path: '/destinations/:destination', exact: true, component: Destinations }
]

const clientRoutesProtectedByUserRole = [
	{ path: '/account/', exact: true, component: Profile },
	{ path: '/account/change-password/', exact: true, component: ChangePassword },
	{ path: '/account/twofa-authentication/', exact: true, component: TwoFAAuthentication },
	{ path: '/account/my-bookings/', exact: true, component: MyBookings },
	{ path: '/account/my-bookings/order/:id', exact: true, component: Order },
	{ path: '/account/wishlist/', exact: true, component: MyWishlist },
	{ path: '/account/*', exact: true, component: Profile },
]

function App() {
	const roleList = useRoleList()

	return (
		<>
			<BrowserRouter>
				<Routes>
					{/* account routes */}
					{accountRoutes.map((route) => (
						<Route key={route.path} exact={route.exact} path={route.path} element={<route.component />} />
					))}

					<Route element={<PersistLogin />}>
						{/* admin routes */}
						<Route element={<RequireAuth allowedRoles={[roleList.admin, roleList.editor]} />}>
							{adminRoutes.map((route) => (
								<Route key={route.path} exact={route.exact} path={route.path} element={<route.component />} />
							))}
						</Route>

						{/* client routes */}
						{clientRoutes.map((route) => (
							<Route key={route.path} exact={route.exact} path={route.path} element={<route.component />} />
						))}

						<Route element={<RequireAuth allowedRoles={[roleList.admin, roleList.editor, roleList.user]} />}>
							{clientRoutesProtectedByUserRole.map((route) => (
								<Route key={route.path} exact={route.exact} path={route.path} element={<route.component />} />
							))}
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App