import useAxiosPrivate from '../../api/useAxiosPrivate'

const usePutBusinessInvoicingById = () => {
    const axiosPrivate = useAxiosPrivate()
    const controller = new AbortController()

    const updateBusinessInvoicingDetails = async (data) => {
        try {
            const response = await axiosPrivate.put(`/api/v1/accounts/business-invoicing/${data.id}`, data, { signal: controller.signal })
            return response
        } catch (error) {
            console.log(error)
        } finally {
            controller.abort()
        }
    }

    return { updateBusinessInvoicingDetails }
}

export default usePutBusinessInvoicingById