import useGetCouponById from '../../../hooks/useGetCouponById'

import styles from './OrderData.module.sass'
import adminStyles from '../../Admin.module.sass'

function OrderPriceBreakdown({ data }) {
    const coupon = useGetCouponById(data.coupon_id)

    return (
        <div className={`${styles['order--details-box']} ${styles['price-breakdown']}`}>
            <h2 className={adminStyles['box-title']}>Price Breakdown</h2>
            <div className={styles['box--content']}>
                <div className={styles['row']}>
                    <div className={styles['label']}>Adult Price:</div>
                    <div className={styles['value']}>{data.adult_price * data.num_of_adults}€ ({data.num_of_adults} x {data.adult_price}€)</div>
                </div>
                {data?.num_of_childs ? (
                    <div className={styles['row']}>
                        <div className={styles['label']}>Child Price:</div>
                        <div className={styles['value']}>{data.child_price * data.num_of_childs}€ ({data.num_of_childs} x {data.child_price}€)</div>
                    </div>
                ) : null}
                {data?.coupon_id && (
                    <>
                        <div className={styles['divider']} />
                        <div className={styles['row']}>
                            <div className={styles['label']}>Coupon:</div>
                            <div className={styles['value']}>
                                <i>{coupon.name}</i> - has a <strong>discount of {coupon.price_type === 'fixed' ? `${coupon.price_value}€` : `${coupon.price_value}%`}</strong> from main price
                            </div>
                        </div>
                    </>
                )}
                {data?.additional_services?.length ? (
                    <>
                        <div className={styles['divider']} />
                        {data.additional_services.map((service, i) => (
                            <div key={i} className={styles['row']}>
                                <div className={styles['label']}>Additional Service ({i + 1}):</div>
                                <div className={styles['value']}>
                                    <i>{service.name}</i> - has a <strong>price of {service.price_value}€</strong>
                                </div>
                            </div>
                        ))}
                    </>
                ) : null}
                <div className={styles['divider']} />
                <div className={`${styles['row']} ${styles['row-total']}`}>
                    <div className={styles['label']}>Total Price:</div>
                    <div className={styles['value']}>{data.total_price}€</div>
                </div>
            </div>
        </div>
    )
}

export default OrderPriceBreakdown