import useGetAllDestinations from '../../hooks/useGetAllDestinations'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'
import DestinationsTable from '../Components/Tables/DestinationsTable'

import styles from '../Admin.module.sass'

function Destinations() {
    const destinations = useGetAllDestinations()

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Destinations'} />
                    <div className={styles['container']}>
                        <DestinationsTable destinations={destinations} itemsPerPage={10} hasAddButton={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Destinations