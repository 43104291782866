import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'
import OrderSummary from '../Components/OrderData/OrderSummary'
import OrderContact from '../Components/OrderData/OrderContact'
import OrderInvoicing from '../Components/OrderData/OrderInvoicing'
import OrderTravellers from '../Components/OrderData/OrderTravellers'
import OrderPriceBreakdown from '../Components/OrderData/OrderPriceBreakdown'

import GenerateContract from '../../Documents/GenerateContract'
import GenerateInvoice from '../../Documents/GenerateInvoice'

import styles from '../Admin.module.sass'

function OrderDetails() {
    const { id } = useParams()
    const contractRef = useRef()
    const invoiceRef = useRef()

    const [data, setData] = useState({})
    const [message, setMessage] = useState('')
    const [orderExist, setOrderExist] = useState('')

    const axiosPrivate = useAxiosPrivate()

    const handleStatus = (e) => {
        const { value } = e.target

        setData({
            ...data,
            status: value
        })
    }

    const changeStatus = async (e) => {
        e.preventDefault()

        await axiosPrivate.put(`/api/v1/orders/status/${id}`, { status: data.status })
            .then((response) => {
                setMessage(response.data.message)

                setTimeout(() => {
                    setMessage('')
                }, 3000)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // generate the name to build document name
    const generateName = data?.invoicing_details?.invoicing.physical_name
        ? data?.invoicing_details?.invoicing.physical_name?.toLocaleLowerCase().replace(/\s/g, '_')
        : data?.invoicing_details?.invoicing.legal_name?.toLocaleLowerCase().replace(/\s/g, '_')

    // setting up the pdf settings
    const pdfSettings = {
        margin: 10,
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            dpi: 192,
            scale: 4,
            scrollX: 0,
            scrollY: 0,
            letterRendering: true,
            useCORS: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
            hotfixes: ['px_scaling']
        },
        pagebreak: {
            mode: ['css', 'legacy']
        }
    }

    const downloadContract = () => {
        // get reference to your HTML from the component
        const element = contractRef.current

        // concatening the settings with the one above and the name
        const opt = {
            ...pdfSettings,
            filename: `contract_${generateName}.pdf`
        }

        // getting the documents ready
        html2pdf().from(element).set(opt).save()
    }

    const downloadInvoice = () => {
        // get reference to your HTML from the component
        const element = invoiceRef.current

        // concatening the settings with the one above and the name
        const opt = {
            ...pdfSettings,
            filename: `invoice_${generateName}.pdf`
        }

        // getting the documents ready
        html2pdf().from(element).set(opt).save()
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/orders/${id}`)
                setData(response.data)
            } catch (error) {
                console.log(error)
                setOrderExist(error.response?.data?.message || 'Error fetching order data.')
            }
        }

        fetchData()
    }, [id, axiosPrivate])

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={!orderExist ? `Order #${data.id} - ${data.tour_title}` : 'Invalid order'} />
                    {data && (
                        <div className={styles['order--container']}>
                            {!orderExist ? (
                                <>
                                    <div className={styles['details']}>
                                        <div className={styles['details--invoice']}>
                                            <OrderSummary data={data} />
                                            <OrderContact data={data.contact_details} />
                                            <OrderInvoicing data={data.invoicing_details} />
                                        </div>
                                        <OrderTravellers data={data.traveller_details} />
                                        <OrderPriceBreakdown data={data} />

                                        <div className={styles['invoice--buttons']}>
                                            <button className={styles['button']} onClick={() => downloadContract()}>Download Contract</button>
                                            <button className={styles['button']} onClick={() => downloadInvoice()}>Download Invoice</button>
                                        </div>
                                    </div>
                                    <form className={styles['status']} onSubmit={(e) => changeStatus(e)}>
                                        <h2 className={styles['box-title']}>Order Status</h2>
                                        <select value={data?.status} className={styles['status--dropdown']} onChange={(e) => handleStatus(e)}>
                                            <option value='pending'>Pending</option>
                                            <option value='paid'>Paid</option>
                                            <option value='rejected'>Rejected</option>
                                            <option value='canceled'>Canceled</option>
                                        </select>

                                        <input type='submit' className={styles['update--status-btn']} value='Update Status' />

                                        {message && (
                                            <p className={styles['status-message']}>{message}</p>
                                        )}
                                    </form>
                                </>
                            ) : (
                                <p>{orderExist}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <GenerateContract
                data={data}
                contact={data?.contact_details?.details}
                invoicing={data?.invoicing_details?.invoicing}
                ref={contractRef}
            />
            <GenerateInvoice
                data={data}
                travellers={data?.traveller_details?.travellers}
                invoicing={data?.invoicing_details?.invoicing}
                ref={invoiceRef}
            />
        </>
    )
}

export default OrderDetails