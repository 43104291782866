const useFormattedDate = (inputDate) => {
    if (inputDate === '' || inputDate === null) return null

    const date = new Date(inputDate)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`

    return formattedDate
}

const useFormattedTime = (inputTime) => {
    if (inputTime === '' || inputTime === null) return null

    const time = new Date(inputTime)
    const hours = time.getHours()
    const minutes = time.getMinutes()

    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return formattedTime
}

const useDefaultDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export { useFormattedDate, useFormattedTime, useDefaultDate }