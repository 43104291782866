import useAuth from '../../../../authManager/useAuth'
import useAccountRole from '../../../../hooks/useAccountRole'
import ProfileNavLink from '../../General/ProfileNavLink/ProfileNavLink'

import styles from './ProfileNavMenu.module.sass'

function ProfileNavMenu() {
    const { auth } = useAuth()
    const userRoles = useAccountRole(auth.roles)
    const { isEditor, isAdmin } = userRoles

    return (
        <>
            <div className={styles['menu--section']}>
                <h2 className={styles['menu--title']}>Profile Menu</h2>
                <div className={styles['nav--menu']}>
                    <div className={styles['links--items']}>
                        <div className={styles['section']}>
                            <h3 className={styles['section--title']}>Account</h3>
                            {(isAdmin || isEditor) && <ProfileNavLink to='/admin/' label='Admin Panel' iconClass='fa-chart-line' />}
                            <ProfileNavLink to='/account/' label='My Account' iconClass='fa-user' />
                            <ProfileNavLink to='/account/change-password/' label='Change Password' iconClass='fa-eye-slash' />
                            <ProfileNavLink to='/account/twofa-authentication/' label='2FA Authentication' iconClass='fa-shield-halved' />
                        </div>
                        <div className={styles['section']}>
                            <h3 className={styles['section--title']}>Booking</h3>
                            <ProfileNavLink to='/account/my-bookings/' label='My Bookings' iconClass='fa-mountain-sun' />
                            <ProfileNavLink to='/account/wishlist/' label='Wishlist' iconClass='fa-heart' />
                        </div>
                    </div>
                    <div className={styles['logout--section']}>
                        <ProfileNavLink label='Logout' iconClass='fa-right-from-bracket' isLogout={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileNavMenu