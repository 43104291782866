import { useState, useEffect } from 'react'

import useAxiosPrivate from '../../../api/useAxiosPrivate'
import useRoleList from '../../../functions/useRoleList'
import useAccountById from '../../../hooks/account_methods/useAccountById'

import Pagination from './Pagination/Pagination'
import { FormModal, FormModalField } from '../FormModal/FormModal'

import styles from './Tables.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function UsersTable({ users, itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const currentItems = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [data, setData] = useState({ id: '', name: '', email: '', creation_email: '', password: '', phone: '', roles: '', account_activation: '', twofa_enabled: '' })
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [isNew, setIsNew] = useState(false)

    const axiosPrivate = useAxiosPrivate()
    const roleList = useRoleList()
    const account = useAccountById(data.id)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const addNewAccount = () => {
        setOpenModal(!openModal)
        setData({ id: '', name: '', email: '', creation_email: '', password: '', phone: '', roles: '', account_activation: '', twofa_enabled: '' })
        setValidation({ message: '', isValid: false })
        setIsNew(!isNew)
    }

    const handleModal = (id) => {
        if (openModal && isNew) {
            setIsNew(!isNew)
        }

        setOpenModal(!openModal)
        setData({ ...data, id })
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const handleSelectChange = (e, field) => {
        const { value } = e.target

        setData({
            ...data,
            [field]: value
        })
    }

    const handleRoles = (roleName, roleValue) => {
        if (data.roles.hasOwnProperty(roleName)) {
            const newData = { ...data }
            delete newData.roles[roleName]
            setData(newData)
            console.log(newData)
        } else {
            setData((prevData) => ({
                ...prevData,
                roles: { ...prevData.roles, [roleName]: roleValue }
            }))
        }
    }

    const updateData = async (e) => {
        e.preventDefault()

        if (isNew) {
            await axiosPrivate.post('/api/v1/accounts/users/add/', data)
                .then((response) => {
                    setData({ id: '', name: '', email: '', creation_email: '', password: '', phone: '', roles: '', account_activation: '', twofa_enabled: '' })
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })

        } else {
            await axiosPrivate.put(`/api/v1/accounts/users/update/${data.id}`, data)
                .then((response) => {
                    setData({ ...data, password: '' })
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        }
    }

    useEffect(() => {
        if (data.id) {
            setData({
                ...data,
                name: account.name,
                email: account.email,
                creation_email: account.creation_email,
                password: '',
                phone: account.phone,
                roles: JSON.parse(account.roles),
                account_activation: account.account_activation,
                twofa_enabled: account.twofa_enabled
            })
        }
    }, [account])

    return (
        <>
            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Users</h2>

                        {hasAddButton && (
                            <button className={styles['button']} onClick={() => addNewAccount()}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['users--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Full Name</div>
                                <div className={styles['heading']}>Email Address</div>
                                <div className={styles['heading']}>Phone Number</div>
                                <div className={styles['heading']}>Account Activation</div>
                                <div className={styles['heading']}>2FA Authentication</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['users--table']}`}>
                            {currentItems.map((user, i) => (
                                <div key={i} className={styles['row']}>
                                    <div className={styles['text']}>{i + 1}</div>
                                    <div className={styles['text']}>{user.name ? user.name : 'No name.'}</div>
                                    <div className={styles['text']}>{user.email ? user.email : 'No email address.'}</div>
                                    <div className={styles['text']}>{user.phone ? user.phone : 'No phone number.'}</div>
                                    <div className={styles['text']}>
                                        {user.account_activation
                                            ? <div className={styles['enabled']}>Enabled</div>
                                            : <div className={styles['disabled']}>Disabled</div>}
                                    </div>
                                    <div className={styles['text']}>
                                        {user.twofa_enabled
                                            ? <div className={styles['enabled']}>Enabled</div>
                                            : <div className={styles['disabled']}>Disabled</div>}
                                    </div>
                                    <div className={styles['text']}>
                                        <div className={styles['tool--button']} onClick={() => handleModal(user.id)}>
                                            <i className="fa-solid fa-highlighter"></i>
                                            Edit
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <Pagination totalItems={users.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <FormModal title={'Edit Account'} closeFunction={handleModal}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Name' inputName='name' inputValue={data.name} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Email Address' inputName='email' inputValue={data.email} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Creation Email Address' inputName='creation_email' inputValue={data.creation_email} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Password' inputName='password' inputValue={data.password} inputUpdate={updateFormInput} />
                            </div>
                            <div className={modalStyles['column']}>
                                <FormModalField inputLabel='* Phone Number' inputName='phone' inputValue={data.phone} inputUpdate={updateFormInput} />
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>Account Activation</label>
                                    <select className={modalStyles['input']} value={data.account_activation} onChange={(e) => handleSelectChange(e, 'account_activation')}>
                                        <option value='0'>Disabled</option>
                                        <option value='1'>Enabled</option>
                                    </select>
                                </div>
                            </div>
                            <div className={modalStyles['column']}>
                                <div className={modalStyles['input--field']}>
                                    <label className={modalStyles['label-outside']}>2FA Authentication</label>
                                    <select className={modalStyles['input']} value={data.twofa_enabled} onChange={(e) => handleSelectChange(e, 'twofa_enabled')}>
                                        <option value='0'>Disabled</option>
                                        <option value='1'>Enabled</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={modalStyles['row']}>
                            <div className={modalStyles['input--field']}>
                                <label className={modalStyles['label-outside']}>Roles</label>
                                {Object.entries(roleList).map(([roleName, roleValue]) => (
                                    <div className={modalStyles['role']} key={roleName}>
                                        <input
                                            id={`form-${roleName}`}
                                            type='checkbox'
                                            name='checkbox'
                                            value={roleValue}
                                            checked={data.roles[roleName] === roleValue}
                                            onChange={() => handleRoles(roleName, roleValue)}
                                        />
                                        <label htmlFor={`form-${roleName}`}>
                                            {roleName.charAt(0).toUpperCase() + roleName.slice(1)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            Update
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default UsersTable