import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from '../../api/axios'
import useAuth from '../../authManager/useAuth'
import useRoleList from '../../functions/useRoleList'

import config from '../../config.json'

import styles from './Accounts.module.sass'
import globalStyles from '../../styles/Globals.module.sass'

function Login() {
    const roleList = useRoleList()
    const { setAuth, rememberDevice, setRememberDevice } = useAuth()
    const [isTwoFA, setIsTwoFA] = useState(0)

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    const [data, setData] = useState({ email: '', password: '', otp_code: '' })
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [otpError, setOtpError] = useState('')

    const [captchaNums, setCaptchaNums] = useState({ firstNum: 0, secondNum: 0 })
    const [captchaState, setCaptchaState] = useState(false)

    // used to calculate the two numbers needed
    const doCaptchaCalculation = () => {
        return captchaNums.firstNum + captchaNums.secondNum
    }

    // used to disable or enable the login button
    const checkCaptchaCalculation = (e) => {
        if (parseInt(e.target.value) === doCaptchaCalculation()) {
            return setCaptchaState(true)
        } else {
            return setCaptchaState(false)
        }
    }

    // used to generate two random numbers for login math
    useEffect(() => {
        const captchaFirstNum = Math.floor(Math.random() * 20) + 1
        const captchaSecondNum = Math.floor(Math.random() * 20) + 1

        setCaptchaNums({ firstNum: captchaFirstNum, secondNum: captchaSecondNum })
    }, [])

    // changing the state for "Remember this device?"
    const togglePersist = () => {
        setRememberDevice(!rememberDevice)
    }

    // updating the storage with our value for login persisting
    useEffect(() => {
        localStorage.setItem('__remember', rememberDevice)
    }, [rememberDevice])

    // handling all the inputs available
    const handleInputs = (e) => {
        const { name, value } = e.target

        if (name === 'otp_code') {
            if (value.length === 0) {
                setOtpError('Your OTP code can not be empty.')
            } else if (!(/^\d+$/).test(value)) {
                setOtpError('Your OTP code should contain just digit characters.')
            } else if (value.length !== 6) {
                setOtpError('Your OTP code should be exactly 6 digits.')
            } else {
                setOtpError('')
            }
        }

        setData({ ...data, [name]: value })
    }

    // handling the API call for login
    const handleLogin = async (dataToServer) => {
        await axios.post('/api/v1/signin', dataToServer, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
            .then((response) => {
                const account = response.data

                const id = account.id
                const name = account.name
                const roles = account.roles
                const accessToken = account.accessToken
                const twofa_enabled = account.twofa_enabled

                const hasEditorOrAdmin = roles.includes(roleList.editor) || roles.includes(roleList.admin)
                setAuth({ id, name, roles, accessToken, twofa_enabled })
                navigate(hasEditorOrAdmin ? from : '/', { replace: true })
            })
            .catch((error) => {
                setValidation({ message: error.response.data.message, isValid: false })
            })
    }

    // handling the login form here
    const loginAccount = async (e) => {
        e.preventDefault()

        try {
            // getting the data we want to test in login process and the call API to test if the account has 2FA enabled
            const checkEmail = { email: data.email, password: data.password }
            await axios.post('/api/v1/signin/check-authenticator/', checkEmail, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
                .then(async (response) => {
                    // we store the state of the 2FA
                    setIsTwoFA(response.data.twoFA_status)

                    // if the 2FA is disabled, then we login without it
                    if (!response.data.twoFA_status) {
                        const dataToServer = { email: data.email, password: data.password }
                        await handleLogin(dataToServer)
                    } else {
                        // for any other messages from the past form, we empty them
                        if (validation.message) {
                            setValidation({ message: '', isValid: false })
                        }
                    }

                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } catch (error) {
            setValidation({ message: 'An unexpected error occured. Try again later...', isValid: false })
        }
    }

    const loginAccountWithOtp = async (e) => {
        e.preventDefault();

        try {
            const dataToServer = { email: data.email, password: data.password, otp_code: data.otp_code }
            await handleLogin(dataToServer)
        } catch (error) {
            setValidation({ message: 'An unexpected error occurred. Try again later...', isValid: false })
        }
    }

    return (
        <>
            <Helmet>
                <title>Destiny Tour - Log In</title>
            </Helmet>

            <div className={styles['form-page']}>
                <div className={styles['form-image']}>
                    <img src='/assets/accounts/login.svg' alt='Login Form' />
                </div>
                <form className={styles['form']} onSubmit={isTwoFA ? loginAccountWithOtp : loginAccount}>
                    <h1 className={styles['form-title']}>Log In</h1>

                    {isTwoFA ? (
                        <>
                            <p className={styles['form-title--description']}>An email with an OTP code was sent to your email address. Please check Inbox and/or Spam folders.</p>

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-user-secret ${styles['icon']}`}></i>
                                <input className={styles['form-input']} type='text' name='otp_code' value={data.otp_code} onChange={(e) => handleInputs(e)} placeholder='OTP Code' autoComplete='otp_code' />
                            </div>

                            {otpError && (
                                <p className={globalStyles['validation--error']}>{otpError}</p>
                            )}

                            <div className={styles['form-field']}>
                                <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Validate' />
                            </div>
                        </>
                    ) : (
                        <>
                            <p className={styles['form-title--description']}>Login here to access your {config.COMPANY_NAME} account.</p>

                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-envelope ${styles['icon']}`}></i>
                                <input className={styles['form-input']} type='email' name='email' onChange={(e) => handleInputs(e)} placeholder='Email Address' autoComplete='email' />
                            </div>
                            <div className={styles['form-field']}>
                                <i className={`fa-solid fa-key ${styles['icon']}`}></i>
                                <input className={styles['form-input']} type='password' name='password' onChange={(e) => handleInputs(e)} placeholder='Password' autoComplete='current-password' />
                            </div>
                            <div className={styles['form-field']}>
                                <input id='form-checkbox' type='checkbox' name='checkbox' onChange={() => togglePersist()} checked={rememberDevice} />
                                <label htmlFor='form-checkbox'>Remember this device?</label>
                            </div>

                            <div className={`${styles['form-field']} ${styles['captcha-field']}`}>
                                <span className={styles['captcha']}>Please solve the following: <b>{captchaNums.firstNum} + {captchaNums.secondNum} = </b></span>
                                <input className={`${styles['form-input']} ${styles['captcha-input']}`} type='text' name='captcha-result' onChange={checkCaptchaCalculation} placeholder='?' />
                            </div>

                            <div className={styles['form-field']}>
                                <input className={`${styles['form-input']} ${styles['form-button']}`} type='submit' value='Log in' disabled={captchaState ? '' : true} />
                            </div>
                        </>
                    )}

                    {validation.message &&
                        <div className={`${globalStyles['validation--message']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                            {validation.message}
                        </div>
                    }

                    <div className={styles['account-util']}>
                        <p>You don't have an account? <Link to='/register/'>Create one</Link></p>
                        <p>You need help with your account? <Link to='/recovery/'>Recover now</Link></p>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login