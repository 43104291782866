import useGetAllOrders from '../../hooks/useGetAllOrders'

import NavMenu from '../Components/NavMenu/NavMenu'
import TopBar from '../Components/TopBar/TopBar'
import UtilityBar from '../Components/UtilityBar/UtilityBar'

import styles from '../Admin.module.sass'
import OrdersTable from '../Components/Tables/OrdersTable'

function Orders() {
    const orders = useGetAllOrders()

    return (
        <>
            <TopBar />
            <div className={styles['page--body']}>
                <NavMenu />
                <div className={styles['page--container']}>
                    <UtilityBar title={'Orders'} />
                    <div className={styles['container']}>
                        <OrdersTable orders={orders} itemsPerPage={10} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders