import { BASE_URL } from '../../api/axios'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams, useLocation } from 'react-router-dom'

import useAuth from '../../authManager/useAuth'
import useAxiosPrivate from '../../api/useAxiosPrivate'

import useGetAllTours from '../../hooks/useGetAllTours'
import useGetAllDestinations from '../../hooks/useGetAllDestinations'
import useGetAllAdditionalServices from '../../hooks/useGetAllAdditionalServices'
import { useFormattedDate } from '../../functions/useDateTimeFormat'

import Header from '../Components/Header/Header'
import Banner from '../Components/Header/Banner/Banner'
import Footer from '../Components/Footer/Footer'
import BookingForm from '../Components/General/BookingForm/BookingForm'
import WishlistButton from '../Components/General/WishlistButton/WishlistButton'

import clientStyles from '../Client.module.sass'

function Destinations() {
    const { state } = useLocation()
    const { destination } = useParams()
    const tours = useGetAllTours()
    const destinations = useGetAllDestinations()
    const services = useGetAllAdditionalServices()

    const { auth } = useAuth(1)
    const axiosPrivate = useAxiosPrivate()

    const [wishlist, setWishlist] = useState({})
    const [destinationFilter, setDestinationFilter] = useState('')
    const [dateFilter, setDateFilter] = useState({ from: '', to: '' })
    const [priceFilter, setPriceFilter] = useState('')
    const [dayFilter, setDayFilter] = useState('')
    const [serviceFilter, setServiceFilter] = useState('')

    const [isUserChangingFilter, setIsUserChangingFilter] = useState(false)

    const priceRanges = [
        { id: '0-100', label: '0 - 100€', range: [0, 100] },
        { id: '100-250', label: '100€ - 250€', range: [100, 250] },
        { id: '250-500', label: '250€ - 500€', range: [250, 500] },
        { id: '500-1000', label: '500€ - 1000€', range: [500, 1000] },
        { id: '>1000', label: 'over 1000€', range: [1000, Infinity] }
    ]

    const filteredItems = Object.values(tours).filter((item) => {
        const isDestinationMatch = !destinationFilter || item.destination_id == destinationFilter

        const tourDates = JSON.parse(item.tour_valability_dates).map((date) => new Date(date))
        const isFromDateMatch = !dateFilter.from || tourDates.some((date) => date >= new Date(dateFilter.from))
        const isToDateMatch = !dateFilter.to || tourDates.some((date) => date <= new Date(dateFilter.to))

        const isDayMatch = !dayFilter || (dayFilter === 5 ? item.tour_days >= dayFilter : item.tour_days === dayFilter)
        const isServiceMatch = !serviceFilter || item.additional_services.some((service) => service.id === serviceFilter)

        let isPriceMatch = true
        if (priceFilter) {
            const priceRange = priceRanges.find((range) => range.id === priceFilter)
            if (priceRange) {
                const [minPrice, maxPrice] = priceRange.range
                const childPrice = item.child_price !== undefined ? item.child_price : Infinity
                const adultPrice = item.adult_price !== undefined ? item.adult_price : Infinity
                const priceToCheck = childPrice !== Infinity ? childPrice : adultPrice
                isPriceMatch = priceToCheck >= minPrice && priceToCheck <= maxPrice
            }
        }

        return isDestinationMatch && isFromDateMatch && isToDateMatch && isPriceMatch && isDayMatch && isServiceMatch
    })

    const handlePriceCheckbox = (value) => {
        setPriceFilter((value) === priceFilter ? null : value)
    }

    const handleDayCheckbox = (value) => {
        setDayFilter((value) === dayFilter ? null : value)
    }

    const handleServiceCheckbox = (value) => {
        setServiceFilter((value) === serviceFilter ? null : value)
    }

    const handleDestinationFilterChange = (newFilter) => {
        setIsUserChangingFilter(true)
        setDestinationFilter(newFilter)
    }

    // added to go to the page top
    useEffect(() => {
        window.scrollTo(0, 0)

        if (state) {
            setDestinationFilter(state.destinationFilter)
            setDateFilter(state.dateFilter)
        }
    }, [])

    useEffect(() => {
        axiosPrivate.get(`/api/v1/tours/wishlist/${auth?.id}`)
            .then((response) => {
                setWishlist(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [wishlist])

    useEffect(() => {
        if (destination && destinations.length) {
            const matchedDestination = destinations.find((dest) => dest.destination_name.toLowerCase() === destination.toLowerCase())
            if (matchedDestination && !isUserChangingFilter) {
                setDestinationFilter(matchedDestination.id)
            }
        }
    }, [destination, destinations, isUserChangingFilter])

    useEffect(() => {
        if (!destination && destinations.length && !isUserChangingFilter) {
            setDestinationFilter('')
        }
        setIsUserChangingFilter(false)
    }, [destination])

    return (
        <>
            <Helmet>
                <title>{`Destiny Tour - ${destination ? destination.charAt(0).toUpperCase() + destination.slice(1) : 'Destinations'}`}</title>
            </Helmet>

            <Header />
            <Banner
                img='/assets/banners/destination_wallpaper.jpg'
                title={destination ? destination.charAt(0).toUpperCase() + destination.slice(1) : 'Destinations'}
                size='M'
            />

            <div className={clientStyles['page--size']}>
                <div className={clientStyles['page--gap__XL']}>
                    <BookingForm
                        destinations={destinations}
                        destinationFilter={destinationFilter}
                        setDestinationFilter={handleDestinationFilterChange}
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                    />

                    <div className={clientStyles['filters-and-tours']}>
                        <div className={clientStyles['filters']}>
                            <div className={clientStyles['container']}>
                                <h2 className={clientStyles['title']}>Filters</h2>

                                <div className={clientStyles['filter--item']}>
                                    <h3 className={clientStyles['filter--title']}>Price</h3>
                                    <div className={clientStyles['filter--items']}>
                                        {priceRanges.map((price) => (
                                            <div key={price.id} className={clientStyles['item']}>
                                                <input
                                                    id={`price${price.id}`}
                                                    type='checkbox'
                                                    checked={priceFilter === price.id}
                                                    onChange={() => handlePriceCheckbox(price.id)}
                                                />
                                                <label htmlFor={`price${price.id}`}>{price.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className={clientStyles['filter--item']}>
                                    <h3 className={clientStyles['filter--title']}>Days</h3>
                                    <div className={clientStyles['filter--items']}>
                                        {[...Array(5).keys()].map((day) => {
                                            const dayValue = day + 1
                                            return (
                                                <div key={dayValue} className={clientStyles['item']}>
                                                    <input
                                                        id={`day${dayValue}`}
                                                        type='checkbox'
                                                        checked={dayFilter === dayValue}
                                                        onChange={() => handleDayCheckbox(dayValue)}
                                                    />
                                                    <label htmlFor={`day${dayValue}`}>
                                                        {dayValue === 5 ? 'over 5 days' : `${dayValue} day${dayValue > 1 ? 's' : ''}`}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className={clientStyles['filter--item']}>
                                    <h3 className={clientStyles['filter--title']}>Additional Serives</h3>
                                    <div className={clientStyles['filter--items']}>
                                        {Object.values(services).map((service) => (
                                            <div key={service.id} className={clientStyles['item']}>
                                                <input
                                                    id={`service${service.id}`}
                                                    type='checkbox'
                                                    checked={serviceFilter === service.id}
                                                    onChange={() => handleServiceCheckbox(service.id)}
                                                />
                                                <label htmlFor={`service${service.id}`}>{service.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={clientStyles['tours']}>
                            {filteredItems.length ? (
                                filteredItems.map((tour) => (
                                    <div key={tour.id} className={clientStyles['tour']}>
                                        <div className={clientStyles['image-container']}>
                                            <div className={clientStyles['image']}>
                                                <img src={BASE_URL + tour.representative_image_url} alt={tour.tour_title} />
                                            </div>
                                            <div className={clientStyles['image-counter']}>{tour.total_images} images</div>
                                        </div>
                                        <div className={clientStyles['info']}>
                                            <div className={clientStyles['info--header']}>
                                                <div className={clientStyles['title']}>{tour.tour_title}</div>
                                                <div className={clientStyles['price']}>
                                                    {tour.child_price || tour.adult_price ? (
                                                        <>
                                                            <span className={clientStyles['from']}>starting from</span>
                                                            <div className={clientStyles['final-price']}>
                                                                {tour.child_price ? tour.child_price : tour.adult_price}€
                                                                <div className={clientStyles['unit']}>/pers</div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <p className={clientStyles['no-price']}>No price yet.</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={clientStyles['info--content']}>
                                                <div className={`${clientStyles['about']} ${clientStyles['destination']}`}>
                                                    <i className={`fa-solid fa-map-pin ${clientStyles['icon']}`}></i>
                                                    <span className={clientStyles['text']}>{destinations[tour.destination_id - 1]?.destination_name}</span>
                                                </div>
                                                <div className={`${clientStyles['about']} ${clientStyles['days']}`}>
                                                    <i className={`fa-solid fa-hourglass-half ${clientStyles['icon']}`}></i>
                                                    <span className={clientStyles['text']}>{tour.tour_days} {tour.tour_days === 1 ? 'day' : `days / ${tour.tour_days - 1} night${tour.tour_days - 1 === 1 ? '' : 's'}`}</span>
                                                </div>
                                                <div className={`${clientStyles['about']} ${clientStyles['valabilities']}`}>
                                                    <i className={`fa-solid fa-calendar-days ${clientStyles['icon']}`}></i>
                                                    <span className={clientStyles['text']}>
                                                        {JSON.parse(tour.tour_valability_dates).map((date, i) => (
                                                            <span key={i} className={clientStyles['date']}>{useFormattedDate(date)}</span>
                                                        ))}
                                                    </span>
                                                </div>
                                                <div className={`${clientStyles['about']} ${clientStyles['people']}`}>
                                                    <i className={`fa-solid fa-street-view ${clientStyles['icon']}`}></i>
                                                    <span className={clientStyles['text']}>max. {tour.num_of_adults + tour.num_of_childs} people per reservation</span>
                                                </div>
                                            </div>
                                            <div className={clientStyles['info--footer']}>
                                                <WishlistButton tourId={tour.id} wishlist={wishlist} />
                                                <Link to={`/tours/${tour.tour_link}`} className={clientStyles['link-to-tour']}>View Tour</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className={clientStyles['no-tours']}>There are no tours matching requested filters.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Destinations