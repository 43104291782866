import { useState, useEffect } from 'react'
import { FormModal, FormModalField } from '../FormModal/FormModal'
import useAxiosPrivate from '../../../api/useAxiosPrivate'

import styles from './OrderData.module.sass'
import adminStyles from '../../Admin.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'

function OrderContact({ data }) {
    const [openModal, setOpenModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [contactDetails, setContactDetails] = useState({})

    const axiosPrivate = useAxiosPrivate()

    const openContactModal = () => {
        setOpenModal(!openModal)
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setContactDetails({ ...contactDetails, [name]: value })
    }

    const updateContact = async (e) => {
        e.preventDefault()

        await axiosPrivate.put(`/api/v1/orders/update/contact/${data?.id}`, contactDetails)
            .then((response) => {
                setValidation({ message: response.data.message, isValid: true })

                setTimeout(() => {
                    setValidation({ message: '', isValid: false })
                    window.location.reload()
                }, 3000)
            })
            .catch((error) => {
                setValidation({ message: error.response.data.message, isValid: false })
            })
    }

    useEffect(() => {
        setContactDetails(data?.details)
    }, [data])

    return (
        <>
            <div className={styles['order--details-box']}>
                <h2 className={adminStyles['box-title']}>
                    Contact Details

                    <button className={styles['order--edit-btn']} onClick={() => openContactModal()}>
                        Edit
                        <i className='fa-solid fa-pen-to-square'></i>
                    </button>
                </h2>
                {data?.details ? (
                    <div className={styles['box--content']}>
                        <div className={styles['row']}>
                            <div className={styles['label']}>First & Last Name:</div>
                            <div className={styles['value']}>{data?.details?.first_name} {data?.details?.last_name}</div>
                        </div>
                        <div className={styles['row']}>
                            <div className={styles['label']}>Email Address:</div>
                            <div className={styles['value']}>{data?.details?.email_address}</div>
                        </div>
                        <div className={styles['row']}>
                            <div className={styles['label']}>Phone Number:</div>
                            <div className={styles['value']}>{data?.details?.phone}</div>
                        </div>
                    </div>
                ) : (
                    <p className={styles['no-data']}>There are no contact details yet.</p>
                )}
            </div>

            {openModal && (
                <FormModal title={'Edit Contact Details'} closeFunction={() => openContactModal()}>
                    <form className={modalStyles['modal--form']} onSubmit={(e) => updateContact(e)}>
                        <div className={modalStyles['column']}>
                            <FormModalField inputLabel='First Name' inputName='first_name' inputValue={contactDetails.first_name} inputUpdate={(e) => updateFormInput(e)} />
                        </div>
                        <div className={modalStyles['column']}>
                            <FormModalField inputLabel='Last Name' inputName='last_name' inputValue={contactDetails.last_name} inputUpdate={(e) => updateFormInput(e)} />
                        </div>
                        <div className={modalStyles['column']}>
                            <FormModalField inputLabel='Email Address' inputName='email_address' inputValue={contactDetails.email_address} inputUpdate={(e) => updateFormInput(e)} />
                        </div>
                        <div className={modalStyles['column']}>
                            <FormModalField inputLabel='Phone' inputName='phone' inputValue={contactDetails.phone} inputUpdate={(e) => updateFormInput(e)} />
                        </div>

                        {validation.message &&
                            <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                {validation.message}
                            </div>
                        }

                        <button className={modalStyles['modal--save']}>
                            <i className='fa-solid fa-pen-to-square'></i>
                            Update
                        </button>
                    </form>
                </FormModal>
            )}
        </>
    )
}

export default OrderContact