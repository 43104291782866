import { useState, useEffect } from 'react'
import { motion, AnimatePresence  } from 'framer-motion'

import { useFormattedDate, useDefaultDate } from '../../../functions/useDateTimeFormat'
import useGetAllTours from '../../../hooks/useGetAllTours'

import useAxiosPrivate from '../../../api/useAxiosPrivate'

import Pagination from './Pagination/Pagination'
import { FormModal, FormModalField } from '../FormModal/FormModal'

import styles from './Tables.module.sass'
import modalStyles from '../FormModal/FormModal.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function NewsletterTable({ itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [subscribers, setSubscribers] = useState({})
    const [data, setData] = useState({
        email: '',
        subscription_date: '',
        status: 'confirmed'
    })

    const tours = useGetAllTours()
    const [tourId, setTourId] = useState('')

    const currentItems = subscribers.length && subscribers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

    const [openModal, setOpenModal] = useState(false)
    const [tourModal, setTourModal] = useState(false)
    const [validation, setValidation] = useState({ message: '', isValid: false })
    const [isNew, setIsNew] = useState(false)

    const axiosPrivate = useAxiosPrivate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const updateFormInput = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const updateTourId = (e) => {
        const { value } = e.target
        setTourId(value)
    }

    const addNewSubscription = () => {
        setOpenModal(!openModal)
        setData({
            email: '',
            subscription_date: '',
            status: 'confirmed'
        })
        setValidation({
            message: '',
            isValid: false
        })
        setIsNew(true)
    }

    const closeModal = () => {
        setOpenModal(!openModal)
        setIsNew(false)
        setValidation({ message: '', isValid: false })
    }

    const getSubscriptionData = async (id) => {
        setOpenModal(!openModal)

        await axiosPrivate.get(`/api/v1/newsletters/${id}`)
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const deleteSubscriptionData = async (id) => {
        if (confirm('Are you sure you want to delete this subscription?')) {
            await axiosPrivate.delete(`/api/v1/newsletters/${id}`)
                .then((response) => {
                    alert(response.data.message)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const newsletterModal = () => {
        setTourModal(!tourModal)
        setTourId(tours[0].id)
    }

    const sendNewsletter = async (e) => {
        e.preventDefault()

        if (confirm('Are you sure you want to send the newsletter to all subscribers?')) {
            await axiosPrivate.post('/api/v1/newsletters/send/', { tour_id: tourId })
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } else {
            alert('You refused to send the newsletter.')
        }
    }

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
        let str = 'Email Address; Subscription Date\r\n'

        for (let i = 0; i < array.length; i++) {
            let line = ''
            if (array[i].email && array[i].subscription_date) {
                line += `${array[i].email}; ${useFormattedDate(array[i].subscription_date)}`
                str += line + '\r\n'
            }
        }
        return str
    }

    const exportCSV = () => {
        const csvData = new Blob([convertToCSV(subscribers)], { type: 'text/csv' })
        const csvURL = URL.createObjectURL(csvData)
        const link = document.createElement('a')
        link.href = csvURL
        link.download = `subscribers.csv`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const updateData = async (e) => {
        e.preventDefault()

        const dataToServer = {
            isNew: isNew,
            ...data
        }

        if (isNew) {
            await axiosPrivate.post('/api/v1/newsletters/', dataToServer)
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        } else {
            await axiosPrivate.put('/api/v1/newsletters/', dataToServer)
                .then((response) => {
                    setValidation({ message: response.data.message, isValid: true })
                    setTimeout(() => {
                        setValidation({ message: '', isValid: false })
                    }, 3000)
                })
                .catch((error) => {
                    setValidation({ message: error.response.data.message, isValid: false })
                })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await axiosPrivate.get('/api/v1/newsletters/')
                .then((response) => {
                    setSubscribers(response.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        fetchData()
    }, [subscribers])

    const modalVariants = {
        hidden: { opacity: 0, transition: { duration: 0.25 } },
        visible: { opacity: 1, transition: { duration: 0.25 } },
        exit: { opacity: 0, transition: { duration: 0.25 } }
    }

    return (
        <>
            <button className={styles['action--button']} onClick={() => exportCSV()}>Export CSV</button>
            <button className={styles['action--button']} onClick={() => newsletterModal()}>Send Newsletter</button>

            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Subscribers</h2>

                        {hasAddButton && (
                            <button className={styles['button']} onClick={() => addNewSubscription()}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['newsletter--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Email Address</div>
                                <div className={styles['heading']}>Subscribe Date</div>
                                <div className={styles['heading']}>Status</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['newsletter--table']}`}>
                            {currentItems ? (
                                <>
                                    {currentItems.map((newsletter, i) => (
                                        <div key={i} className={styles['row']}>
                                            <div className={styles['text']}>{i + 1}</div>
                                            <div className={styles['text']}>{newsletter.email}</div>
                                            <div className={styles['text']}>{useFormattedDate(newsletter.subscription_date)}</div>
                                            <div className={styles['text']}>
                                                {newsletter.status === 'confirmed'
                                                    ? <div className={styles['enabled']}>Confirmed</div>
                                                    : <div className={styles['disabled']}>Not Confirmed</div>}
                                            </div>
                                            <div className={styles['text']}>
                                                <div className={styles['tools']}>
                                                    <div className={styles['tool--button']} onClick={() => getSubscriptionData(newsletter.id)}>
                                                        <i className='fa-solid fa-highlighter'></i>
                                                        Edit
                                                    </div>
                                                    <div className={styles['tool--button']} onClick={() => deleteSubscriptionData(newsletter.id)}>
                                                        <i className='fa-solid fa-trash'></i>
                                                        Delete
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className={globalStyles['no-data--message']}>There is not data yet...</p>
                            )}

                            <Pagination totalItems={subscribers.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {openModal && (
                    <motion.div
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        variants={modalVariants}
                    >
                        <FormModal title={isNew ? 'Add Subscription' : 'Edit Subscription'} closeFunction={closeModal}>
                            <form className={modalStyles['modal--form']} onSubmit={(e) => updateData(e)}>
                                <div className={modalStyles['row']}>
                                    <div className={modalStyles['column']}>
                                        <FormModalField inputLabel='* Email Address' inputName='email' inputValue={data?.email} inputUpdate={updateFormInput} />
                                    </div>
                                </div>
                                <div className={modalStyles['row']}>
                                    <div className={modalStyles['column']}>
                                        <div className={modalStyles['input--field']}>
                                            <label className={modalStyles['label-outside']}>Subscription Date</label>
                                            <input type='date' className={modalStyles['input']} name='subscription_date' value={useDefaultDate(data?.subscription_date)} onChange={(e) => updateFormInput(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={modalStyles['row']}>
                                    <div className={modalStyles['column']}>
                                        <div className={modalStyles['input--field']}>
                                            <label className={modalStyles['label-outside']}>Status</label>
                                            <select className={modalStyles['input']} name='status' value={data?.status} onChange={(e) => updateFormInput(e)}>
                                                <option value='confirmed'>Confirmed</option>
                                                <option value='not_confirmed'>Not Confirmed</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {validation.message &&
                                    <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                        {validation.message}
                                    </div>
                                }

                                <button className={modalStyles['modal--save']}>
                                    <i className='fa-solid fa-pen-to-square'></i>
                                    {isNew ? 'Add' : 'Update'}
                                </button>
                            </form>
                        </FormModal>
                    </motion.div>
                )}

                {tourModal && (
                    <motion.div
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        variants={modalVariants}
                    >
                        <FormModal title='Select a Tour to Share' closeFunction={newsletterModal}>
                            <form className={modalStyles['modal--form']} onSubmit={(e) => sendNewsletter(e)}>
                                <div className={modalStyles['row']}>
                                    <div className={modalStyles['input--field']}>
                                        <label className={modalStyles['label-outside']}>Tour Name</label>
                                        <select className={modalStyles['input']} value={tourId} onChange={(e) => updateTourId(e)}>
                                            {tours.map((tour) => (
                                                <option key={tour.id} value={tour.id}>{tour.tour_title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {validation.message &&
                                    <div className={`${globalStyles['validation--message']} ${globalStyles['margin--no-top']} ${validation.isValid ? `${globalStyles['background--light-green']} ${globalStyles['color--green']}` : `${globalStyles['background--light-red']} ${globalStyles['color--red']}`}`}>
                                        {validation.message}
                                    </div>
                                }

                                <button className={modalStyles['modal--save']}>
                                    <i className='fa-solid fa-pen-to-square'></i>
                                    Send
                                </button>
                            </form>
                        </FormModal>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default NewsletterTable