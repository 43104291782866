import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Pagination from './Pagination/Pagination'
import { useFormattedDate } from '../../../functions/useDateTimeFormat'

import styles from './Tables.module.sass'
import globalStyles from '../../../styles/Globals.module.sass'

function OrdersTable({ orders, itemsPerPage, hasAddButton = false }) {
    const [currentPage, setCurrentPage] = useState(1)
    const currentItems = orders.length && orders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const editOrder = (id) => {
        navigate(`/admin/orders/${id}`)
    }

    return (
        <>
            <div className={styles['custom--table']}>
                <div className={styles['table']}>
                    <div className={styles['table--header']}>
                        <h2 className={styles['title']}>List of Orders</h2>

                        {hasAddButton && (
                            <button className={styles['button']}>Add New</button>
                        )}
                    </div>

                    <div className={styles['table--body']}>
                        <div className={`${styles['table--body__header']} ${styles['orders--table']}`}>
                            <div className={styles['row']}>
                                <div className={styles['heading']}>ID</div>
                                <div className={styles['heading']}>Contact Details</div>
                                <div className={styles['heading']}>Invoicing Details</div>
                                <div className={styles['heading']}>Order Date</div>
                                <div className={styles['heading']}>Total Price</div>
                                <div className={styles['heading']}>Payment Method</div>
                                <div className={styles['heading']}>Status</div>
                                <div className={styles['heading']}>Tools</div>
                            </div>
                        </div>

                        <div className={`${styles['table--body__content']} ${styles['orders--table']}`}>
                            {currentItems ? (
                                <>
                                    {currentItems.map((order, i) => (
                                        <div key={i} className={styles['row']}>
                                            <div className={styles['text']}>{order.id}</div>
                                            <div className={`${styles['text']} ${styles['text--details']}`}>
                                                {order.contact_details ? (
                                                    <>
                                                        <p>{order.contact_details.first_name + ' ' + order.contact_details.last_name}</p>
                                                        <p>{order.contact_details.email_address}</p>
                                                        <p>{order.contact_details.phone}</p>
                                                    </>
                                                ) : (
                                                    'No contact details.'
                                                )}
                                            </div>
                                            <div className={`${styles['text']} ${styles['text--details']}`}>
                                                {order.invoicing_details ? (
                                                    <>
                                                        <p>{order.invoicing_details.physical_name ? order.invoicing_details.physical_name : order.invoicing_details.legal_name}</p>
                                                        <p>{order.invoicing_details.physical_identification_number ? order.invoicing_details.physical_identification_number : order.invoicing_details.legal_identification_number}</p>
                                                        <p>{order.invoicing_details.physical_email ? order.invoicing_details.physical_email : order.invoicing_details.legal_email}</p>
                                                        <p>{order.invoicing_details.physical_phone ? order.invoicing_details.physical_phone : order.invoicing_details.legal_phone}</p>
                                                    </>
                                                ) : (
                                                    'No contact details.'
                                                )}
                                            </div>
                                            <div className={styles['text']}>{order.order_date ? useFormattedDate(order.order_date) : 'No order date.'}</div>
                                            <div className={styles['text']}>{order.total_price ? order.total_price + '€' : 'No order price.'}</div>
                                            <div className={styles['text']}>
                                                {order.payment_method === 1 ? 'At the Agency' : 'Banking Transfer'}
                                            </div>
                                            <div className={styles['text']}>
                                                <div className={styles[order.status]}>{order.status}</div>
                                            </div>
                                            <div className={styles['text']} onClick={() => editOrder(order.id)}>
                                                <div className={styles['tool--button']}>
                                                    <i className="fa-solid fa-highlighter"></i>
                                                    Edit
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className={globalStyles['no-data--message']}>There is not data yet...</p>
                            )}

                            <Pagination totalItems={orders.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrdersTable