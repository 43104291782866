import { Link } from 'react-router-dom'

import { BASE_URL, CLIENT_BASE_URL } from '../../../../api/axios'
import useGetAllDestinations from '../../../../hooks/useGetAllDestinations'

import styles from './DestinationCards.module.sass'
import clientStyles from '../../../../styles/Globals.module.sass'

function DestinationCard() {
    const destinations = useGetAllDestinations()

    return (
        <div className={clientStyles['page--size']}>
            <div className={clientStyles['page--gap__XL']}>
                <h2 className={clientStyles['module--title']}>Travel Destinations</h2>
                <p className={clientStyles['module--description']}>Going somewhere to celebrate this season? Whether you’re going home or somewhere to roam, we’ve got the travel tools to get you to your destination.</p>
                {destinations.length
                    ? (
                        <div className={styles['grid-card--image']}>
                            {destinations.map((destination, i) => (
                                <div key={i} className={styles['card']} style={{ backgroundImage: `url(${BASE_URL + destination.representative_image_url})` }}>
                                    <div className={styles['title']}>{destination.destination_name}</div>
                                    <Link to={'/destinations/' + destination.destination_name.toLowerCase()} className={styles['button']}>View More</Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className={clientStyles['module--loading']}>Loading ...</p>
                    )}
            </div>
        </div>
    )
}

export default DestinationCard