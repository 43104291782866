import { useState, useEffect } from 'react'
import useAxiosPrivate from '../api/useAxiosPrivate'

const useDestinationById = (id) => {
    const axiosPrivate = useAxiosPrivate()
    const [destination, setDestination] = useState({})

    useEffect(() => {
        if (!id) return
        
        let isMounted = true
        const controller = new AbortController()

        const getDataForDestination = async () => {
            try {
                const response = await axiosPrivate.get(`/api/v1/tours/destinations/${id}`, { signal: controller.signal })
                isMounted && setDestination(response.data)
            } catch (error) {
                console.error("Error fetching destination data: ", error)
            }
        }

        getDataForDestination()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [id])

    return destination
}

export default useDestinationById